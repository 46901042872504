<template>
  <div>
    <div class="d-flex justify-content-between font-size-sm">
      <AnonymisedUsername :user="response.user"></AnonymisedUsername>
      <a href="javascript:void(0)"
        class="mb-0"
        v-b-tooltip.hover
        :title="getFullPostTime"
      >
        {{ getRelativePostTime }}
      </a>
    </div>
    <div class="row">
      <div
        class="card card-custom my-2 mx-2 w-100"
        :style="getImageStyle"
      >
        <div class="card-body p-2">
          <a
            @click="$emit('show-modal')"
            href="javascript:void(0)"
          >
            <img
              class="w-100 h-100"
              :src="post.activity_attachment.projectAsset.signedUrl"
              style="object-fit: contain !important"
              v-b-tooltip.hover.bottom
              :title="post.response"
            >
          </a>             
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "PictureBookDisplay",

  props: {
    response: { type: Object, required: true },
    post: { type: Object, required: true },
    isModal: { type: Boolean, required: true }
  },

  computed: {
    getRelativePostTime: function() {
      return moment(this.response.created_at).fromNow();
    },

    getFullPostTime: function() {
      return moment(String(this.response.created_at)).format("ddd, Do MMM YY HH:mm");
    },

    getImageStyle: function() {
      if (this.isModal == false) {
        return 'height: 225px';
      } else {
        return 'max-height:750px';
      }
    }
  }
};
</script>
