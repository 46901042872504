<template>
  <div class="mb-5">
    <template v-for="(question, i) in questions">
      <component
        :key="i"
        :is="getQuestionComponent(question.system_answer_type_id)"
        v-if="showQuestionResponse(question.id)"
        :index="i"
        :question="question"
        :domain="domain"
        :response="getQuestionResponse(question.id)"
        :probing="probing"
        :showSeparator="(i + 1) != questions.length"
        :activityType="activityType"
        :activityId="activityId"
        :baseResponse="baseResponse"
        :highlight="highlight"
        @open-chart-modal="$emit('open-chart-modal',$event)"
        @toggleNotepad="$emit('toggleNotepad',$event)"
      >
      </component>
    </template>
  </div>
</template>

<script>
import { mapGetters } from  "vuex";

import StreamSurveyOpenEnded from "./answer-types/OpenEnded";
import StreamSurveyMultipleChoice from "./answer-types/MultipleChoice";
import StreamSurveySingleChoice from "./answer-types/SingleChoice";
import StreamSurveyPictureUpload from "./answer-types/PictureUpload";
import StreamSurveyRanking from "./answer-types/Ranking";
import StreamSurveyVideoUpload from "./answer-types/VideoUpload";
import StreamSurveyFillInTheBlanks from "./answer-types/FillInTheBlanks";
import StreamSurveyAssetUpload from "./answer-types/AssetUpload";

export default {
  name: "StreamSurvey",

  props: {
    questions: { type: Array, required: true },
    response: { type: Object, required: true },
    activityType: { type: String, required: true },
    activityId: { type: Number, required: true },
    baseResponse: { type: Number, required: true },
    domain: { type: String, required: false, default: "admin" },
    highlight: { type: String, required: false, default: null }
  },

  data() {
    return {
      probing: false
    };
  },
  components: {
    StreamSurveyOpenEnded,
    StreamSurveyMultipleChoice,
    StreamSurveySingleChoice,
    StreamSurveyPictureUpload,
    StreamSurveyRanking,
    StreamSurveyVideoUpload,
    StreamSurveyFillInTheBlanks,
    StreamSurveyAssetUpload
  },

  beforeMount() {
    const inputProbing = !(_.isNull(this.$route.params.responseId) || (this.$route.params.responseId== undefined));
    const adminProbing = !(_.isNull(this.$route.params.id) || (this.$route.params.id== undefined));
    if (inputProbing || adminProbing) {
      this.probing = true;
    }
  },
  
  methods: {
    getQuestionComponent: function(systemAnswerTypeId) {
      let component = null;

      switch (systemAnswerTypeId) {
        case 1:
          component = "StreamSurveyOpenEnded";
          break;
        case 2:
          component = "StreamSurveyMultipleChoice";
          break;
        case 3:
          component = "StreamSurveySingleChoice";
          break;
        case 4:
          component = "StreamSurveyPictureUpload";
          break;
        case 5:
          component = "StreamSurveyRanking";
          break;
        case 6:
          component = "StreamSurveyVideoUpload";
          break;
        case 8:
          component = "StreamSurveyFillInTheBlanks";
          break;
        case 9:
          component = "StreamSurveyAssetUpload";
          break;
      }

      return component;
    },
    getQuestionResponse: function(questionId) {

      let responseChild = null;
      let responseForeign = null;

      switch (this.activityType) {
        case "diary":
          responseChild = "diary_question_responses";
          responseForeign = "activity_diary_question_id";
          break;
        case "survey":
          responseChild = "survey_question_responses";
          responseForeign = "activity_survey_question_id";
          break;
        case "poll":
          responseChild = "poll_question_responses";
          responseForeign = "activity_poll_question_id";
          break;
      }

      return this.response[responseChild].filter(function( question ) {
        return question[responseForeign] == questionId;
      });
    },
    showQuestionResponse: function(questionId) {
      if (this.domain == "admin") {
        if (this.streamFilter.questions.length == 0) {
          return true;
        } else {
          let systemActivityTypeId = null;

          switch (this.activityType) {
            case 'diary':
              systemActivityTypeId = 2;
              break;
            case 'survey':
              systemActivityTypeId = 4;
              break;
            case 'poll':
              systemActivityTypeId = 9;
              break;
          }

          let positiveFilters = this.streamFilter.questions.filter(function( question ) {
            return question.id == questionId && question.systemActivityTypeId == systemActivityTypeId;
          }).length;

          if (positiveFilters > 0) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return true;
      }
    }
  },
  computed: {
    ...mapGetters([
      'streamFilter'
    ]),
  }
};
</script>
