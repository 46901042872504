<template>
  <div
    :id="'reply-container-' + reply.id"
    class="row w-100 mx-0 stream-reply px-3"
    v-on:mouseover="hovering = true"
    v-on:mouseleave="hovering = false"
  >
    <div class="d-flex justify-content-start mx-0 py-3 mb-5 w-100">

      <UserThumbnail
        :user="reply.user"
        size="40"
      />

      <div class="ml-3 w-100">
        <div class="row d-flex justify-content-between mx-0">
          <p class="mb-1 font-weight-bold">

            <AnonymisedUsername
              :user="reply.user"
            />

          </p>
          <p class="mb-1 text-muted font-size-sm">

            <GenericDatetimeDisplay
              :long="true"
              :date="reply.created_at"
              :createdAt="true"
              :lastUpdated="false"
              :relative="true"
            />

          </p>
        </div>
        <div class="row mx-0 mb-3">
          {{ reply.value }}
        </div>

        <InlineEngagementNewSchema
          type="reply"
          :allowInteractions="(domain === 'admin') ? ['Sentiment','Favourite','Reply','Note','Tag'] : ['Sentiment','Reply']"
          :allowDestroy="(domain === 'admin' || reply.user_id == currentUser.id)"
          :baseResponse="baseResponse"
          :response="reply"
          :hovering="hovering"
          morph="EngagementInteraction"
          :domain="domain"
          @on-response-deletion="$emit('on-response-deletion')"
          @toggle-notepad="$emit('toggle-notepad',$event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters
} from "vuex";

import UserThumbnail from "@/modules/together-helpers/components/generic-displays/GenericUserThumbnail";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay"

export default {
  name: "ReplyResponse",

  props: {
    baseResponse: { type: Object, required: true },
    reply:        { type: Object, required: true },
    domain:       { type: String, required: true }
  },

  data() {
    return {
      hovering: false
    }
  },

  components: {
    UserThumbnail,
    GenericDatetimeDisplay,
    InlineEngagementNewSchema: () => import('@/modules/together-helpers/activities/response/InlineEngagementNewSchema.vue')
  },

  computed: {
    ...mapGetters([
      "currentUser"
    ])
  }
}
</script>