<template>
  <div v-if="displayResponse">
    <SurveyQuestion
      :number="(index + 1)"
      :question="question"
      :activityId="activityId"
      @open-chart-modal="$emit('open-chart-modal',$event)"
    >
    </SurveyQuestion>

    <template v-for="(response, i) in rankedResponses">
      <div :key="i" class="card card-custom my-2 mx-10 ranking-card">
        <div class="card-body d-flex justify-content-between" :class="{ 'pb-0 pt-2' : response.surveyQuestionChoice.choiceStimuli.length > 0 }">
          <div class="d-flex align-items-center">
            {{ response.rank }}
          </div>
          <div>
            <p class="mb-0 text-center">{{ response.surveyQuestionChoice.choice }}</p>
            <InputGenericThumbGallery
              class="stream-ranking-gallery"
              v-if="response.surveyQuestionChoice.choiceStimuli.length > 0"
              :projectAssets="getChoiceStimuli(response.surveyQuestionChoice.choiceStimuli)"
              :wholeWidth="true"
              :showToolbar="false"
            >
            </InputGenericThumbGallery>
          </div>
          <div></div>
        </div>
      </div>
    </template>

    <div :id="'response-container-' + response.id">
      <InlineEngagement
        ref="engagement"
        :type="morphType"
        :sentiment="true"
        :reply="true"
        :favourite="true"
        :destroy="true"
        :domain="domain"
        :probing="probing"
        :notepad="true"
        :response="response[0]"
        :activityId="activityId"
        :replyState="replyState"
        :hovering="(hovering && !hovering_replies) ? true : false"
        :baseResponse="baseResponse"
        @toggle_replies="toggle_replies()"
        @toggle_reply_input="toggle_reply_input(true)"
        @toggleNotepad="$emit('toggleNotepad',$event)"
      >
      </InlineEngagement>      
    </div>

    <Replies
      ref="replies"
      :activityId="activityId"
      :replies="response[0].replies"
      :repliable_type="morphType"
      :repliable_id="response[0].id"
      :replyState="replyState"
      :domain="domain"
      :probing="probing"
      :baseResponse="baseResponse"
      :baseMorph="morphType"
      :baseMorphId="response[0].id"
      :baseQuestionPosition="index"
      @cancel-reply-input="replyState.showReplyInput = false"
      @hovering-replies="hovering_replies = $event"
      @toggleNotepad="$emit('toggleNotepad',$event)"
    >
    </Replies>

    <div v-if="showSeparator" class="col-12 separator separator-solid separator-gray separator-border-1 mb-7 mt-10"></div>
  </div>
</template>

<script>
import SurveyQuestion from "../components/SurveyQuestion";
import InputGenericThumbGallery from "@/view/components/form-inputs/GenericThumbGallery";
import InlineEngagement from "../InlineEngagement";

export default {
  name: "StreamSurveyRanking",

  props: {
    question: { type: Object, required: true },
    response: { type: Array, required: true },
    index: { type: Number, required: true },
    showSeparator: { type: Boolean, required: true },
    activityId: { type: Number, required: true },
    activityType: { type: String, required: true },
    baseResponse: { type: Number, required: true },
    probing: { type: Boolean,required: false, default:false },
    domain: { type: String, required: true, default: "admin" }
  },

  data() {
    return {
      hovering: false,
      hovering_replies: false,
      replyState: {
        showReplies: ( this.probing ) ? true : false,
        showReplyInput: false
      }
    }
  },

  components: {
    SurveyQuestion,
    InputGenericThumbGallery,
    InlineEngagement
  },

  methods: {
    toggle_replies: function() {
      if (this.replyState.showReplies) {
        this.toggle_reply_input(false);
      } else {
        this.replyState.showReplies = true;
      }
    },
    toggle_reply_input: function(bool) {
      this.replyState.showReplies = bool;
      this.replyState.showReplyInput = bool;
    },
    getChoiceStimuli: function(choiceStimuli) {
      let projectAssets = [];

      choiceStimuli.forEach(choiceStimulus => {
        projectAssets.push(choiceStimulus.projectAsset);
      });

      return projectAssets;
    }
  },

  computed: {
    displayResponse: function() {
      return (this.response.length == 1) ? true : false;
    },
    morphType: function() {
      let morphType = null;

      switch (this.activityType) {
        case 'diary':
          morphType = 'DiaryQuestionResponse';
          break;
        case 'survey':
          morphType = "SurveyQuestionResponse";
          break;
        case 'poll':
          morphType = "PollQuestionResponse";
          break;
      }

      return morphType;
    },
    rankedResponses: function() {
      if (this.displayResponse) {
        let responses = this.response[0].surveyChoiceResponses;
        responses.sort((a,b) => (a.rank > b.rank) ? 1 : ((b.rank > a.rank) ? -1 : 0));
        return responses;
      } else {
        return null;
      }
    }
  }
};
</script>