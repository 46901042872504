class SystemLabelHelper {

  //Returns the translation string for a given activity type
  static activityType(activityType, recordingOverride = false) {
    let label = "";

    switch(activityType.id) {
      case 1:
        label = "SYSTEM.ACTIVITY_TYPE.BLOG"
        break;
      case 2:
        label = "SYSTEM.ACTIVITY_TYPE.DIARY"
        break;
      case 3:
        label = "SYSTEM.ACTIVITY_TYPE.DISCUSSION"
        break;
      case 4:
        label = "SYSTEM.ACTIVITY_TYPE.SURVEY"
        break;
      case 5:
        label = "SYSTEM.ACTIVITY_TYPE.PICTURE_BOOK"
        break;
      case 6:
        label = "SYSTEM.ACTIVITY_TYPE.IDEASTORM"
        break;
      case 7:
        if (recordingOverride) {
          label = "SYSTEM.ACTIVITY_TYPE.SCREEN"
          break;
        } else {
          label = "SYSTEM.ACTIVITY_TYPE.EXTERNAL"
          break; 
        }
      case 8:
        label = "SYSTEM.ACTIVITY_TYPE.MARKUP_LEGACY"
        break;
      case 9:
        label = "SYSTEM.ACTIVITY_TYPE.POLL"
        break;
      case 10:
        label = "SYSTEM.ACTIVITY_TYPE.MARKUP"
        break;
    }

    return label;
  }
}

export default SystemLabelHelper;