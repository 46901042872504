<template>
  <div v-if="displayResponse">
    <SurveyQuestion
      :number="(index + 1)"
      :question="question"
      :activityId="activityId"
      @open-chart-modal="$emit('open-chart-modal',$event)"
    >
    </SurveyQuestion>
    <div class="form-group my-0 mx-5">
      <div class="radio-list">
        <template v-for="(choice, i) in choices">
          <div :key="i" class="row d-flex justify-content-between w-100 mb-4">
            <div class="justify-content-start px-4 choice-display">
              <label class="radio">
                <input type="radio" disabled :checked="choice.id == selectedChoice" />
                <span></span>
                {{ choice.choice }}
              </label>
            </div>
            <div class="justify-content-end">
              <InputGenericThumbGallery
                class="stream-single-choice-gallery"
                v-if="(choice.choiceStimuli != undefined && choice.choiceStimuli.length > 0)"
                :projectAssets="getChoiceStimuli(choice.choiceStimuli)"
                :wholeWidth="true"
                :showToolbar="false"
              >
              </InputGenericThumbGallery>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div :id="'response-container-' + response.id">
      <StreamTextMedia
        class="mx-5"
        :domain="domain"
        :response="response[0].response"
        :media="response[0].attachments"
      >
      </StreamTextMedia>
      <InlineEngagement
        ref="engagement"
        :type="morphType"
        :sentiment="true"
        :reply="true"
        :favourite="true"
        :destroy="true"
        :domain="domain"
        :probing="probing"
        :notepad="true"
        :response="response[0]"
        :activityId="activityId"
        :replyState="replyState"
        :hovering="(hovering && !hovering_replies) ? true : false"
        :baseResponse="baseResponse"
        @toggle_replies="toggle_replies()"
        @toggle_reply_input="toggle_reply_input(true)"
        @toggleNotepad="$emit('toggleNotepad',$event)"
      >
      </InlineEngagement>      
    </div>

    <Replies
      ref="replies"
      :activityId="activityId"
      :replies="response[0].replies"
      :repliable_type="morphType"
      :repliable_id="response[0].id"
      :replyState="replyState"
      :domain="domain"
      :probing="probing"
      :baseResponse="baseResponse"
      :baseMorph="morphType"
      :baseMorphId="response[0].id"
      :baseQuestionPosition="index"
      @cancel-reply-input="replyState.showReplyInput = false"
      @hovering-replies="hovering_replies = $event"
      @toggleNotepad="$emit('toggleNotepad',$event)"
    >
    </Replies>

    <div v-if="showSeparator" class="col-12 separator separator-solid separator-gray separator-border-1 mb-7 mt-10"></div>
  </div>
</template>

<script>
import SurveyQuestion from "../components/SurveyQuestion";
import InputGenericThumbGallery from "@/view/components/form-inputs/GenericThumbGallery";
import StreamTextMedia from "../TextMedia";
import InlineEngagement from "../InlineEngagement";

export default {
  name: "StreamSurveySingleChoice",

  props: {
    question: { type: Object, required: true },
    response: { type: Array, required: true },
    index: { type: Number, required: true },
    showSeparator: { type: Boolean, required: true },
    activityId: { type: Number, required: true },
    activityType: { type: String, required: true },
    baseResponse: { type: Number, required: true },
    probing: { type: Boolean,required: false, default:false },
    domain: { type: String, required: true, default: "admin" }
  },

  data() {
    return {
      hovering: false,
      hovering_replies: false,
      replyState: {
        showReplies: ( this.probing ) ? true : false,
        showReplyInput: false
      }
    }
  },

  components: {
    SurveyQuestion,
    InputGenericThumbGallery,
    InlineEngagement,
    StreamTextMedia
  },

  methods: {
    toggle_replies: function() {
      if (this.replyState.showReplies) {
        this.toggle_reply_input(false);
      } else {
        this.replyState.showReplies = true;
      }
    },
    toggle_reply_input: function(bool) {
      this.replyState.showReplies = bool;
      this.replyState.showReplyInput = bool;
    },
    getChoiceStimuli: function(choiceStimuli) {
      let projectAssets = [];

      choiceStimuli.forEach(choiceStimulus => {
        projectAssets.push(choiceStimulus.projectAsset);
      });

      return projectAssets;
    }
  },

  computed: {
    displayResponse: function() {
      return (this.response.length == 1) ? true : false;
    },
    morphType: function() {
      let morphType = null;

      switch (this.activityType) {
        case 'diary':
          morphType = 'DiaryQuestionResponse';
          break;
        case 'survey':
          morphType = "SurveyQuestionResponse";
          break;
        case 'poll':
          morphType = "PollQuestionResponse";
          break;
      }

      return morphType;
    },
    selectedChoice: function() {
      if (this.displayResponse) {
        if (this.activityType == "diary") { 
          return this.response[0].diaryChoiceResponses[0].diary_question_choice_id;
        } else if (this.activityType == "survey") {
          return this.response[0].surveyChoiceResponses[0].survey_question_choice_id;
        } else if (this.activityType == "poll") {
          return this.response[0].pollChoiceResponses[0].poll_question_choice_id;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    choices: function() {
      if (this.activityType == "diary") {
        return this.question.diary_question_choices;
      } else if (this.activityType == "survey") {
        return this.question.survey_question_choices;
      } else if (this.activityType == "poll") {
        return this.question.poll_question_choices;
      } else {
        return [];
      }
    }
  }
};
</script>