<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Survey Wizard-->
      <div
        class="wizard wizard-3"
        id="survery_page_wizard"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
        ref="wizardBody"
      >
        <!--begin: Survey Wizard Nav -->
        <WizardNav
          v-if="!answeredDate"
          :wizardForm="questionForm"
          :currentPage="currentPage"
          :hideTitle="hideTitle"
        ></WizardNav>
        <!--end: Survey Wizard Nav -->

        <h5 class="text-right pt-10 pr-10 w-100" v-if="answerAt">
          <span>{{ $t("ACTIVITY.SURVEY.ANSWERED_AT") }}</span>
          {{ answerAt }}
        </h5>

        <!--begin: Survey Wizard Body-->
        <div class="row justify-content-center py-12 px-8 py-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-10">
            <!--begin: Survey Wizard Form-->
            <form class="form" id="kt_form" @submit.prevent="submit">
              <!--begin: Survey Page Wizard Steps--->
              <div
                class="pb-5"
                v-for="(page, pageIndex) in questions"
                v-bind:key="pageIndex"
                data-wizard-type="step-content"
                :data-wizard-state="pageIndex == 0 ? 'current' : ''"
              >
                <div v-for="(q, index) in page.page" v-bind:key="index">
                  <div class="p-10">
                    <div
                      v-if="q.system_answer_type_id != 8"
                      class="font-weight-bold"
                      v-html="questionLabel(index, q.question)"
                    ></div>

                    <!--begin::Ranking Question-->
                    <div v-if="q.system_answer_type_id == '5'">
                      <SurveyRankingQuestion
                        :disabled="disabled"
                        @ranking_changed="
                          changedOrders(pageIndex, index, $event)
                        "
                        @checkedOriOrder="
                          useOriginalOrders(pageIndex, index, $event)
                        "
                        :id="`question-${q.id}-answer`"
                        :error="$t('FORMS.GENERIC_ERRORS.REQUIRED')"
                        :model="validationModel(pageIndex, index)"
                        :submissionStates="submissionStates"
                        :list="q.options"
                      ></SurveyRankingQuestion>
                    </div>
                    <!--end::Ranking Question-->

                    <!--begin:: Non-Ranking Question-->
                    <div v-else>
                      <!--begin::Question Stimulus media file-->
                      <div v-if="q.stimulus_assets.length > 0">
                        <InputGenericThumbGallery
                          :projectAssets="q.stimulus_assets"
                        >
                        </InputGenericThumbGallery>
                      </div>
                      <!--end::Question Stimulus media file-->

                      <!--begin::Open-ended Question-->
                      <InputGenericTextArea
                        v-if="q.system_answer_type_id == '1'"
                        :id="`question-${q.id}-answer`"
                        :model="validationModel(pageIndex, index)"
                        :prepResponse="q.prepopulated_response"
                        :submissionStates="submissionStates"
                        :disabled="disabled"
                        :error="$t('FORMS.GENERIC_ERRORS.REQUIRED')"
                      >
                      </InputGenericTextArea>
                      <!--end::Open-ended Question-->

                      <!--begin::Multiple Choice Question-->
                      <MultipleChoiceQuestion
                        v-if="q.system_answer_type_id == '2'"
                        :id="`question-${q.id}-answer`"
                        class="px-4 mb-0"
                        :model="validationModel(pageIndex, index)"
                        :options="q.options"
                        :disabled="disabled"
                        :submissionStates="submissionStates"
                        :error="$t('FORMS.GENERIC_ERRORS.REQUIRED')"
                      >
                      </MultipleChoiceQuestion>
                      <!--end::Multiple Choice Question-->

                      <!--begin::Single Choice Question-->
                      <SingleChoiceQuestion
                        v-if="q.system_answer_type_id == '3'"
                        :id="`question-${q.id}-answer`"
                        class="px-4 mb-0"
                        :model="validationModel(pageIndex, index)"
                        :options="q.options"
                        :disabled="disabled"
                        :submissionStates="submissionStates"
                        :error="$t('FORMS.GENERIC_ERRORS.REQUIRED')"
                      >
                      </SingleChoiceQuestion>
                      <!--end::Single Choice Question-->

                      <!--begin::FITB Question-->
                      <FITBQuestion
                        v-if="q.system_answer_type_id == 8"
                        :questionStructure="q.arrayQuestion"
                        :questionId="q.id"
                        @blank-filled="
                          blankFilled(...arguments, pageIndex, index)
                        "
                        :fitbErrorSlot="fitbErrorSlot"
                      >
                      </FITBQuestion>
                      <!--end::FITB Question-->

                      <!--begin::Picture upload Or Video upload Question-->
                      <div v-if="allowedAttachment(q)">
                        <b-form-group
                          :id="`question-${q.id}-answer`"
                          class="w-100 pb-5 mb-0"
                        >
                        </b-form-group>
                        <!--Begin: Drop Zone ---->
                        <IntegratedDropzone
                          v-if="!disabled"
                          class="col-xl-12 px-0"
                          classStyle="sm"
                          :assetReservation="getAssetReservation(q)"
                          :idx="q.id"
                          :wholeWidth="true"
                          :removable="true"
                          :formError="customModelStatus(pageIndex, index, q)"
                          @asset_reservation_created="
                            createdAttachments(pageIndex, index, q, $event)
                          "
                          @asset_reservation_deleted="
                            cancelAttachments(pageIndex, index, q, $event)
                          "
                        >
                        </IntegratedDropzone>
                        <!--End: Drop Zone ---->

                        <div
                          class="w-100"
                          v-if="
                            disabled && getAttachmentAssets(pageIndex, index)
                          "
                        >
                          <InputGenericThumbGallery
                            :projectAssets="
                              getAttachmentAssets(pageIndex, index)
                            "
                            classStyle="sm"
                          >
                          </InputGenericThumbGallery>
                        </div>
                      </div>
                      <!--end::Picture upload Question-->
                    </div>
                    <!--end:: Non-Ranking Question-->
                  </div>
                  <div
                    v-if="index !== page.page.length - 1"
                    class="
                      separator
                      separator-solid
                      separator-border-4
                      separator-primary
                      my-5
                    "
                  ></div>
                </div>
              </div>
              <!--end: Survey Page Wizard Steps-->

              <!--begin: Survey Wizard Actions -->
              <WizardActions
                :wizardForm="questionForm"
                :prev="$t('ACTIVITY.SURVEY.PREVIOUS')"
                :next="$t('ACTIVITY.SURVEY.NEXT')"
                :submit="$t('ACTIVITY.SURVEY.SUBMIT')"
                @submitAction="submit"
                v-if="!disabled"
              ></WizardActions>
              <!--end: Survey Wizard Actions -->
            </form>
            <!--end: Survey Wizard Form-->
          </div>
        </div>
        <!--end: Survey Wizard Body-->
      </div>
      <!--end: Survey Wizard-->
    </div>
  </div>
</template>
<script>
import moment from "moment";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import WizardNav from "@/view/components/wizard/WizardNav.vue";
import WizardActions from "@/view/components/wizard/WizardActions.vue";
import SurveyRankingQuestion from "@/view/pages/activity/SurveyRankingQuestion.vue";
import MultipleChoiceQuestion from "@/view/pages/activity/MultipleChoiceQuestion.vue";
import SingleChoiceQuestion from "@/view/pages/activity/SingleChoiceQuestion.vue";
import FITBQuestion from "@/view/pages/activity/FITBQuestion.vue";
import InputGenericTextArea from "@/modules/together-helpers/components/generic-inputs/GenericTextArea";
import InputGenericThumbGallery from "@/view/components/form-inputs/GenericThumbGallery.vue";
import IntegratedDropzone from "@/modules/together-helpers/components/generic-inputs/GenericIntegratedDropzone";
import { RETURN_SUPPORTED_ASSET_TYPE } from "@/core/services/store/system/asset.module";
import { CREATE_SURVEY_RESPONSES } from "@/core/services/store/activity/activity_survey_responses.module.js";
import { CREATE_DIARY_RESPONSES } from "@/core/services/store/activity/activity_diary_responses.module.js";

export default {
  name: "PageOfQuestions",

  props: {
    questionForm: { type: Array, required: true },
    questions: { type: Array, required: true },
    activityType: { type: String, required: true },
    answeredDate: { type: String, required: false },
    disabled: { type: Boolean, required: false },
  },

  components: {
    WizardNav,
    WizardActions,
    SurveyRankingQuestion,
    MultipleChoiceQuestion,
    SingleChoiceQuestion,
    FITBQuestion,
    InputGenericTextArea,
    InputGenericThumbGallery,
    IntegratedDropzone,
  },

  data() {
    return {
      submissionStates: {
        submitting: false,
      },

      error: {
        active: false,
        message: "",
      },

      fitbErrorSlot: [],

      currentPage: 1,

      arDropzoneOptions: {
        supportedMimeTypes: {},
        supportedMimeSizes: {},
        supportedFileSize: 1600,
        supportedFileCount: 0,
        supportWebcam: 0,
        supportUploadButton: 1,
      },
      videoUploadArDropzoneOptions: {
        supportedMimeTypes: {},
        supportedMimeSizes: {},
        supportedFileSize: 1600,
        supportedFileCount: 0,
        supportWebcam: 0,
        supportUploadButton: 1,
      },
    };
  },

  validations: {
    questionForm: {
      $each: {
        page: {
          required,
          $each: {
            answer: {
              required,
            },
          },
        },
      },
    },
  },

  mounted() {
    // this.fetchSupportedFileTypes();

    const wizard = new KTWizard("survery_page_wizard", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on("change", (wizardObj) => {
      if (wizardObj.beforeNext()) {
        if (this.validatePageForm(wizardObj.getStep())) {
          wizardObj.stop();
        } else {
          wizardObj.resume();
          this.$nextTick(() => {
            this.$refs.wizardBody.scrollIntoView({
              block: "start",
              behavior: "smooth",
            });
          });
          this.currentPage = wizardObj.getNewStep();
        }
      } else {
        this.currentPage = wizardObj.getNewStep();
      }
    });
  },

  methods: {
    questionLabel(index, question) {
      if (this.answeredDate != undefined) {
        return "<span class='mr-2'> Q." + (index + 1) + "</span>" + question;
      }
      return question;
    },

    validationModel(p, q) {
      return this.$v.questionForm.$each.$iter[p].page.$each.$iter[q].answer;
    },
    getAttachmentAssets(p, q) {
      return this.questionForm[p].page[q].attachments;
    },

    changedOrders(p, q, $event) {
      this.questions[p].page[q].options = $event;
      this.$v.questionForm.$each.$iter[p].page.$each.$iter[
        q
      ].answer.$model = true;
    },
    getAssetReservation(q) {
      switch (q.system_answer_type_id) {
        case 4:
          return this.imageUploadAssetReservationData;
        case 6:
          return this.videoUploadAssetReservationData;
        case 9:
          return {
            scope: "activity",
            data: {
              directory: "attachments_custom",
              projectId: this.projectInfo.id,
              activityId: this.activityInfo.id,
              questionId: q.id,
              system_answer_type_id: q.system_answer_type_id
            },
          };
        default:
          return this.assetReservationData;
      }
    },
    getArDropzoneOptions(q) {
      return q.system_answer_type_id == 6
        ? this.videoUploadArDropzoneOptions
        : this.arDropzoneOptions;
    },

    allowedAttachmentButNotOpen(q) {
      return q.system_answer_type_id != 1 &&
        (q.attachments_allowed ||
          this._.includes([4, 6, 9], q.system_answer_type_id))
        ? true
        : false;
    },

    allowedAttachment(q) {
      return q.attachments_allowed ||
        this._.includes([4, 6, 9], q.system_answer_type_id)
        ? true
        : false;
    },

    useOriginalOrders(p, q, checkboxValue) {
      this.$v.questionForm.$each.$iter[p].page.$each.$iter[q].answer.$model =
        !checkboxValue ? "" : checkboxValue;
    },

    customModelStatus(p, q, que) {
      if (this._.includes([4, 5, 6, 9], que.system_answer_type_id)) {
        return this.$v.questionForm.$each.$iter[p].page.$each.$iter[q].answer
          .$error;
      } else {
        return;
      }
    },

    createdAttachments(p, q, que, event) {
      this.questions[p].page[q].attachments.push(event);
      if (this.allowedAttachmentButNotOpen(que)) {
        this.$v.questionForm.$each.$iter[p].page.$each.$iter[q].answer.$model = true;
      }
    },

    cancelAttachments(p, q, que, event) {
      this.questions[p].page[q].attachments = this.questions[p].page[
        q
      ].attachments.filter(function (stim) {
        return stim !== event;
      });
      if (
        this.allowedAttachmentButNotOpen(que) &&
        this.questions[p].page[q].attachments.length == 0
      ) {
        this.$v.questionForm.$each.$iter[p].page.$each.$iter[q].answer.$model =
          "";
      }
    },

    validatePageForm(pageNumber) {
      let arrIndex = pageNumber - 1;
      let hasSpecificErrors = false;
      let errorSlot = [];

      const pageValidationEl = this.$v.questionForm.$each.$iter[arrIndex];
      pageValidationEl.$touch();

      this._.each(pageValidationEl.page.$each.$iter, (q, index) => {
        let answer = q.answer.$model;
        // validate Fill in the blank response
        let slots = this.questions[arrIndex].page[index].arrayQuestion;
        if (slots.length > 0) {
          this._.forOwn(answer, function (slot, key) {
            slot = (typeof slot != "number" && !_.isNil(slot)) ? slot.replace(/[\[\]\-']+/g,'') : slot;
            let onlyWhiteSpace = /^\s*$/.test(slot);
            if (_.isNil(slot) || onlyWhiteSpace) {
              hasSpecificErrors = true;
              errorSlot.push(key);
            }
          });
        }
        // validate prepolulate respsonse
        let prep = this.questions[arrIndex].page[index].prepopulated_response;
        if (answer == prep) {
          hasSpecificErrors = true;
        }
      });
      this.fitbErrorSlot = errorSlot;
      return pageValidationEl.$error || hasSpecificErrors;
    },

    preparePayload() {
      let form = [];
      this.questionForm.forEach((pages, PageIndex) => {
        pages.page.forEach((Question, QusestionIndex) => {
          let origin = this.questions[PageIndex].page[QusestionIndex];
          let type = origin.system_answer_type_id;
          switch (type) {
            case 1: //Open-ended Question
              form[origin.id] =
                origin.attachments_allowed && origin.attachments.length > 0
                  ? {
                      response: String(Question.answer),
                      attachments: origin.attachments,
                    }
                  : Question.answer;
              break;

            case 2: // MC Question
            case 3: // SC Question
              if (this._.isArray(Question.answer)) {
                form[origin.id] = Question.answer;
              } else {
                form[origin.id] = [Question.answer];
              }
              break;

            case 4: // Picture upload Question
            case 6: // Video upload Question
            case 9: // Asset upload Question
              form[origin.id] = origin.attachments;
              break;

            case 5: // Ranking Questions
              form[origin.id] = this._.map(origin.options, "value");
              break;

            case 8: // FITB
              let types = origin.arrayQuestion;
              let obj = {};
              this._.forOwn(Question.answer, function (value, slot) {
                types.forEach((fitbQ) => {
                  if (fitbQ.slotNumber == slot) {
                    value = (fitbQ.type == "OpenEnded") ? value.replace(/[\[\]\-']+/g,'') : value;
                    obj[slot] = { type: fitbQ.type, response: value };
                  }
                });
              });
              form[origin.id] = obj;
              break;
          }
        });
      });
      let data = {
        activity_id: this.activityInfo.id,
        project_profile_id: this.currentUser.project_profiles.id,
        user_id: this.currentUser.id,
        system_activity_type_id: this.activityInfo.system_activity_type_id,
        response: form,
      };
      return {
        activityId: this.activityInfo.id, // required
        data: data,
      };
    },

    blankFilled: function (blank, p, q) {
      this.questions[p].page[q][blank.slotNumber] = blank.slotResponse;
      this.$v.questionForm.$each.$iter[p].page.$each.$iter[q].answer.$model[
        blank.slotNumber
      ] = blank.slotResponse;
    },

    submit: function () {
      this.$v.questionForm.$touch();
      let customError = this.validatePageForm(this.questions.length);
      if (this.$v.questionForm.$anyError || customError) {
        this.$toasted.global.error_toast({
          message: "Please review form errors.",
        });
        return;
      }

      let answers = this.preparePayload();

      Swal.fire({
        title: "Submitting",
        text: "This response cannot be edited once submitted",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.submissionStates.submitting = true;
          this.$store
            .dispatch(this.SubmitAction, answers)
            .then(() => {
              let sweetAlertMsg = "";

              if (!this.isParticipant) {
                sweetAlertMsg = this.$t(
                  "RESPONSE_FORMS.ADD_SUCCESS.TEST_ACTIVITY"
                );
              } else {
                sweetAlertMsg =
                  this.activityType == "diary"
                    ? this.$t("RESPONSE_FORMS.ADD_SUCCESS.2")
                    : this.$t("RESPONSE_FORMS.ADD_SUCCESS.4");
              }
              Swal.fire({
                title: "",
                text: sweetAlertMsg,
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                allowOutsideClick: false,
              }).then(() => {
                this.$emit("responses_created");
              });
            })
            .catch(() => {
              this.error.active = true;
              this.$toasted.global.error_toast({
                message: `We're having some issues posting a ${this.activityType} response, please check back later or contact the helpdesk`,
              });
            });
        }
      });
    },

    fetchSupportedFileTypes() {
      let payload = {
        scope: this.assetReservationData.scope,
        directory: this.assetReservationData.data.directory,
        activityId: this.activityInfo.id, // required
      };
      this.$store.dispatch(RETURN_SUPPORTED_ASSET_TYPE, payload).then((res) => {
        this.arDropzoneOptions.supportedMimeTypes = res.types;
        this.arDropzoneOptions.supportedMimeSizes = res.max_size;
      });
      let vPayload = {
        scope: this.videoUploadAssetReservationData.scope,
        directory: this.videoUploadAssetReservationData.data.directory,
        activityId: this.activityInfo.id,
      };
      this.$store
        .dispatch(RETURN_SUPPORTED_ASSET_TYPE, vPayload)
        .then((res) => {
          this.videoUploadArDropzoneOptions.supportedMimeTypes = res.types;
          this.videoUploadArDropzoneOptions.supportedMimeSizes = res.max_size;
        });
    },
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "projectInfo",
      "activityInfo",
      "surveyQuestionList",
    ]),
    isParticipant: function () {
      if (this.currentUser.relation_to_project == "participant") {
        return true;
      } else {
        return false;
      }
    },

    imageUploadAssetReservationData: function () {
      return {
        scope: "activity",
        data: {
          directory: "attachments_images",
          projectId: this.projectInfo.id,
          activityId: this.activityInfo.id,
        },
      };
    },

    videoUploadAssetReservationData: function () {
      return {
        scope: "activity",
        data: {
          directory: "attachments_videos",
          projectId: this.projectInfo.id,
          activityId: this.activityInfo.id,
        },
      };
    },

    assetReservationData: function () {
      return {
        scope: "activity",
        data: {
          directory: "attachments",
          projectId: this.projectInfo.id,
          activityId: this.activityInfo.id,
        },
      };
    },

    hideTitle: function () {
      return this.activityType == "diary" || this.disabled ? true : false;
    },
    answerAt() {
      if (this.answeredDate != undefined) {
        return moment(this.answeredDate).format("MM/DD/YYYY");
      }
      return false;
    },
    SubmitAction: function () {
      return this.activityType == "diary"
        ? CREATE_DIARY_RESPONSES
        : this.activityType == "survey"
        ? CREATE_SURVEY_RESPONSES
        : false;
    },
  },
};
</script>
