import html2canvas from 'html2canvas';

class CanvasExportService {


  /**
   * The canvas export service is used to export HTML elements to image format
   *
   */
  constructor() {};

  generateJPEG(element) {
    return new Promise((resolve, reject) => {
      html2canvas(element, { allowTaint: false, useCORS: true, logging: true })
        .then((canvas) => {
          resolve(canvas.toDataURL('image/jpeg', 1));
        }).catch(() => {
          reject();
        })
    });

  }

  exportJPEG(element, filename) {
    this.exportImage(element, 'image/jpeg', filename, '.jpeg');
  }

  exportPNG(element, filename) {
    this.exportImage(element, 'image/png', filename, '.png');
  }

  exportSVG(element, filename) {
    const svgElement = element;
    const svgData = new XMLSerializer().serializeToString(svgElement);
    const blob = new Blob([svgData], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);

    var link = document.createElement('a');
    link.href = url;
    link.download = `${filename}.svg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  exportImage(element, format, filename, extension) {
    html2canvas(element, { allowTaint: false, useCORS: true, logging: true })
      .then((canvas) => {

        var link = document.createElement('a');
        link.href = canvas.toDataURL(format, 1);
        link.download = `${filename}${extension}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      });
  }

}

export default CanvasExportService;