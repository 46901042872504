<template>
  <a
    @click="deletePictureBook"
    href="javascript:void(0)"
    class="btn btn-icon-danger btn-xs btn-text-dark-50 bg-hover-light-danger btn-hover-text-danger rounded font-weight-bolder p-2"
    ::class="calculatedBtnSize"
  >
    <span class="svg-icon svg-icon-md svg-icon-danger mr-0">
      <inline-svg src="media/svg/icons/Home/Trash.svg" />
    </span>
  </a>
</template>

<script>
import { mapGetters } from "vuex";

import {
  DELETE_PICTURE_BOOK_RESPONSES
} from "../../../vuex/activity/response/activity_picture_book_responses.module";

export default {
  name: "PictureBookDelete",

  props: {
    size: { type: String, required: false },
    post: { type: Object, required: true },
    activityId: { type: Number, required: false, default: null }
  },

  methods: {
    deletePictureBook: function() {
      let payload = {
        activityId: (this.activityId != null) ? this.activityId : this.activityInfo.id,
        activityPictureBookResponseId: this.post.id
      };
      this.$store
        .dispatch(DELETE_PICTURE_BOOK_RESPONSES, payload)
        .then(() => {
          this.$emit('deleted');
        })
    }
  },

  computed: {
    ...mapGetters(["activityInfo"]),
    calculatedBtnSize: function() {
      if (this.size == undefined) {
        return "btn-xs font-size-xs"
      } else {
        return "btn-" + this.size + " font-size-" + this.size;
      }
    }
  }
};
</script>
