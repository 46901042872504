<template>
  <div>
    <div v-if="displayTitle" class="row mx-0">
      <p
        ref="title-container"
        class="mb-5 font-weight-bold"
      >
        {{ title }}
      </p>
    </div>
    <div v-if="displayContent" class="row mx-0">
      <p
        ref="response-container"
        class="mb-5"
      >
        {{ response }}
      </p>
    </div>
    <div v-if="displayTags" class="row d-flex justify-content-start mx-0">
      <template v-for="(tag, i) in tags">
        <span :key="i" class="label label-rounded label-primary mr-2 px-3 w-auto">{{ tag.name }}</span>
      </template>
    </div>
    <div class="row mx-0">
      <span class="py-5 text-warning" v-if="authIsObserver">You are unable to view uploaded media due to your user role</span>
      <InputGenericThumbGallery
        v-else-if="displayAttachments"
        :projectAssets="media"
        :wholeWidth="true"
        :showToolbar="false"
        classStyle="sm"
      >
      </InputGenericThumbGallery>
    </div>
  </div>
</template>

<script>
import { mapGetters } from  "vuex";

import InputGenericThumbGallery from "@/view/components/form-inputs/GenericThumbGallery";
import HighlightHelper from "@/modules/together-helpers/helpers/HighlightHelper";

export default {
  name: "StreamTextMedia",

  props: {
    title: { type: String, required: false },
    response: { type: String, required: false },
    media: { type: Array, required: false },
    tags: { type: Array, required: false },
    highlight: { type: String, required: false, default: null }
  },

  data() {
    return {
      HighlightHelper: new HighlightHelper()
    }
  },

  components: {
    InputGenericThumbGallery
  },

  watch: {
    highlight: {
      handler(newHighlight) {
        if (newHighlight != null && newHighlight.length > 0) {
          let restructuredHTML = this.HighlightHelper.highlightText(newHighlight, this.$refs['response-container'].textContent);
          this.$refs['response-container'].innerHTML = restructuredHTML;

          if (this.displayTitle) {
            let restructuredHTML = this.HighlightHelper.highlightText(newHighlight, this.$refs['title-container'].textContent);
            this.$refs['title-container'].innerHTML = restructuredHTML;
          }
        }
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters([
      "currentUser"
    ]),
    displayTitle: function() {
      if (this.title != undefined) {
        if (this.title.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    displayContent: function() {
      if (this.response != undefined) {
        if (this.response.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    displayAttachments: function() {
      if (this.media != undefined) {
        if (this.media.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    displayTags: function() {
      if (this.tags != undefined) {
        if (this.tags.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    authIsObserver: function() {
      return (this.currentUser.projectProfile != undefined && this.currentUser.projectProfile.system_project_role_id == 2) ? true : false;
    }
  }
}
</script>