<template>
  <GeneralDisplay v-if="loadingComplete" :activityInfo="activityInfo">
    <div slot="activity-panel">
      <div class="mt-10 d-flex flex-row-reverse">
        <button
          class="btn btn-primary"
          @click="toggleNewMessage()"
          v-show="!showNewMessageBox"
        >
          {{ $t("RESPONSE_FORMS.BUTTON.ADD_IDEA") }}
        </button>
      </div>

      <!--begin::Message input Box-->
      <InputGenericResponseBox
        mode="post"
        :prepResponse="prepopulatedResponse"
        reqBlogTitle=TRUE        
        :ideastormCategories="ideastormCategories"
        v-show="showNewMessageBox"
        @cancelAction="toggleNewMessage"
        @refreshList="fetchActivityInfo"
      ></InputGenericResponseBox>
      <!--end::Message input Box-->

      <!--begin::Ideastorm Response Lists Filter-->
      <div class="mt-10" v-if="!responseLoading">
        <!-- <span class="view-label mr-3">{{
          $t("ACTIVITY.IDEASTORM.RESPONSES")
        }}</span> -->
        <div class="d-flex justify-content-center mb-3">
          <button
            type="button"
            @click="toggleFilter('date')"
            class="btn btn-primary btn-outline-primary mr-3"
            :class="checkedFilter('date') ? 'active' : ''"
          >
            {{ $t("ACTIVITY.IDEASTORM.FILTER_1") }}
          </button>
          <button
            type="button"
            @click="toggleFilter('like')"
            class="btn btn-primary btn-outline-primary mr-3"
            :class="checkedFilter('like') ? 'active' : ''"
          >
            {{ $t("ACTIVITY.IDEASTORM.FILTER_2") }}
          </button>
        </div>
      </div>
      <!--end::Ideastorm Response Lists Filter-->

      <!--begin::Ideastorm Response Lists-->
      <div class="mt-10">
        <div class="d-flex justify-content-center mb-3" v-if="responseLoading">
          <b-spinner class="main-card-loader" label="Loading..."></b-spinner>
        </div>

        <IdeastormResponse
          :targetResponse="responseId"
          :ideastormCategories="ideastormCategories"
          v-else
        ></IdeastormResponse>
      </div>
      <!--end::Ideastorm Response Lists-->
    </div>
  </GeneralDisplay>

  <!--begin::Loading Spinner-->
  <div class="row" v-else>
    <div class="col-xxl-12 min-vh-100">
      <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
        <div class="card-body my-4 pd-12">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner class="main-card-loader" label="Loading..."></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Loading Spinner-->
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { POPULATE_ACTIVITY_INFO } from "@/core/services/store/activity/activity.module";
import {
  POPULATE_ACTIVITY_IDEASTORM_RESPONSES_LIST,
  POPULATE_ACTIVITY_IDEASTORM_RESPONSES_FILTER
} from "@/core/services/store/activity/activity_ideastorm_responses.module.js";
import IdeastormResponse from "@/view/components/activity/IdeastormResponse.vue";
import GeneralDisplay from "@/view/components/activity/GeneralDisplay.vue";
import InputGenericResponseBox from "@/modules/together-helpers/components/generic-inputs/GenericResponseBox";
import GeneralFunctionsHelper from "@/helpers/GeneralFunctions";
export default {
  name: "IdeamstormActivity",
  components: {
    IdeastormResponse,
    GeneralDisplay,
    InputGenericResponseBox
  },

  data() {
    return {
      showNewMessageBox: false,
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },
      error: {
        active: false,
        message: ""
      },
      responseLoading: true,
      replies: []
    };
  },

  mounted() {
    this.cleanup();
    this.fetchActivityInfo();
    this.toggleFilter(this.filter);
  },

  watch: {
    activityId: function() {
      this.pageLoader.componentsCompleted = 0;
      this.pageLoader.componentsRequired = 2;
      this.fetchActivityInfo();
    },
    responseId: function() {
      this.responseLoading = true;
      this.fetchIdeastormResponse();
    }
  },
  methods: {
    toggleFilter(val) {
      this.responseLoading = true;
      this.$store.dispatch(POPULATE_ACTIVITY_IDEASTORM_RESPONSES_FILTER, val);
      setTimeout(() => {
        this.responseLoading = false;
      }, 500);
    },
    checkedFilter(val) {
      return this.filter == val ? true : false;
    },
    fetchActivityInfo() {
      let payload = {
        projectId: this.projectInfo.id,
        activityId: this.activityId,
        params: {
          paginate: false,
          activityType: "with",
          activityStage: "with",
          activitySettings: "with",
          activityIdeastormCategories: "with",
          socialBias: "with",
          stimulusAssets: "with",
          appendSignedUrls: "1"
        }
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_INFO, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
          this.fetchIdeastormResponse();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity information, please check back later or contact the helpdesk";
        });
    },
    fetchIdeastormResponse() {
      let payload = {
        activityId: this.activityId,
        params: {
          paginate: false,
          activityIdeastormCategories: "with",
          responseId: this.responseId,
          scope: "input",
          activityIdeastormVotes: "with"
        }
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_IDEASTORM_RESPONSES_LIST, payload)
        .then(() => {
          this.responseLoading = false;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the ideastorm response list, please check back later or contact the helpdesk";
        });
    },
    toggleNewMessage() {
      this.showNewMessageBox = !this.showNewMessageBox;
    },
    responses_created() {
      this.toggleNewMessage();
      this.pageLoader.componentsCompleted = 0;
      this.fetchActivityInfo();
    },
    cleanup: function() {
      this.setActivityInfo([]);
      this.resetActivityIdeastormRepsonsesList([]);
      this.resetActivityReplyEngagementsList([]);
    },
    ...mapMutations({
      setActivityInfo: "setActivityInfo",
      resetActivityIdeastormRepsonsesList: "resetActivityIdeastormRepsonsesList",
      resetActivityReplyEngagementsList: "resetActivityReplyEngagementsList"
    })
  },
  computed: {
    ...mapGetters([
      "projectInfo",
      "currentUser",
      "activityInfo",
      "activityIdeastormRepsonsesList",
      "activityIdeastormResponsesFilter"
    ]),
    activityId: function() {
      return this.$route.params.id;
    },    
    responseId: function() {
      return this.$route.params.responseId;
    },
    activityType: function() {
      return this._.toLower(this.activityInfo.activity_type.name);
    },
    activityTypeId: function() {
      return this.activityInfo.activity_type.id;
    },
    activityUnbiased: function() {
      return this.activityInfo.social_bias.id == 1 ? true : false;
    },
    showResponse: function() {
      return this.userPosted || !this.activityUnbiased;
    },
    userPosted: function() {
      let index = this.activityIdeastormRepsonsesList.findIndex(
        replies => replies.user.id === this.currentUser.id
      );
      return index == -1 ? false : true;
    },
    prepopulatedResponse: function() {
      let setting = this._.find(this.activityInfo.activity_settings, { id: 1 });
      return setting ? String(setting.pivot.value) : "";
    },
    ideastormCategories: function() {
      let categories = this.activityInfo.activity_ideastorm_categories;
      if (categories.length > 0) {
        categories.forEach(element => {
          element.labelColor = GeneralFunctionsHelper.getSampleColor(
            element.id
          );
          element.value = element.id;
          element.text = element.name;
        });
        return categories;
      } else {
        return [];
      }
    },
    filter: function() {
      return this.activityIdeastormResponsesFilter == ""
        ? "date"
        : this.activityIdeastormResponsesFilter;
    },
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
<style scoped>
.view-label {
  position: relative;
  text-align: center;
  vertical-align: center;
}
</style>
