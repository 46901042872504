<template>
  <div>
    <PinIcon
      :type="type" @pin-selected="click()"
    />

    <el-popover
      v-if="domain != 'review'"
      v-model="visible"
      :title="$t('ACTIVITY.MARKUP.POPOVER_TITLE')"
      placement="right"
      :width="300"
      class="pin-popover"
    >
      <div class="arrow"></div>

      <b-dropdown
        ref="pin-input-select"
        id="pin-input-select"
        class="mb-3 w-100"
        toggle-class="d-flex justify-content-start align-items-center"
      >
        <template #button-content>
          <div class="w-100 text-left">
            <PinIcon
              :type="selectedPins.system_markup_pin_type_id"
              :hoverable="false"
              :height="27"
              :width="27"
              class="mr-1"
            />
            <span>{{ selectedPins.label }}</span>            
          </div>
        </template>

        <b-dropdown-item
          v-for="(pin) in availablePinByType.filter(function(pinType) { return pinType.system_markup_pin_type_id != model.pinType } )"
          :key="pin.id"
          href="javacript:void(0)"
          @click="model.pinType = pin.system_markup_pin_type_id, (pin.prepopulated_response != null) ? $refs['pin-input-select'].actionDropdown = false : update('confirm')"
          v-show="
            pin.placement_maximum == null ||
            (pin.placement_maximum !== null &&
              pin.pinsPut.length < pin.placement_maximum)
          "
        >
          <PinIcon
            :type="pin.system_markup_pin_type_id"
            :hoverable="false"
            :height="27"
            :width="27"
            class="mr-1"
          />

          <span class="mb-0">
            {{ pin.label }}
          </span>
        </b-dropdown-item>
      </b-dropdown>

      <template v-if="selectedPins != null && selectedPins.prepopulated_response !== null">
        <label class="form-label fs-6" :class="{ required: true }">
          {{ selectedPins.prepopulated_response }}
        </label>

        <div
          class="form-control-container position-relative mb-5"
        >
          <textarea
            class="form-control form-control-solid w-100"
            :class="{'border-danger' : confirmFail}"
            v-model="model.text_response"
            rows="4"
          />
        </div>
      </template>

      <div class="popover-buttons">
        <a class="btn btn-sm btn-secondary" @click="update('remove')">
          {{ $t("ACTIVITY.MARKUP.POPOVER_REMOVE") }}
        </a>
        <a
          href="javascript:void(0)"
          class="btn btn-sm btn-primary"
          @click="update('confirm')"
        >
          {{ $t("ACTIVITY.MARKUP.POPOVER_CONFIRM") }}
        </a>
      </div>
    </el-popover>
  </div>
</template>

<script>
import {
  mapGetters
} from "vuex";

import PinIcon from "./PinIcon.vue";

export default {
  name: "MarkupPinDraftDetail",

  props: {
    type:               { type: Number, required: true },
    availablePinByType: { type: Array, required: true },
    selectedPins:       { type: Object, required: false, default: null },
    pin:                { type: Object, required: true },
    domain:             { type: String, required: true },
  },

  data() {
    return {
      model: {
        text_response: "",
        pinType: null,
      },
      formState: {
        submitting: false,
      },
      visible: false,
      confirmFail: false
    };
  },

  components: {
    PinIcon
  },

  mounted() {
    if (this.selectedPins !== null) {
      this.model.pinType = this.selectedPins.system_markup_pin_type_id;
      if (this.selectedPins.prepopulated_response !== null) {
        this.visible = true;
      }
    }
  },

  methods: {
    update(action, model = null, newPin = null) {
      let pin = (newPin != null) ? newPin : this.pin.engagement_question_pin;

      if (action == "confirm" && (pin.prepopulated_response == null || this.model.text_response.length > 0)) {
        this.visible = !this.visible;
        this.confirmFail = false;
      } else {
        this.confirmFail = true;
      }

      let pins = newPin !== null ? newPin : this.selectedPins;
      this.$emit("mutatePin", this.pin, pins, action, model);
    },
    click() {
      if (this.domain == "input") {
        this.visible = !this.visible;
      } else {
        this.$emit("clickPin", this.pin, "pin-detail");
      }
    },
  },

  watch: {
    "model.text_response": {
      handler(newModel) {
        this.update("update", newModel);
      },
      deep: true,
    },
    "model.pinType": {
      handler(newModel, oldModel) {
        if (oldModel !== null) {
          let newType = this.availablePinByType.filter((type) => {
            return type.system_markup_pin_type_id == newModel;
          });
          this.model.text_response = "";
          this.update("select", null, newType[0]);
        }
      },
      deep: true,
    },
    'pin.engagement_question_pin': {
      handler(newPin, oldPin) {
        if (
          typeof newPin.prepopulated_response === 'string' &&
          newPin.prepopulated_response.length > 0 &&
          (
            oldPin == undefined || newPin.id != oldPin.id
          )
        ) {
          this.visible = true;
        }
      },
      immediate: true,
      deep: true
    }
  },

  computed: {
    ...mapGetters([
      "systemMarkupPinTypes"
    ]),
    selectedPin: function() {
      const self = this;
      return this.systemMarkupPinTypes.filter(function(systemPinType) {
        return systemPinType.id === self.model.pinType;
      })[0];
    }
  }
};
</script>

<style lang="scss" scoped>
  .popover-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .no-padding {
    padding: 0px !important;
  }

  .pin-popover .el-popover.el-popper {
    left: -40px;
    top: -40px;
  }
</style>