<template>
  <div v-if="!localDelete">

    <PictureBookDisplay
      :response="response"
      :post="post"
      :isModal="false"
      @show-modal="$refs[modalRef].show();"
    >
    </PictureBookDisplay>

    <div class="d-flex justify-content-between">
      <div class="d-flex justify-content-start">
        <PictureBookSentiment :post="post" size="xs" :activityId="response.activity_id"></PictureBookSentiment>
        <PictureBookComment :post="post" @show-modal="$refs[modalRef].show();"></PictureBookComment>
      </div>
      <PictureBookDelete
        v-if="canDelete"
        size="xs"
        :post="post"
        :activityId="response.activity_id"
        @deleted="localDelete = true"
      >
      </PictureBookDelete>
    </div>

    <b-modal
      :ref="modalRef"
      :id="modalRef"
      size="lg"
      hide-header
      hide-footer
    >
      <PictureBookModal
        :response="response"
        :post="post"
        @deleted="localDelete = true"
      >
      </PictureBookModal>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PictureBookComment from "./PictureBookComment";
import PictureBookSentiment from "../../engagement/Sentiment";
import PictureBookModal from "./PictureBookModal";
import PictureBookDisplay from "./PictureBookDisplay";
import PictureBookDelete from "./PictureBookDelete";

export default {
  name: "PictureBookThumb",

  props: {
    post: { type: Object, required: true },
    response: { type: Object, required: true }
  },

  data() {
    return {
      localDelete: false
    }
  },

  components: {
    PictureBookComment,
    PictureBookSentiment,
    PictureBookModal,
    PictureBookDisplay,
    PictureBookDelete
  },

  computed: {
    ...mapGetters(["currentUser"]),

    modalRef: function() {
      return 'pb-modal-' + this.response.id;
    },

    canDelete: function() {
      if (
        this.currentUser.id == this.response.user.id ||
        ['community manager','super_admin','admin'].includes(this.currentUser.relation_to_project)
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
