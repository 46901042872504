<template>
  <a
    href="javascript:void(0)"
    @click="onSentimentEngage"
    class="btn btn-icon-danger btn-sm btn-text-dark-50 bg-hover-light-danger btn-hover-text-danger rounded font-weight-bolder font-size-xs p-2"
    v-b-tooltip.hover.bottom
    :title="tooltip"
  >
    <span class="svg-icon svg-icon-md svg-icon-danger pr-1">
      <i
        class="fa-heart text-danger fa-lg"
        :class="{ 'far' : myEngagements.length == 0, 'fas' : myEngagements.length > 0 }"
      >
      </i>
    </span>
    {{ engagements.length }}
  </a>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "InteractionSentiment",

  props: {
    engagements:  { type: Array, required: true }
  },

  methods: {
    onSentimentEngage: function() {
      this.$emit('on-engagement',{
        type: (this.myEngagements.length > 0) ? 'remove' : 'add',
        engagementId: (this.myEngagements.length > 0) ? this.myEngagements[0].id : null,
        engagement: { sentiment: 1 }
      });
    },
  },

  computed: {
    ...mapGetters([
      "currentUser"
    ]),
    myEngagements: function() {
      const self = this;
      let myEngagements = this.engagements.filter(function(sentiment) {
        return sentiment.user_id == self.currentUser.id;
      });
      return myEngagements;
    },
    tooltip: function() {
      let tooltip = null;

      if (this.myEngagements.length > 0) {
        return `${this.$t("REVIEW.STREAM.SENTIMENT_SUMMARY_ME")}`;
      } else if (this.engagements.length == 1) {
        return this.engagements.length + ` ${this.$t("REVIEW.STREAM.SENTIMENT_SUMMARY_SINGULAR")}`;
      } else if (this.engagements.length > 1 || this.engagements.length == 0) {
        return this.engagements.length + ` ${this.$t("REVIEW.STREAM.SENTIMENT_SUMMARY_PLURAL")}`;
      };

      return tooltip;
    }
  }
};
</script>
