<template>
  <div :id="`response-question-${question.id}-${baseResponse}`" class="d-flex justify-content-between">
    <div class="row d-flex justify-content-start mx-0">
      <p class="mb-2 font-weight-bolder">Question {{ number }}: </p>
      <p
        class="ml-2 mb-10 text-muted"
        :class="{ 'fitb' : question.system_answer_type_id == 8 }"
        v-html="`${displayedQuestion}`"
      ></p>
    </div>
    <a
      v-if="isQuantQuestion && isAdminTheme"
      @click="$emit('open-chart-modal', { activityId: activityId, question: question })"
      href="javascript:void(0)"
      class="text-muted"
    >
      {{ $t('REVIEW.STREAM.VIEW_CHART') }}
    </a>


  </div>
</template>

<script>
export default {
  name: "SurveyQuestion",

  props: {
    baseResponse: { type: Number, required: false },
    number: { type: Number, required: true },
    question: { type: Object, required: true },
    activityId: { type: Number, required: true },
    selectedSlot: { type: String, required: false }
  },

  data() {
    return {
      show_full_question: false
    }
  },

  mounted() {
    let ellipses = document.getElementById(`response-question-${this.question.id}-${this.baseResponse}`).getElementsByClassName("ellipses-link");
    if (ellipses.length > 0) {
      ellipses = ellipses[0];
      const self = this;
      ellipses.addEventListener('click', function() {
        self.show_full_question = true;
        self.$emit("slot_changed",null);
      });
    }

    if (this.baseResponse != null) {
      this.bindFITBAnchors();
    }
  },

  methods: {
    bindFITBAnchors: function() {
      let questionSlots = document.getElementById(`response-question-${this.question.id}-${this.baseResponse}`).getElementsByClassName("fitb-anchor");
      const self = this;
      questionSlots.forEach(function(anchor) {
        anchor.addEventListener("click", function() {
          let newSlot = (self.selectedSlot == anchor.id) ? null : anchor.id;
          self.$emit("slot_changed",newSlot);
          self.reformatSlots(newSlot);
        });
      });
    },
    reformatSlots: function(newSlot) {
      let questionSlots = document.getElementById(`response-question-${this.question.id}-${this.baseResponse}`).getElementsByClassName("fitb-anchor");
      const self = this;
      questionSlots.forEach(function(anchor) {
        if (newSlot == null || newSlot != anchor.id) {
          anchor.classList.remove("selected");
        } else {
          anchor.classList.add("selected");
        }
      })
    }
  },

  watch: {
    displayedQuestion: {
      handler() {
        if (this.baseResponse != null) {
          const self = this;
          setTimeout(function(){
            self.bindFITBAnchors()
          }, 500);
        }
      }
    }
  },

  computed: {
    isQuantQuestion: function() {
      const quantQuestionTypes = [2,3,5];

      return (quantQuestionTypes.includes(this.question.system_answer_type_id)) ? true : false;
    },
    isAdminTheme: function() {
      return (process.env.VUE_APP_SUBMODULE_IDENTIFIER == "ADMIN") ? true : false;
    },
    displayedQuestion: function() {
      if (this.question.system_answer_type_id == 8) {
        return (this.show_full_question) ? this.question.fitbQuestion : this.question.longerQuestion;
      } else {
        return (this.show_full_question) ? this.question.question : this.question.longerQuestion;
      }
    },
  }
}
</script>