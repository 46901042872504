class HighlightHelper {

  constructor() {}

  /**
   * This method delegates to one of two private methods for more specific functionality based on whether the "textToHighlight" is a word type of literal type.
   * 
   * @param {string} textToHighlight 
   * @param {string} textHTML 
   * @returns string
   */
  highlightText(textToHighlight, textHTML) {
    switch (this.searchType(textToHighlight)) {
      case 'word':
        return this.#highlightWord(textToHighlight, textHTML);
      case 'literal':
      default:
        return this.#highlightLiteral(textToHighlight, textHTML);
    }
  }

  /**
   * For this logic we know we're only highlighting a single word, so we split the response text by spaces and check if the lowercase version of the
   * highlight text matches the lowercase version of the word. If true we wrap the newly highlighted word in a span tag with the "highlight" class.
   * 
   * @param {string} textToHighlight 
   * @param {string} textHTML 
   * @returns string
   */
  #highlightWord(textToHighlight, textHTML) {
    textToHighlight = textToHighlight.trim();

    const highlightedText = textHTML.match(/[\w']+|[.,!?]/g)
      .map(word => word.toLowerCase() === textToHighlight.toLowerCase()
        ? `<span class="highlight">${word}</span>`
        : word)
      .join(' ');

    return highlightedText;
  }

  #highlightLiteral(textToHighlight, textContent) {
    const regex = new RegExp(textToHighlight, 'gi');
    return textContent.replace(regex, `<span class="highlight">$&</span>`);
  }

  /**
   * Search type can be either "word" or "literal".
   * "Word" must be a distinct word appearing at either the beginning or end of string, or within the string separated by spaces. It cannot be multiple words
   * "Literal" can be any occurence of the word in the string
   * Type is determined by positioning of spaces, `textToHighlight` has a space at the beginning and end it is considered "word" otherwise "literal"
   * 
   * @param {string} textToHighlight 
   */
  searchType(textToHighlight) {
    const pattern = /^\s+[^ ]*[^ ]\s+$/;
    return (pattern.test(textToHighlight)) ? "word" : "literal";
  }
}

export default HighlightHelper;