<template>
  <div>
    <PictureBookDisplay :response="response" :post="post" :isModal="true">
    </PictureBookDisplay>

    <div class="d-flex justify-content-between mb-5">
      <Sentiment
        :post="post"
        size="sm"
        :activityId="response.activity_id"
      ></Sentiment>

      <PictureBookCaption
        v-if="currentUser.id == response.user.id"
        :post="post"
      >
      </PictureBookCaption>

      <PictureBookDelete
        v-if="currentUser.id == response.user.id"
        size="sm"
        :post="post"
        @deleted="$emit('deleted')"
      >
      </PictureBookDelete>
    </div>

    <div class="mt-10 d-flex flex-row-reverse">
      <button
        class="btn btn-primary"
        @click="toggleNewMessage()"
        v-show="!showNewMessageBox"
      >
        {{ $t("RESPONSE_FORMS.INPUTS.NEW_POST") }}
      </button>
    </div>

    <!--begin::Message input Box-->
    <GenericResponseBox
      v-if="pageLoader.componentsCompleted >= pageLoader.componentsRequired"
      mode="post-comment"
      prepResponse=""
      v-show="showNewMessageBox"
      :dataRespondingTo="post.id"
      :activity="post.activity"
      @cancelAction="toggleNewMessage"
      @refreshList="refreshList"
    ></GenericResponseBox>
    <!--end::Message input Box-->

    <!--begin::Response Lists-->
    <div v-if="pageLoader.componentsCompleted >= pageLoader.componentsRequired">
      <template v-for="(reply, index) in replies">
        <Response
          :layer="index"
          :reply="reply"
          :key="index"
          :type="`pictureBookResponse`"
          :activity="post.activity"
          @deleteComment="deleteComment"
          @deleteResponse="deleteComment"
        ></Response>
      </template>
    </div>
    <div v-else class="d-flex justify-content-center my-10">
      <b-spinner label="Loading..."> </b-spinner>
    </div>
    <!--end::Response Lists-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PictureBookDisplay from "./PictureBookDisplay";
import PictureBookCaption from "./PictureBookCaption";
import PictureBookDelete from "./PictureBookDelete";

import Response from "../../../components/Response.vue";
import Sentiment from "../../engagement/Sentiment";
import GenericResponseBox from "../../../components/generic-inputs/GenericResponseBox";

import { POPULATE_ACTIVITY_PICTURE_BOOK_RESPONSES_INFO } from "../../../vuex/activity/response/activity_picture_book_responses.module";

import { DELETE_REPLY_ENGAGEMENTS } from "../../../vuex/activity/engagement/activity_reply_engagements.module";

export default {
  name: "PictureBookModal",

  props: {
    response: { type: Object, required: true },
    post: { type: Object, required: true }
  },

  data() {
    return {
      showNewMessageBox: false,
      pageLoader: {
        componentsRequired: 1,
        componentsCompleted: 0
      }
    };
  },

  components: {
    PictureBookDisplay,
    Sentiment,
    PictureBookCaption,
    Response,
    PictureBookDelete,
    GenericResponseBox
  },

  mounted() {
    this.fetchResponses();
  },

  methods: {
    toggleNewMessage() {
      this.showNewMessageBox = !this.showNewMessageBox;
    },
    refreshList() {
      this.fetchResponses();
    },
    fetchResponses() {
      let payload = {
        activityId: this.response.activity_id,
        activityPictureBookResponseId: this.post.id,
        params: {
          paginate: false,
          replies: "with"
        }
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_PICTURE_BOOK_RESPONSES_INFO, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        });
    },
    deleteComment(val) {
      let payload = {
        activity_id: this.response.activity_id,
        id: val.activity_response_id
      };
      this.$store.dispatch(DELETE_REPLY_ENGAGEMENTS, payload).then(() => {
        this.$toasted.global.success_toast({
          message: this.$t("RESPONSE_FORMS.DELETE_SUCCESS.5")
        });
        this.refreshList();
      });
    }
  },

  computed: {
    ...mapGetters(["activityPictureBookRepsonsesInfo", "currentUser"]),
    replies: function() {
      let replies = this.activityPictureBookRepsonsesInfo.replies
        .slice()
        .reverse();
      return replies;
    }
  }
};
</script>
