class InteractService {

  interactAction;
  removeAction;
  interactions = [];

  constructor(
    interactions,
    interactAction,
    removeAction
  ) {
    this.interactAction = interactAction;
    this.removeAction = removeAction; 
    this.interactions = interactions;
  }

  interact(interactionTypeId, polyType, polyId, interactionPayload, baseResponse) {
    return new Promise((resolve, reject) => {
      let payload = {
        ...interactionPayload,
        ...{
          engagement_response_id: baseResponse.id,
          engagement_interaction_type: polyType,
          engagement_interaction_id: polyId,
          system_interaction_type_id: interactionTypeId
        }
      };

      return this.interactAction(payload)
        .then((res) => {
          this.interactions.push(res);
          resolve(res);
        });
    })
  }

  remove(engagementInteractionId, baseResponse) {
    return new Promise((resolve, reject) => {
      let payload = {
        engagement_response_id: baseResponse.id,
        engagement_interaction_id: engagementInteractionId,
      };

      return this.removeAction(payload)
        .then((res) => {
          this.interactions = this.interactions.filter(function(interaction) {
            return interaction.id != engagementInteractionId;
          })
          resolve(res);
        });
    })
  }
}

export default InteractService;