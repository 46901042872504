<template>
  <div class="d-flex w-100 justify-content-center flex-wrap">
    <!--begin::Pin Instruction -->
    <div class="d-flex w-100 flex-row-reverse">
      <span class="font-size-xs text-success text-right"
        >{{ $t("ACTIVITY.MARKUP.IMAGE_PIN_INSTRUCTION") }}
      </span>
    </div>
    <!--end::Pin Instruction -->

    <!--begin::Questions -->
    <h5
      class="mt-5 text-bold w-100 text-center"
      v-html="question.question"
    ></h5>
    <!--end::Questions -->

    <!--begin::Pin Instruction -->
    <div class="d-flex w-100 flex-row-reverse">
      <span
        :class="displayCountPinsError ? 'text-danger' : 'text-success'"
        class="font-size-xs"
        >{{ remainPinLabelText }}
      </span>
      <i class="fa-sm fa fa-map-marker-alt text-danger mr-3"></i>
    </div>
    <!--end::Pin Instruction -->

    <!--begin::Image -->
    <div class="mt-5 d-flex w-100 justify-content-center position-relative">
      <!-- Mark Up Image -->
      <img
        class="mark-up-image"
        :src="markUpImage"
        ref="markUpImage"
        @load="loadedImage()"
      />
      <!-- Mark Up Image -->

      <div
        class="mark-up-image-overlay"
        ref="mark-up-image-overlay"
        :show="imageLoaded"
        :style="overlaySize"
        v-on:mousedown.self="puttingPins($event)"
      >
        <MarkUpImagePins
          v-for="index in availablePins"
          v-dragged="onDragged"
          :key="index"
          :index="index"
          :ref="`pin-${index}`"
          :pinsColor="pinsColor(index)"
          :maxY="maxY"
          :maxX="maxX"
          :requiredTextResponse="requiredTextResponse"
          :requiredSentiment="requiredSentiment"
          :requiredSentimentTextResponse="requiredSentimentTextResponse"
          :requiredFillPopover="requiredFillPopover"
          :textResponseLabel="textResponseLabel"
          :sentimentLabel="sentimentLabel"
          :sentimentTextResponseLabel="sentimentTextResponseLabel"
          :imageLoaded="imageLoaded"
          @removePins="resetPutPins($event)"
        ></MarkUpImagePins>
      </div>
    </div>
    <!--end::Image -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MarkUpImagePins from "@/view/components/activity/mark_up/MarkUpImagePins.vue";
import GeneralFunctionsHelper from "@/helpers/GeneralFunctions";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "MarkUpImageQuestions",

  props: {
    question: { type: Object, required: true },
    form: { type: Array, required: true },
    pinCountType: { type: Number, required: true },
    pinCount: { type: Number, required: true }
  },

  components: {
    MarkUpImagePins
  },

  validations: {
    form: {
      $each: {
        pins: {
          required,
          $each: {
            pin_x: {
              required
            },
            pin_y: {
              required
            },
            text_response: {
              required: requiredIf(function() {
                return this.requiredTextResponse;
              })
            },
            sentiment: {
              required: requiredIf(function() {
                return this.requiredSentiment;
              })
            },
            sentiment_text_response: {
              required: requiredIf(function() {
                return this.requiredSentimentTextResponse;
              })
            }
          }
        }
      }
    }
  },

  data() {
    return {
      pinsPut: 0,
      availablePins: 0,
      displayCountPinsError: false,
      imageLoaded: false,
      isDragging: false
    };
  },

  watch: {
    question: {
      handler() {
        this.resetQuestionsPins();
      },
      deep: true
    },
    pinsPut: {
      handler(val) {
        if (val < 1) {
          return (this.displayCountPinsError = true);
        } else if (this.pinCountType !== 0 && val < this.pinCount) {
          return (this.displayCountPinsError = true);
        }
        return (this.displayCountPinsError = false);
      },
      deep: true
    }
  },

  mounted() {
    this.availablePins = this.form[0].pins.length;
  },

  methods: {
    dragStart() {
      this.isDragging = true;
    },
    dragEnd() {
      this.isDragging = false;
    },
    loadedImage() {
      this.imageLoaded = true;
      let param = {
        X: this.maxX,
        Y: this.maxY
      };
      this.$emit("loadedImageXY", param);
    },
    puttingPins(el) {
      if (this.isDragging) {
        return false;
      } else {
        //check remaining number of pins exceed
        if (this.pinsPut === this.availablePins) {
          // If it is not exceeding, we have to expand the form arr
          if (this.availablePins < this.pinCount) {
            this.availablePins++;
            this.$emit("addAnotherPins", this.question.id);
          } else {
            this.setError(this.$t("ACTIVITY.MARKUP.EXCEED_PIN_ERRORS"));
            return false;
          }
        }
        let target_pin = this.pinsPut + 1;
        this.settingPinCordinateData(this.pinsPut, el.offsetX, el.offsetY);
        this.displayClickedInPins(target_pin, el.offsetX, el.offsetY);
        this.pinsPut++;

        return true;
      }
    },

    /* eslint-disable no-unused-vars */
    onDragged({
      el,
      deltaX,
      deltaY,
      offsetX,
      offsetY,
      clientX,
      clientY,
      first,
      last
    }) {
      if (first) {
        this.isDragging = true;
        return;
      }
      if (last) {
        this.isDragging = false;
        return;
      }
      var l = +window.getComputedStyle(el)["left"].slice(0, -2) || 0;
      var t = +window.getComputedStyle(el)["top"].slice(0, -2) || 0;

      const boundaries = {
        minX: -25,
        minY: -44,
        maxX: this.maxX - 25,
        maxY: this.maxY - 44
      };
      let computedX = l + deltaX;
      let computedY = t + deltaY;
      if (boundaries.minX < computedX && boundaries.maxX > computedX) {
        el.style.left = computedX + "px";
      }
      if (boundaries.minY < computedY && boundaries.maxY > computedY) {
        el.style.top = computedY + "px";
      }
      let pin_x = computedX + 25;
      let pin_y = computedY + 44;
      let index = this._.trim(this._.trim(el.id, "pin-"), "-container");
      this.settingPinCordinateData(index - 1, pin_x, pin_y);
      return false;
    },
    settingPinCordinateData(pinIndex, X, Y) {
      this.form[0].pins[pinIndex].pin_x = X;
      this.form[0].pins[pinIndex].pin_y = Y;
    },
    resetPutPins($event) {
      this.$nextTick(function() {
        let target_pin = $event.pinIndex - 1;
        let refPin = $event.pinRef;
        if (this.form[0].pins[target_pin] !== undefined) {
          this.form[0].pins[target_pin].pin_x = 0;
          this.form[0].pins[target_pin].pin_y = 0;
          if (this.requiredTextResponse || this.textResponseLabel) {
            this.form[0].pins[target_pin].text_response = null;
          }
          if (this.requiredSentiment || this.sentimentLabel) {
            this.form[0].pins[target_pin].sentiment = null;
          }
          if (
            this.requiredSentimentTextResponse ||
            this.sentimentTextResponseLabel
          ) {
            this.form[0].pins[target_pin].sentiment_text_response = null;
          }
          this.$refs[refPin][0].resetPinsPostions();
          if ($event.caller === "removePins") {
            this.pinsPut--;
          }
        }
        return false;
      });
    },
    resetQuestionsPins() {
      this.$nextTick(function() {
        // reset previous put pins after DOM update
        Object.keys(this.$refs).forEach(pinRefIndex => {
          let pin = this._.toString(pinRefIndex);
          if (this.$refs[pin][0] !== undefined && pin.includes("pin-")) {
            let arr = {
              pinIndex: this._.trim(pin, "pin-"),
              pinRef: pin,
              caller: "resetQuestionsPins"
            };
            this.resetPutPins(arr);
          }
        });
        this.availablePins = this.form[0].pins.length;
        this.pinsPut = 0;
        return false;
      });
    },
    displayClickedInPins(index, x, y) {
      let pins = `pin-${index}`;
      this.$nextTick(function() {
        // update new pin position after DOM update
        this.$refs[pins][0].newPinsPostions(x, y);
      });
    },
    pinsColor(index) {
      return GeneralFunctionsHelper.getSampleColor(index);
    },
    setError(msg) {
      this.$toasted.global.error_toast({
        message: msg
      });
      return;
    }
  },

  computed: {
    ...mapGetters(["activityInfo"]),
    remainingMaxPins: function() {
      return this.question.pin_count - this.pinsPut;
    },
    remainPinLabelText: function() {
      let prefix = this.$t("ACTIVITY.MARKUP.PIN_DESC_PREFIX");
      let suffix = this.$t("ACTIVITY.MARKUP.PIN_DESC_SUFFIX");
      return `${prefix} ${this.question.type_label} ${this.remainingMaxPins} ${suffix}`;
    },
    requiredTextResponse: function() {
      let prompt = this._.isString(this.question.text_response_prompt);
      let required = this.question.text_response_required;
      return prompt && required ? true : false;
    },
    requiredSentiment: function() {
      let prompt = this._.isString(this.question.sentiment_response_prompt);
      let required = this.question.sentiment_response_required;
      return prompt && required ? true : false;
    },
    requiredSentimentTextResponse: function() {
      let prompt = this._.isString(
        this.question.sentiment_text_response_prompt
      );
      let required = this.question.sentiment_text_response_required;
      return prompt && required ? true : false;
    },
    textResponseLabel: function() {
      return this.question.text_response_prompt;
    },
    sentimentLabel: function() {
      return this.question.sentiment_response_prompt;
    },
    sentimentTextResponseLabel: function() {
      return this.question.sentiment_text_response_prompt;
    },
    requiredFillPopover: function() {
      let requiredTextResponse = this._.isString(
        this.question.text_response_prompt
      );
      let requiredSentiment = this._.isString(
        this.question.sentiment_response_prompt
      );
      let requiredSentimentTextResponse = this._.isString(
        this.question.sentiment_text_response_prompt
      );
      return requiredTextResponse ||
        requiredSentiment ||
        requiredSentimentTextResponse
        ? true
        : false;
    },
    markUpImage: function() {
      return this.activityInfo.stimulus_assets[0].signedUrl;
    },
    maxY: function() {
      return this.imageLoaded ? this.$refs.markUpImage.clientHeight : 0;
    },
    maxX: function() {
      return this.imageLoaded ? this.$refs.markUpImage.clientWidth : 0;
    },
    overlaySize: function() {
      return `width: ${this.maxX}px; height: ${this.maxY}px;`;
    }
  }
};
</script>