<template>
  <div>
    <InputGenericTextArea
      id="reply-input"
      class="mb-3"
      :model="$v.form.reply"
      rows="6"
      :submissionStates="submissionStates"
      :slim="true"
    >
    </InputGenericTextArea>
    <div class="row d-flex justify-content-between mx-0">
      <div class="d-flex justify-content-start">
        <InputGenericCheckbox
          id="reply-input"
          class="mr-4 mb-0 pt-1"
          label="Request Reply"
          :model="$v.form.probe"
          v-if="domain == 'admin'"
          :submissionStates="submissionStates"
          :slim="true"
        >
        </InputGenericCheckbox>
      </div>
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-outline-primary mr-2"
          @click="$emit('reply-input-closed')"
        >
          {{ $t('GENERAL.BUTTONS.CANCEL') }}
        </button>
        <button
          class="btn btn-primary"
          @click="$emit('reply-input-added', form), resetForm(), $emit('reply-input-closed')"
        >
          {{ $t('GENERAL.BUTTONS.SAVE') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import InputGenericTextArea from "@/modules/together-helpers/components/generic-inputs/GenericTextArea";
import InputGenericCheckbox from "@/modules/together-helpers/components/generic-inputs/GenericCheckbox";

export default {
  mixins: [validationMixin],

  name: "ReplyInput",

  props: {
    domain:   { type: String, required: true, default: "admin" }
  },

  data() {
    return {
      submissionStates: {
        submitting: false
      },
      form: {
        type: 'add',
        reply: "",
        probe: (this.domain == 'admin') ? 1 : 0,
        private: 0
      },
      formReset: null
    }
  },

  validations: {
    form: {
      reply: { required },
      probe: { required },
      private: { required }
    }
  },

  components: {
    InputGenericTextArea,
    InputGenericCheckbox
  },

  methods: {
    toastPublicReply: function() {
      this.$root.$bvToast.toast(`${this.$t("TOASTS.ADD_REPLY.MESSAGE")}`, {
        title: `${this.$t("TOASTS.ADD_REPLY.TITLE")}`,
        autoHideDelay: 5000,
        appendToast: false,
        variant: "success"
      });
    },
    toastPrivateReply: function() {
      this.$root.$bvToast.toast(`${this.$t("TOASTS.ADD_REPLY_PRIVATE.MESSAGE")}`, {
        title: `${this.$t("TOASTS.ADD_REPLY_PRIVATE.TITLE")}`,
        autoHideDelay: 5000,
        appendToast: false,
        variant: "success"
      });
    },
    resetForm: function() {
      this.form.type    = 'add',
      this.form.reply   = '',
      this.form.probe   = (this.domain == 'admin') ? 1 : 0,
      this.form.private = 0;

      this.$v.form.$reset()
    }
  }
}
</script>