<template>
  <div>
    <b-form-group
      :id="id + '-choices'"
      v-slot="{ ariaDescribedby }"
    >
      <b-form-checkbox-group
        v-model="model.$model"
        :state="validateState()"
        :aria-describedby="ariaDescribedby"
        size="lg"
        :stacked="showInline ? false : true"
        :disabled="disabled || submissionStates.submitting === true"
      >
        <template v-for="(option, idx) in options">
          <div
            class="row d-flex justify-content-between w-100"
            v-bind:key="option.id"
          >
            <div class="choice-display justify-content-start">
              <b-form-checkbox
                :value="option.value"
              >
                <p class="pt-1">
                  {{ option.text }}
                </p>
              </b-form-checkbox>
            </div>
            <div class="justify-content-end">
              <b-button
                v-if="option.choiceStimuli"
                :id="`image-${option.value}`"
                class="no-decoration"
              >
                <InputGenericThumbGallery
                  :projectAssets="option.choiceStimuli"
                  classStyle="sm"
                  class="mb-0"
                >
                </InputGenericThumbGallery>
              </b-button>
            </div>
          </div>
          <div v-if="anyChoiceStimuli && idx != (options.length - 1)" v-bind:key="option.value" class="separator separator-dashed my-6"></div>
        </template>
      </b-form-checkbox-group>
    </b-form-group>
    <div class="custom-validation-failure mb-7" v-if="model.$error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import InputGenericThumbGallery from "@/view/components/form-inputs/GenericThumbGallery.vue";

export default {
  name: "MultipleChoiceQuestion",
  
  props: {
    id: { type: String, required: true },
    disabled: { type: Boolean, required: false },
    showInline: { type: Boolean, required: false },
    error: { type: String, required: false },
    options: { type: Array, required: true },
    model: {type: Object, required: true },
    submissionStates: { type: Object, required: true }
  },

  components: {
    InputGenericThumbGallery
  },

  methods: {
    validateState() {
      const { $dirty, $error } = this.model;
      return $dirty ? !$error : null;
    }
  },

  computed: {
    anyChoiceStimuli: function() {
      let count = 0;

      this.options.forEach(choice => {
        if (choice.choiceStimuli != undefined) {
          count = count + choice.choiceStimuli.length;
        }
      });

      if (count == 0) {
        return false;
      } else {
        return true;
      };
    }
  }
};
</script>

<style>
.custom-control-label {
  width: 100% !important;
  max-width: 750px;
}
</style>
