<template>
  <div class="fitb">
    <b-form-input
      :id="`${questionId}-${this.slotNumber}-input`"
      v-model="text"
      :placeholder="`${$t('FITB.PLACEHOLDER.OPEN_ENDED')}`"
      class="fitb-input fitb-open-ended"
      v-observe-visibility="visibilityChanged"
      :class="{ 'fitb-invalid' : invalid }"
    >
    </b-form-input>

    <b-tooltip
      v-if="invalid"
      :target="`${questionId}-${this.slotNumber}-input`"
      triggers="hover"
      placement="right"
    >
      {{ $t('FITB.ERROR.OPEN_ENDED') }}
    </b-tooltip>
  </div>

</template>

<script>
export default {
  name: "fitbComponentOpenEnded",

  props: {
    block: { type: Object, required: true },
    slotNumber: { type: Number, required: true },
    fitbErrorSlot: { type: Array, required: true },
    questionId: { type: Number, required: true }
  },

  data() {
    return {
      defaultWidth: null,
      invalid: false,
      text: ""
    }
  },

  mounted() {
    let fitb = document.getElementById(`${this.questionId}-${this.slotNumber}-input`);
    this.defaultWidth = fitb.getAttribute('placeholder').length;
  },

  methods: {
    visibilityChanged: function(isVisible) {
      if (isVisible) {
        this.adaptInputSize();
      }
    },
    adaptInputSize: function() {
      let fitb = document.getElementById(`${this.questionId}-${this.slotNumber}-input`);
      if (this.text.length > 0) {
        if (this.text.length > this.defaultWidth) {
          fitb.setAttribute('size',(this.text.length > 100) ? 100 : this.text.length);
        } else {
          fitb.setAttribute('size',this.defaultWidth);
        }
      } else {
        fitb.setAttribute('size',this.defaultWidth);
      }
    }
  },

  watch: {
    text: {
      handler(newSlotResponse) {
        this.adaptInputSize();
        this.$emit('blank-filled',{ slotResponse: newSlotResponse, slotNumber: this.slotNumber });
      },
      immediate: false
    },
    fitbErrorSlot: {
      handler(slots) {
        let inArray = false;
        for (let index = 0; index < slots.length; index++) {
          const element = slots[index];
          if (this.slotNumber == element) inArray = true;
        }
        this.invalid = inArray
      },
      immediate: false
    }
  }
}
</script>

<style scoped>
.custom-validation-failure {
  width: auto!important;
  margin-top: unset !important;
}
</style>