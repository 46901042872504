<template>
  <div>

    <div class="d-flex justify-content-between align-items-center mb-7 mt-10">
      <div class="d-flex justify-content-start align-items-center">
        <UserThumbnail
          class="mr-5"
          :user="response.user"
          size="40"
        />

        <AnonymisedUsername
          :user="response.user"
        />
      </div>

      <div class="d-flex justify-content-end align-items-center" >
        <span class="text-muted font-weight-normal flex-grow-1 font-size-sm">
          <GenericDatetimeDisplay
            :long="true"
            :date="response.updated_at"
            :lastUpdated="true"
            :relative="false"
          >
          </GenericDatetimeDisplay>          
        </span>
      </div>
    </div>

    <PinnedImage
      type="review"
      id="markup_pins"
      ref="pinContainer"
      :engage="engage"
      :activity="response.activity"
      :imageUrl="response.activity.engagement_stimuli_assets[0].signedUrl"
      :reactHandler="react_tmp"
    />

    <InlineEngagement
      ref="engagement"
      type="base"
      :allowInteractions="['Sentiment','Reply']"
      :allowDestroy="false"
      :baseResponse="response"
      :response="response"
      :hovering="false"
      morph="EngagementResponse"
      domain="input"
    />  
  </div>
</template>

<script>
import Engage from "@/core/services/EngageService";

import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay";
import UserThumbnail from "@/modules/together-helpers/components/generic-displays/GenericUserThumbnail";
import PinnedImage from "@/modules/together-helpers/activities/response/answer-types/PinnedImage.vue";
import InlineEngagement from "@/modules/together-helpers/activities/response/InlineEngagementNewSchema.vue";

export default {
  name: "NewMarkupDisplay",

  props: {
    response:   { type: Object, required: true }
  },

  data() {
    return {
      engage: new Engage(
        this,
        null,
        null,
        null
      ),
      react_tmp: 1
    }
  },

  components: {
    GenericDatetimeDisplay,
    UserThumbnail,
    PinnedImage,
    InlineEngagement
  },

  mounted() {
    this.engage.setResponseValue("markup_pins", this.response.engagement_question_pin_responses);
  }
}
</script>