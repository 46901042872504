var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],staticClass:"btn btn-icon-primary btn-sm btn-text-dark-50 bg-hover-light-primary btn-hover-text-primary rounded font-weight-bolder font-size-xs p-2",attrs:{"id":"notepad_toggle","href":"javascript:void(0)","title":_vm.tooltip},on:{"click":function($event){return _vm.$emit('toggle-notepad', {
      tab: 1,
      morphType: _vm.morphType,
      morphId: _vm.morphId,
      activityId: _vm.baseResponse.activity.id,
      baseResponse: _vm.baseResponse
    })}}},[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-primary pr-1"},[_c('i',{staticClass:"fa-sticky-note text-primary fa-lg",class:{ 'far' : _vm.myEngagements.length == 0, 'fas' : _vm.myEngagements.length > 0 }})]),_vm._v(" "+_vm._s(_vm.engagements.length)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }