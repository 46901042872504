var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-0"},[(_vm.type == 'input')?_c('div',{staticClass:"w-100 text-center card-title markup-area-desc"},[_vm._v(" "+_vm._s(_vm.$t("ACTIVITY.MARKUP_NEW.PIN_LIST_DESC"))+" "),(_vm.confirmedInstructions === true)?_c('a',{staticClass:"text-muted pl-1",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.confirmedInstructions = false}}},[_c('i',{staticClass:"fas fa-info-circle"}),_vm._v(" Instructions ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"d-flex flex-wrap justify-content-center"},[_c('img',{ref:`image-object-${_vm.id}`,staticClass:"w-100",staticStyle:{"height":"0px"},attrs:{"src":_vm.imageUrl},on:{"load":function($event){return _vm.onImageLoaded()}}}),_c('div',{ref:"markUpImage",staticClass:"mark-up-image rounded",style:({
        background: `url(${_vm.imageUrl})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'relative',
        height: `${_vm.imageInputHeight}px`,
        width: `${_vm.imageInputWidth}px`
      }),attrs:{"draggable":""},on:{"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();},"drop":function($event){return _vm.mouseDrop($event)},"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;if($event.target !== $event.currentTarget)return null;return _vm.puttingDraftPins($event)}}},[(_vm.type === 'input' && !_vm.confirmedInstructions)?_c('div',{staticClass:"h-100 w-100"},[_c('div',{staticClass:"w-100 h-100 grey-canvas",staticStyle:{"z-index":"2"}}),_c('PinCompletionCard',{attrs:{"type":_vm.type,"confirmedInstructions":_vm.confirmedInstructions},on:{"on-confirm-instruction":function($event){_vm.confirmedInstructions = true}}})],1):_vm._e(),_vm._l((_vm.model),function(pin,p){return [_c('div',{key:_vm.type == 'input' ? pin.index : p,on:{"dragstart":function($event){return _vm.drag($event, pin)}}},[(pin)?_c('PinDraft',{ref:`pin-icon-${pin.id}`,refInFor:true,attrs:{"pin":pin,"type":_vm.type,"filterValue":_vm.filterValue,"availablePinByType":_vm.pinCountByType},on:{"mutatePin":_vm.mutatePin,"clickPin":_vm.clickPin}}):_vm._e()],1)]})],2)]),(_vm.type == 'input')?_c('PinCompletionSummary',{attrs:{"model":_vm.model}}):_vm._e(),(_vm.type == 'review' && _vm.showTally)?_c('PinCompletionTally',{attrs:{"model":_vm.model}}):_vm._e(),(_vm.type == 'input' || (_vm.type == 'review' && _vm.showResponses))?_c('div',{staticClass:"mt-5",class:{ 'filter-applied': _vm.filterValue.length > 0 }},[(_vm.type === 'review')?_c('div',{staticClass:"d-flex justify-content-center mb-5"},[_c('a',{staticClass:"btn btn-sm",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.showResponses = false}}},[_vm._v(" Hide Responses ")])]):_vm._e(),_vm._l((_vm.model),function(pin,index){return [(
          pin.engagement_question_pin.system_markup_pin_type_id !=
            undefined &&
          pin.engagement_question_pin.system_markup_pin_type_id != null &&
          (_vm.filterValue.length == 0 ||
            _vm.filterValue.includes(pin.engagement_question_pin_id))
        )?_c('div',{key:pin.id},[_c('PinDetail',{ref:`pin-detail-${pin.id}`,refInFor:true,attrs:{"pin":pin,"markUpImage":_vm.imageUrl,"imageX":_vm.image_x,"imageY":_vm.image_y,"type":_vm.type},on:{"on-pin-focus":function($event){return _vm.clickPin(pin, 'pin-icon')},"base-64-preview-generated":function($event){_vm.model[index].preview = $event}}}),_c('hr',{staticClass:"my-2"})],1):_vm._e()]})],2):(_vm.type === 'review')?_c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('a',{staticClass:"btn btn-sm",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.showResponses = true}}},[_vm._v(" Show Responses ")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }