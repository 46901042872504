<template>
  <b-card class="card card-custom gutter-b" header-tag="header">
    <template #header>
      <h5 class="mb-0" v-html="activityHeader(activityInfo)"></h5>
      <div v-if="isSequencedActivity" class="font-size-sm text-muted">
        {{ sequenceLabel }}
      </div>
    </template>

    <b-card-text
      v-if="showIntroduction"
      class="font-size-normal"
      v-html="activityInfo.introduction"
    ></b-card-text>

    <b-card-text
      v-if="showSummary"
      class="font-size-normal"
    >
      {{
        activityInfo.summary
      }}
    </b-card-text>

    <slot name="activity-text" />

    <!--begin::Media File-->
    <b-card-text
      v-if="activityInfo.stimulus_assets"
      class="d-flex justify-content-center"
    >
      <InputGenericThumbGallery
        :projectAssets="activityInfo.stimulus_assets"
        v-if="activityType !== 'markup'"
      >
      </InputGenericThumbGallery
    ></b-card-text>
    <!--end::Media File-->

    <b-card-text>
      <div
        class="d-flex align-items-between justify-content-between pr-2 footer-status"
      >
        <div class="d-flex justify-content-start font-size-xs">
          <!--start::Social Setting-->
          <i class="mr-2 icon-nm pt-1" :class="relativeSocialSetting.class"></i>
          <span
            class="text-muted"
            v-b-tooltip.hover.bottom
            :title="relativeSocialSetting.tooltip"
          >
            {{ relativeSocialSetting.text }}
          </span>
          <!--end::Social Setting-->
        </div>
        <div class="d-flex justify-content-start font-size-sm">
          <span class="mr-2 text-muted">
            {{ relativeDate.prefix }}
          </span>
          <span
            v-if="activityInfo.system_activity_stage_id > 1"
            class="font-size-sm text-muted"
          >
            <GenericDatetimeDisplay
              :long="true"
              :date="relativeDate.date"
              :lastUpdated="true"
              :relative="false"
            >
            </GenericDatetimeDisplay>
          </span>
        </div>
      </div>
    </b-card-text>

    <slot name="activity-panel"></slot>
  </b-card>
</template>

<script>
import InputGenericThumbGallery from "@/view/components/form-inputs/GenericThumbGallery.vue";
import SystemIconHelper from "@/modules/together-helpers/helpers/SystemIconHelper";
import SystemLabelHelper from "@/modules/together-helpers/helpers/SystemLabelHelper";
import GeneralFunctionsHelper from "@/helpers/GeneralFunctions";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay";

export default {
  name: "ActivityGeneraldisplay",

  data() {
    return {};
  },

  props: {
    activityInfo:     { type: Object, required: true },
    showIntroduction: { type: Boolean, required: false, default: true },
    showSummary:      { type: Boolean, required: false, default: true }
  },

  components: {
    InputGenericThumbGallery,
    GenericDatetimeDisplay
  },

  computed: {
    isSequencedActivity: function() {
      let isChild = this.activityInfo.sequence_position !== null;
      let inSequence = this.activityInfo.total_sequence_length !== null;
      return isChild && inSequence;
    },
    sequenceLabel: function() {
      let position = this.activityInfo.sequence_position;
      let total = this.activityInfo.total_sequence_length;
      return this.isSequencedActivity
        ? "Part: " + position + " of " + total
        : null;
    },
    activityType: function() {
      return this._.toLower(this.activityInfo.activity_type.name);
    },
    relativeSocialSetting: function() {
      let setting = {};

      switch (this.activityInfo.system_social_setting_id) {
        case 1:
          setting.class = "fas fa-lock-open";
          setting.text = `${this.$t("ACTIVITY.SCOPE.PUBLIC")}`;
          setting.tooltip =
            "Responses to this activity can be viewed by all participants";
          break;
        case 2:
          setting.class = "fas fa-lock-open";
          setting.text = `${this.$t("ACTIVITY.SCOPE.SEGMENT")}`;
          setting.tooltip =
            "Responses to this activity can only be viewed by users in the same segment";
          break;
        case 3:
          setting.class = "fas fa-lock";
          setting.text = `${this.$t("ACTIVITY.SCOPE.PRIVATE")}`;
          setting.tooltip =
            "Responses to this activity cannot be viewed by other participants";
          break;
      }

      return setting;
    },
    relativeDate: function() {
      let date = {};

      switch (this.activityInfo.system_activity_stage_id) {
        case 1:
          date.prefix = `${this.$t("ACTIVITY.STATUS.DRAFT")}`;
          date.date = null;
          break;
        case 2:
          date.prefix = `${this.$t("ACTIVITY.STATUS.OPENS")}:`;
          date.date = this.activityInfo.starts_on;
          break;
        case 3:
          date.prefix = `${this.$t("ACTIVITY.STATUS.CLOSES")}:`;
          date.date = this.activityInfo.ends_on;
          break;
        case 4:
          date.prefix = `${this.$t("ACTIVITY.STATUS.CLOSED")}:`;
          date.date = this.activityInfo.ends_on;
          break;
      }

      return date;
    }
  },

  methods: {
    activityIcon(activity) {
      return SystemIconHelper.activityType(
        activity.activity_type,
        activity.recording_override
      );
    },
    iconLabel(activity) {
      return SystemLabelHelper.activityType(
        activity.activity_type,
        activity.recording_override
      );
    },
    activityHeader(activityInfo) {
      return GeneralFunctionsHelper.activityHeader(
        this.activityIcon(activityInfo),
        activityInfo,
        this.$t(this.iconLabel(activityInfo))
      );
    }
  }
};
</script>
