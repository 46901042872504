<template>
  <div
    class="mark-up-pins"
    :style="pinPositionStyle"
    :id="`pin-${index}-container`"
  >
    <span
      :class="`svg-icon svg-icon-4x svg-icon-${pinsColor}`"
      :id="`pin-${index}-span`"
      :ref="`pin-${index}-button`"
    >
      <inline-svg src="media/svg/icons/Map/Marker2.svg" />
    </span>

    <b-popover
      :target="`pin-${index}-span`"
      triggers="click"
      :show.sync="popoverShow"
      placement="auto"
      @shown="onShown"
      :container="`pin-${index}-container`"
      :ref="`pin-${index}-popover`"
      variant="warning"
    >
      <template #title>
        <b-button @click="onClose" class="close" aria-label="Close">
          <span class="d-inline-block" aria-hidden="true">&times;</span>
        </b-button>
        {{ $t("ACTIVITY.MARKUP.POPOVER_TITLE") }}
      </template>

      <div>
        <span class="font-size-xs text-secondary" v-if="requiredFillPopover">{{
          $t("ACTIVITY.MARKUP.POPOVER_INSTRUCTION")
        }}</span>
        <!--begin::Text Response Question-->
        <InputGenericInputGroup
          v-if="requiredTextResponse || textResponseLabel"
          :id="`pin-${index}-popover-text-response`"
          :ref="`pin-${index}-popover-text-response`"
          :label="_.capitalize(textResponseLabel)"
          :model="validationModelTextResponse()"
          :submissionStates="submissionStates"
          @nextInputFocus="nextInputFocus($event)"
          :error="$t('FORMS.GENERIC_ERRORS.REQUIRED')"
          class="my-5"
        >
        </InputGenericInputGroup>
        <!--end::Text Response Question-->

        <!--begin::Sentiment Question-->
        <MarkUpSentimentCheckBox
          v-if="requiredSentiment || sentimentLabel"
          :id="`pin-${index}-popover-sentiment`"
          :label="_.capitalize(sentimentLabel)"
          :model="validationModelSentiment()"
          :submissionStates="submissionStates"
          :error="$t('FORMS.GENERIC_ERRORS.REQUIRED')"
        >
        </MarkUpSentimentCheckBox>
        <!--end::Sentiment Question-->

        <!--begin::Sentiment Text Response Question-->
        <InputGenericInputGroup
          v-if="requiredSentimentTextResponse || sentimentTextResponseLabel"
          class="mb-5"
          :id="`pin-${index}-popover-sentiment-text-response`"
          :ref="`pin-${index}-popover-sentiment-text-response`"
          :label="_.capitalize(sentimentTextResponseLabel)"
          :model="validationModelSentimentTextResponse()"
          :submissionStates="submissionStates"
          @nextInputFocus="nextInputFocus($event)"
          :error="$t('FORMS.GENERIC_ERRORS.REQUIRED')"
        >
        </InputGenericInputGroup>
        <!--end::Sentiment Text Response Question-->

        <div class="d-flex justify-content-between w-100">
          <b-button @click="onCancel" size="sm" variant="danger">
            {{ $t("ACTIVITY.MARKUP.POPOVER_REMOVE") }}</b-button
          >
          <b-button @click="onOk" size="sm" variant="primary">
            {{ $t("ACTIVITY.MARKUP.POPOVER_CONFIRM") }}</b-button
          >
        </div>
      </div>
    </b-popover>
  </div>
</template>
<script>
import InputGenericInputGroup from "@/view/components/form-inputs/GenericInputGroup.vue";
import MarkUpSentimentCheckBox from "@/view/components/activity/mark_up/MarkUpSentimentCheckBox.vue";
export default {
  name: "MarkUpImagePins",

  props: {
    pinsColor: { type: String, required: true },
    maxY: { type: Number, required: true },
    maxX: { type: Number, required: true },
    index: { type: Number, required: true },
    requiredTextResponse: { type: Boolean, required: true },
    requiredSentiment: { type: Boolean, required: true },
    requiredSentimentTextResponse: { type: Boolean, required: true },
    requiredFillPopover: { type: Boolean, required: true },
    textResponseLabel: { type: String, required: false },
    sentimentLabel: { type: String, required: false },
    sentimentTextResponseLabel: { type: String, required: false }
  },

  data() {
    return {
      pinPositionStyle: `left: 0px; top: 0px; display:none;`,

      submissionStates: {
        submitting: false
      },

      popoverShow: false
    };
  },

  components: {
    InputGenericInputGroup,
    MarkUpSentimentCheckBox
  },

  methods: {
    resetPinsPostions() {
      return (this.pinPositionStyle = `left: 0px; top: 0px; display:none;`);
    },
    validationModelTextResponse() {
      let number = this.index - 1;
      return this.$parent.$v.form.$each.$iter[0].pins.$each.$iter[number]
        .text_response;
    },

    validationModelSentiment() {
      let number = this.index - 1;
      return this.$parent.$v.form.$each.$iter[0].pins.$each.$iter[number]
        .sentiment;
    },

    validationModelSentimentTextResponse() {
      let number = this.index - 1;
      return this.$parent.$v.form.$each.$iter[0].pins.$each.$iter[number]
        .sentiment_text_response;
    },
    newPinsPostions(X, Y) {
      let width = X - 26;
      let height = Y - 44;
      this.pinPositionStyle = `left: ${width}px; top: ${height}px;`;
      this.popoverShow = true;
      return;
    },
    onCancel() {
      // call reset pin here as well
      let arr = {
        pinIndex: `${this.index}`,
        pinRef: `pin-${this.index}`,
        caller: "removePins"
      };
      this.$emit("removePins", arr);
      this.onClose();
    },
    onClose() {
      this.popoverShow = false;
    },
    onShown() {
      if (this.requiredTextResponse || this.textResponseLabel) {
        this.$refs[
          `pin-${this.index}-popover-text-response`
        ].$children[0].$children[0].focus();
      }
    },
    nextInputFocus($event) {
      let origin = $event;
      if (this.requiredTextResponse || this.textResponseLabel) {
        let id = `pin-${this.index}-popover-text-response`;
        if (origin !== id) {
          this.$refs[
            `pin-${this.index}-popover-text-response`
          ].$children[0].$children[0].focus();
        } else if (
          this.requiredSentimentTextResponse ||
          this.sentimentTextResponseLabel
        ) {
          let id = `pin-${this.index}-popover-sentiment-text-response`;
          if (origin !== id) {
            this.$refs[
              `pin-${this.index}-popover-sentiment-text-response`
            ].$children[0].$children[0].focus();
          }
        }
      }
    },
    onOk() {
      let errorInThisPin = false;
      if (this.requiredTextResponse) {
        let model = this.validationModelTextResponse();
        model.$touch();
        if (model.$anyError) {
          errorInThisPin = true;
        }
      }

      if (this.requiredSentiment) {
        let model = this.validationModelSentiment();
        model.$touch();
        if (model.$anyError) {
          errorInThisPin = true;
        }
      }
      if (this.requiredSentimentTextResponse) {
        let model = this.validationModelSentimentTextResponse();
        model.$touch();
        if (model.$anyError) {
          errorInThisPin = true;
        }
      }
      if (errorInThisPin) {
        this.$toasted.global.error_toast({
          message: this.$t("ACTIVITY.MARKUP.FORM_ERRORS")
        });
        return;
      } else {
        this.onClose();
        return;
      }
    }
  },

  computed: {}
};
</script>
