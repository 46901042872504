<template>
  <div class="mx-0">
    <div
      v-if="type == 'input'"
      class="w-100 text-center card-title markup-area-desc"
    >
      {{ $t("ACTIVITY.MARKUP_NEW.PIN_LIST_DESC") }}
      <a
        v-if="confirmedInstructions === true"
        href="javascript:void(0)"
        class="text-muted pl-1"
        @click="confirmedInstructions = false"
      >
        <i class="fas fa-info-circle" />
        Instructions
      </a>
    </div>

    <div class="d-flex flex-wrap justify-content-center">
      <img
        :ref="`image-object-${id}`"
        class="w-100"
        style="height:0px"
        :src="imageUrl"
        @load="onImageLoaded()"
      >

      <div
        class="mark-up-image rounded"
        ref="markUpImage"
        draggable
        @dragover.prevent
        @dragenter.prevent
        @drop="mouseDrop($event)"
        :style="{
          background: `url(${imageUrl})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          position: 'relative',
          height: `${imageInputHeight}px`,
          width: `${imageInputWidth}px`
        }"
        v-on:click.left.self="puttingDraftPins($event)"
      >
        <div
          v-if="type === 'input' && !confirmedInstructions"
          class="h-100 w-100"
        >
          <div
            class="w-100 h-100 grey-canvas"
            style="z-index: 2"
          />

          <PinCompletionCard
            :type="type"
            :confirmedInstructions="confirmedInstructions"
            @on-confirm-instruction="confirmedInstructions = true"
          />          
        </div>

        <template
          v-for="(pin, p) in model"
        >
          <div
            @dragstart="drag($event, pin)"
            :key="type == 'input' ? pin.index : p"
          >
            <PinDraft
              v-if="pin"
              :pin="pin"
              :type="type"
              :ref="`pin-icon-${pin.id}`"
              :filterValue="filterValue"
              :availablePinByType="pinCountByType"
              @mutatePin="mutatePin"
              @clickPin="clickPin"
            />
          </div>
        </template>
      </div>
    </div>

    <PinCompletionSummary v-if="type == 'input'" :model="model" />

    <PinCompletionTally v-if="type == 'review' && showTally" :model="model" />

    <div
      v-if="type == 'input' || (type == 'review' && showResponses)"
      class="mt-5"
      :class="{ 'filter-applied': filterValue.length > 0 }"
    >
      <div
        v-if="type === 'review'"
        class="d-flex justify-content-center mb-5"
      >
        <a
          href="javascript:void(0)"
          class="btn btn-sm"
          @click="showResponses = false"
        >
          Hide Responses
        </a>
      </div>

      <template
        v-for="(pin, index) in model"
      >
        <div
          :key="pin.id"
          v-if="
            pin.engagement_question_pin.system_markup_pin_type_id !=
              undefined &&
            pin.engagement_question_pin.system_markup_pin_type_id != null &&
            (filterValue.length == 0 ||
              filterValue.includes(pin.engagement_question_pin_id))
          "
        >
          <PinDetail
            :pin="pin"
            :ref="`pin-detail-${pin.id}`"
            :markUpImage="imageUrl"
            :imageX="image_x"
            :imageY="image_y"
            :type="type"
            @on-pin-focus="clickPin(pin, 'pin-icon')"
            @base-64-preview-generated="model[index].preview = $event"
          />
          <hr class="my-2" />
        </div>
      </template>
    </div>

    <div
      v-else-if="type === 'review'"
      class="d-flex justify-content-center mt-5"
    >
      <a
        href="javascript:void(0)"
        class="btn btn-sm"
        @click="showResponses = true"
      >
        Show Responses
      </a>
    </div>
  </div>
</template>

<script>
import Engage from "@/core/services/EngageService.js";
import PinDraft from "../markup-components/PinDraft.vue";
import PinCompletionSummary from "../markup-components/PinCompletionSummary.vue";
import PinCompletionTally from "../markup-components/PinCompletionTally.vue";
import PinDetail from "../markup-components/PinDetail.vue";
import PinCompletionCard from "../markup-components/PinCompletionCard.vue";

export default {
  name: "PinnedImageSummary",

  props: {
    id:           { type: String, required: true },
    imageUrl:     { type: String, required: true },
    engage:       { type: Engage, required: true },
    type:         { type: String, required: true },
    activity:     { type: Object, required: true },
    pinFilter:    { type: Array, required: false, default: function() { return [] } },
    showTally:    { type: Boolean, required: false, default: false },
    reactHandler: { type: Number, required: false, default: 1 }
  },

  data() {
    return {
      model: [],
      showResponses: false,
      imageInputWidth: null,
      imageInputHeight: null,
      responsesLoaded: false,
      isDragging: false,
      confirmedInstructions: false
    };
  },

  components: {
    PinDraft,
    PinCompletionSummary,
    PinCompletionTally,
    PinDetail,
    PinCompletionCard
  },

  methods: {
    drag(ev, pin) {
      ev.dataTransfer.setData("pin", pin.index);
      ev.dataTransfer.setData("pin-orgX", ev.clientX);
      ev.dataTransfer.setData("pin-orgY", ev.clientY);
    },
    mouseDrop: function (ev) {
      ev.preventDefault();
      let p2Mutate = ev.dataTransfer.getData("pin");
      const offsetX = ev.clientX - ev.dataTransfer.getData("pin-orgX");
      const offsetY = ev.clientY - ev.dataTransfer.getData("pin-orgY");
      this.model.filter((pModel) => {
        if (pModel.index == p2Mutate) {
          pModel.pin_x = pModel.pin_x + offsetX;
          pModel.pin_y = pModel.pin_y + offsetY;
        }
      });
    },
    onImageLoaded: function() {
      let imageObject = this.$refs[`image-object-${this.id}`];

      let naturalWidth = imageObject.naturalWidth;
      let naturalHeight = imageObject.naturalHeight;

      let ratio = imageObject.clientWidth / naturalWidth;

      this.imageInputWidth = this.$refs[`image-object-${this.id}`].clientWidth;
      this.imageInputHeight = naturalHeight * ratio;
    },

    calculateInitialPins: function() {
      if (this.responsesLoaded === true) {
        return;
      }

      const self = this;
      this.formValue.forEach(function (pin) {
        pin.pin_x = self.imageInputWidth * (pin.pin_x / 100);
        pin.pin_y = self.imageInputHeight * (pin.pin_y / 100);
        self.model.push(pin);
      });

      this.responsesLoaded = true;
    },

    puttingDraftPins($event) {
      this.model = this.model.filter(function(pin) {
        return pin.engagement_question_pin_id != undefined;
      })

      if (this.type == "input" && this.maximumPinsReached === false) {
        let pin = {
          id: new Date().getTime(),
          index: $event.timeStamp,
          engagement_question_pin: {
            label: null,
            system_markup_pin_type_id: null,
            response: null,
          },
          pin_x: $event.offsetX,
          pin_y: $event.offsetY,
          image_y: this.image_y,
          image_x: this.image_x,
        };
        this.model.push(pin);
      }
    },

    recalibrateCounts(p2Mutated) {
      this.pinCountByType.filter((type) => {
        if (type.pinsPut.includes(p2Mutated.index)) {
          let idx = type.pinsPut.indexOf(p2Mutated.index);
          type.pinsPut.splice(idx, 1);
        } else if (
          type.system_markup_pin_type_id ==
          p2Mutated.engagement_question_pin.system_markup_pin_type_id
        ) {
          if (!type.pinsPut.includes(p2Mutated.index)) {
            type.pinsPut.push(newPins.index);
          }
        }
      });
    },

    mutatePin(pin, pins, action, variable2update = null) {
      if (this.type == "input") {
        let p2Mutate = { ...pin };
        let pinsByType = { ...pins };
        this.model.filter((pModel) => {
          if (pModel.index == p2Mutate.index) {
            if (action == "select") {
              pModel.engagement_question_pin = pinsByType;
              pModel.engagement_question_pin_id = pinsByType.id;
            } else if (action == "remove") {
              this.model.splice(this.model.indexOf(pModel), 1);
            } else if (action == "update") {
              pModel.text_response = variable2update;
            }
          }
        });
        this.recalibrateCounts(p2Mutate);
      }
    },
    clickPin(pin, refName) {
      this.showResponses = true;
      this.scrollToElement(pin.id, refName);
    },
    scrollToElement(pinId, refName) {
      this.$nextTick(() => {
        const pinElement = this.$refs[`${refName}-${pinId}`][0].$el;
        if (pinElement) {
          pinElement.classList.add("focused-pin");
          setTimeout(function () {
            pinElement.classList.remove("focused-pin");
          }, 1000);
          return pinElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
      });
    },
    getExportElement: function () {
      return this.$refs["markUpImage"];
    },
  },

  watch: {
    imageInputHeight: {
      handler(newValue) {
        if (newValue != null && this.imageInputWidth != null) {
          this.calculateInitialPins();
        }
      }
    },
    imageInputWidth: {
      handler(newValue) {
        if (newValue != null && this.imageInputHeight != null) {
          this.calculateInitialPins();
        }
      }
    },
    model: {
      handler(newModel) {
        if (this.type === "review") {
          return;
        }

        newModel.forEach((newPins) => {
          if (
            newPins.engagement_question_pin.system_markup_pin_type_id !==
            undefined
          ) {
            this.pinCountByType.filter((type) => {
              if (
                type.system_markup_pin_type_id ==
                newPins.engagement_question_pin.system_markup_pin_type_id
              ) {
                if (!type.pinsPut.includes(newPins.index)) {
                  type.pinsPut.push(newPins.index);
                }
              }
            });
          }
        });

        if (newModel != this.formValue) {
          this.engage.setResponseValue(this.id, newModel)
        }
      },
      deep: true,
    }
  },

  computed: {
    formValue: function () {
      return this.engage.response[this.id];
    },
    filterValue: function () {
      this.reactHandler;
      if (this.engage.response.markup_pin_filter === undefined) {
        return []
      } else {
        return this.engage.response.markup_pin_filter.map((a) => a.id)
      }
    },
    image_y: function () {
      return this.$refs.markUpImage.clientHeight;
    },
    image_x: function () {
      return this.$refs.markUpImage.clientWidth;
    },
    pinCountByType: function () {
      if (this.type == "review") {
        return [];
      } else {
        let pins = this.activity.engagement_question_pins;
        pins.forEach((icons) => {
          return (icons.pinsPut = []);
        });
        return pins;
      }
    },
    maximumPinsReached: function() {
      let availablePinsTypes = 0;

      this.pinCountByType.forEach(function(pinCount) {
        if (pinCount.placement_maximum == null || pinCount.pinsPut.length < pinCount.placement_maximum) {
          availablePinsTypes += 1;
        }
      })

      return (availablePinsTypes === 0) ? true : false;
    }
  },
};
</script>

<style lang="scss" scoped>
.mark-up-image {
  object-fit: contain;
  width: 100%;
  background-size: cover !important;
}
.focused-pin {
  box-shadow: 0px 0px 8px 4px #d9ecff91, 0px 0px 8px 4px #5aabffab !important;
}
</style>