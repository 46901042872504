<template>
  <GeneralDisplay v-if="loadingComplete" :activityInfo="activityInfo">
    <div slot="activity-panel">
      <PageOfQuestions
        v-if="!completedSurvey"
        :questionForm="computedForm"
        :questions="computedSurveyQuestions"
        :activityType="activityType"
        @responses_created="responses_created()"
      ></PageOfQuestions>
      <!--end::Survey Questions List-->

      <!--begin::Survey Response Lists-->
      <div
        v-if="
          activitySurveyRepsonsesList.length > 0 &&
          (
            (activityInfo.system_social_bias_id == 2 || (activityInfo.system_social_bias_id == 1 && completedSurvey)) ||
            ['super_admin','observer','community manager','admin'].includes(currentUser.relation_to_project)
          )"
        class="mt-10"
      >
        <div class="d-flex justify-content-center mb-3" v-if="responseLoading">
          <b-spinner class="main-card-loader" label="Loading..."></b-spinner>
        </div>

        <SurveyResponse
          :activityId="_.toNumber(activityId)"
          v-else
          @response-deleted="completedSurvey = false"
        ></SurveyResponse>
      </div>
      <!--end::Survey Response Lists-->
    </div>
  </GeneralDisplay>

  <!--begin::Loading Spinner-->
  <div class="row" v-else>
    <div class="col-xxl-12 min-vh-100">
      <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
        <div class="card-body my-4 pd-12">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner class="main-card-loader" label="Loading..."></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Loading Spinner-->
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { POPULATE_ACTIVITY_INFO } from "@/core/services/store/activity/activity.module";
import { POPULATE_ACTIVITY_SURVEY_RESPONSES_LIST,POPULATE_ACTIVITY_SURVEY_RESPONSES_INFO } from "@/core/services/store/activity/activity_survey_responses.module.js";
import { POPULATE_SURVEY_QUESTION_LIST } from "@/core/services/store/activity/survey_question.module";
import PageOfQuestions from "@/view/pages/activity/PageOfQuestions.vue";
import GeneralDisplay from "@/view/components/activity/GeneralDisplay.vue";
import ActivityFunctionsHelper from "@/helpers/ActivityFunctions";
import SurveyResponse from "@/view/components/activity/SurveyResponse.vue";

export default {
  name: "SurveyActivity",

  data() {
    return {
      showNewMessageBox: false,
      baseResponseId: false,
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 2
      },
      error: {
        active: false,
        message: ""
      },
      showAnsweredRespones: false,
      no_response_role: false,
      completedSurvey: false,
      responseLoading: true
    };
  },

  components: {
    GeneralDisplay,
    PageOfQuestions,
    SurveyResponse
  },

  mounted() {
    this.cleanup();
    this.fetchActivityInfo();
    this.fetchAllSurveyResponses();
  },

  watch: {
    activityId: function() {
      this.pageLoader.componentsCompleted = 0;
      this.pageLoader.componentsRequired = 1;
      this.fetchActivityInfo();
    },
    responseId: function(val) {
      this.responseLoading = true;
      this.fetchSurveyResponseById(this.baseResponseId);
    },
    no_response_role: function(val) {
      if (val == true) {
        this.$root.$bvToast.toast(
          `${this.$t("TOASTS.ACTIVITY.ROLE_NOT_ELIGIBLE_TO_RESPONSE_MSG")}`,
          {
            title: `${this.$t(
              "TOASTS.ACTIVITY.ROLE_NOT_ELIGIBLE_TO_RESPONSE_TITLE"
            )}`,
            variant: "warning",
            noAutoHide: true
          }
        );
      }
    }
  },
  methods: {
    fetchActivityInfo() {
      let payload = {
        projectId: this.projectInfo.id,
        activityId: this.activityId,
        params: {
          paginate: false,
          activityType: "with",
          activityStage: "with",
          activitySettings: "with",
          stimulusAssets: "with",
          appendSignedUrls: "1"
        }
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_INFO, payload)
        .then(res => {
          if (res.responseStatus == "completed") {
            this.completedSurvey = true;
            this.fetchSurveyResponseById(res.responseId);
            this.fetchSurveyQuestions();
          } else {
            if (res.responseStatus !== "eligible") {
              this.no_response_role = true;
            }
            this.pageLoader.componentsRequired--;
            this.fetchSurveyQuestions();
          }
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity information, please check back later or contact the helpdesk";
        });
    },
    fetchSurveyQuestions() {
      let payload = {
        activityId: this.activityId,
        params: {
          answerType: "with",
          surveyQuestionChoices: "with",
          appendSignedUrls: 1,
          stimulusAssets: "with",
          sorting: "ASC",
          paginate: false
        }
      };

      this.$store
        .dispatch(POPULATE_SURVEY_QUESTION_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the survey question list, please check back later or contact the helpdesk";
        });
    },
    fetchSurveyResponseById(responseId) {
      let payload = {
        activityId: this.activityId,
        activitySurveyResponseId: responseId,
        params: {
          surveyQuestionResponses: "with",
          responseId: this.responseId,
          scope: "input",
          activityResponse: "with",
          paginate: false
        }
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_SURVEY_RESPONSES_INFO, payload)
        .then(() => {
          this.baseResponseId = responseId
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the diary response list, please check back later or contact the helpdesk";
        });
    },
    fetchAllSurveyResponses: function() {
      let payload = {
        activityId: this.activityId,
        params: {
          surveyQuestionResponses: "with",
          scope: "input",
          activityResponse: "with",
          paginate: false
        }
      }

      this.$store.dispatch(POPULATE_ACTIVITY_SURVEY_RESPONSES_LIST, payload)
        .then(() => {
          this.responseLoading = false;
        });
    },
    prepareSurveyAnswers(list) {
      return ActivityFunctionsHelper.prepareQuestionsAnswer(
        list,
        "surveyChoiceResponses"
      );
    },
    prepareSurveyQuestionsChoices(list) {
      return ActivityFunctionsHelper.prepareQuestionsChoices(
        list,
        "survey_question_choices"
      );
    },
    wholeSurveyQuestionLists(list) {
      return ActivityFunctionsHelper.wholeSurveyQuestionLists(list);
    },
    responses_created() {
      this.$router.go();
    },
    prepareFormByPage(arr) {
      let form = [];
      arr.forEach(q => {
        if (q.system_answer_type_id == "2") {
          form.push({ answer: [] });
        } else if (q.system_answer_type_id == "8") {
          let slots = {};
          let slotNumber = 1;
          q.arrayQuestion.forEach(slot => {
            if (slot.type == "OpenEnded" || slot.type == "SingleChoice") {
              slots[slotNumber] = null;
              slotNumber++;
            }
          })
          form.push({ answer: slots});
        } else {
          form.push({ answer: "" });
        }
      });
      return form;
    },
    groupByPage(list, modeString) {
      let p = [];
      let onePageOnly = this._.isArray(list);
      let questionMode = modeString == "questions";

      if (onePageOnly) {
        if (!questionMode) {
          list = this.prepareFormByPage(list);
        }
        p.push({ page: list });
      } else {
        this._.forOwn(list, value => {
          if (!questionMode) {
            value = this.prepareFormByPage(value);
          }
          p.push({ page: value });
        });
      }

      return p;
    },
    cleanup: function() {
      this.setActivityInfo([]);
      this.resetActivitySurveyRepsonsesList([]);
      this.resetActivityReplyEngagementsList([]);
    },
    ...mapMutations({
      setActivityInfo: "setActivityInfo",
      resetActivitySurveyRepsonsesList: "resetActivitySurveyRepsonsesList",
      resetActivityReplyEngagementsList: "resetActivityReplyEngagementsList"
    })
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "projectInfo",
      "activityInfo",
      "surveyQuestionList",
      "activitySurveyRepsonsesInfo",
      "activitySurveyRepsonsesList"
    ]),
    activityId: function() {
      return this.$route.params.id;
    },    
    responseId: function() {
      return this.$route.params.responseId;
    },
    activityType: function() {
      return this._.toLower(this.activityInfo.activity_type.name);
    },
    totalQuestion: function() {
      return this.surveyQuestionList.length;
    },
    randomiseQuestions: function() {
      let setting = this._.find(this.activityInfo.activity_settings, { id: 3 });
      return setting ? Boolean(parseInt(setting.pivot.value)) : false;
    },
    randomisePages: function() {
      let setting = this._.find(this.activityInfo.activity_settings, { id: 4 });
      return setting ? Boolean(parseInt(setting.pivot.value)) : false;
    },
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    },
    wholeSurveyQuestionList: function() {
      return this.wholeSurveyQuestionLists(this.surveyQuestionList);
    },
    userResponse: function() {
      let response = this._.filter(
        this.activitySurveyRepsonsesList,
        responses => {
          let user = responses.activity_response;
          return user.project_profile_id == this.currentUser.projectProfile.id;
        }
      );
      return response ? response[0].survey_question_responses : false;
    },
    computedList: function() {
      let list = this.prepareSurveyQuestionsChoices(this.surveyQuestionList);
      let pageArray = this._.map(list, "page");
      let pageTotal = this._.max(pageArray);
      if (pageTotal == 1) {
        return this.randomiseQuestions ? this._.shuffle(list) : list;
      } else {
        list = this._.groupBy(list, "page");
        if (this.randomiseQuestions) {
          this._.forOwn(list, (value, key) => {
            list[key] = this._.shuffle(value);
          });
        }
        list = this.randomisePages ? this._.shuffle(list) : list;
        return Object.assign({}, list);
      }
    },
    computedForm: function() {
      return this.groupByPage(this.computedList, "form");
    },
    computedSurveyQuestions: function() {
      return this.groupByPage(this.computedList, "questions");
    }
  }
};
</script>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.when-closed,
.when-opened {
  float: right;
}
</style>
