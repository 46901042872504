var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row d-flex flex-nowrap justify-content-start mx-0 p-1"},[_c('div',{ref:"pin-preview",staticClass:"mark-up-image p-0",style:({
      position: 'relative',
      background: `url(${_vm.markUpImage})`,
      overflow: 'hidden',
      width: '100px',
      height: '100px',
      backgroundSize: `${_vm.imageX}px ${_vm.imageY}px`,
      backgroundPosition: `${_vm.imageX - (_vm.pin.pin_x - 40)}px ${_vm.imageY - (_vm.pin.pin_y - 60)}px`,
    })},[_c('span',{staticClass:"pin",staticStyle:{"top":"25px","left":"40px"}},[_c('PinIcon',{staticStyle:{"position":"relative","top":"-10px","left":"-10px"},attrs:{"type":_vm.pin.engagement_question_pin.system_markup_pin_type_id,"width":35,"height":35,"hoverable":true},on:{"click":function($event){return _vm.$emit('on-pin-focus')}}})],1)]),_c('div',{staticClass:"w-auto py-3 mx-5"},[_c('span',{staticClass:"fw-semibold d-block"},[_vm._v(_vm._s(_vm.pin.engagement_question_pin.label))]),_c('span',{staticClass:"text-muted d-block pt-1"},[_vm._v(_vm._s(_vm.pin.engagement_question_pin.prepopulated_response))]),(_vm.pinTextResponse != null)?_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.pinTextResponse)+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }