<template>
  <div class="row d-flex flex-nowrap justify-content-start mx-0 p-1">
    <div
      ref="pin-preview"
      class="mark-up-image p-0"
      :style="{
        position: 'relative',
        background: `url(${markUpImage})`,
        overflow: 'hidden',
        width: '100px',
        height: '100px',
        backgroundSize: `${imageX}px ${imageY}px`,
        backgroundPosition: `${imageX - (pin.pin_x - 40)}px ${imageY - (pin.pin_y - 60)}px`,
      }"
    >
      <span
        class="pin"
        style="top:25px; left:40px"
      >
        <PinIcon
          style="position:relative; top:-10px; left:-10px"
          :type="pin.engagement_question_pin.system_markup_pin_type_id"
          :width="35"
          :height="35"
          :hoverable="true"
          @click="$emit('on-pin-focus')"
        />
      </span>
    </div>
    <div class="w-auto py-3 mx-5">
      <span class="fw-semibold d-block">{{ pin.engagement_question_pin.label }}</span>
      <span class="text-muted d-block pt-1">{{ pin.engagement_question_pin.prepopulated_response }}</span>
      <span
        v-if="pinTextResponse != null"
        class="d-block"
      >
        {{ pinTextResponse }}
      </span>
    </div>

  </div>
</template>

<script>
import CanvasExport from "@/core/services/CanvasExportService";

import PinIcon from "./PinIcon.vue";

export default {
  emits: ['on-pin-focus'],

  name: "PinDetail",

  props: {
    pin:          { type: Object, required: true },
    markUpImage:  { type: String, required: true },
    imageX:       { type: Number, required: true },
    imageY:       { type: Number, required: true },
    type:         { type: String, required: true }
  },

  data() {
    return {
      canvasExport: new CanvasExport(),
      tmp: 1,
      generatingPreview: false
    }
  },

  components: {
    PinIcon
  },

  mounted() {
    this.calculatePreview();
  },

  methods: {
    calculatePreview: async function() {
      if (this.type == 'input' && this.generatingPreview === false) {
        this.generatingPreview = true;
        this.canvasExport.generateJPEG(this.$refs['pin-preview']).then((res) => {
          this.$emit('base-64-preview-generated',res);
        }).finally(() => {
          this.generatingPreview = false;
        })
      }
    }
  },

  watch: {
    pin: {
      handler() {
        this.tmp ++;
      },
      immediate: true,
      deep: true
    },
    pinX: {
      handler() {
        setTimeout(() => {
          this.calculatePreview();
        }, 600);
      }
    },
    pinY: {
      handler() {
        setTimeout(() => {
          this.calculatePreview();
        }, 600);
      }
    },
    pinQuestion: {
      handler() {
        setTimeout(() => {
          this.calculatePreview();
        }, 600)
      }
    }
  },

  computed: {
    pinTextResponse: function() {
      this.tmp;
      return (this.pin.text_response == undefined) ? null : this.pin.text_response;
    },
    pinX: function() {
      return this.pin.pin_x;
    },
    pinY: function() {
      return this.pin.pin_y;
    },
    pinQuestion: function() {
      this.tmp;
      return this.pin.engagement_question_pin_id;
    }
  }
}
</script>