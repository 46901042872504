// import moment from "moment";
import _ from "lodash";
class ActivityFunctionsHelper {
  static prepareChoicesOption(question, type) {
    let options = [];
    question[type].forEach((e, idx) => {
      let arr = {
        id: idx,
        value: e.id,
        text: e.choice
      };

      if (e.choiceStimuli !== undefined) {
        if (e.choiceStimuli.length > 0) {
          arr.choiceStimuli = e.choiceStimuli.map(el => el.projectAsset);
        }
      }
      options.push(arr);
    });
    return options;
  }

  static prepareQuestionsChoices(list, type) {
    let questions = [];
    list.forEach(el => {
      if (_.includes([2, 3, 5], el.system_answer_type_id)) {
        el.options = this.prepareChoicesOption(el, type);
      }
      if (
        el.attachments_allowed == 1 ||
        _.includes([4, 6, 9], el.system_answer_type_id)
      ) {
        el.attachments = [];
        el.previews = [];
      }
      questions.push(el);
    });
    return questions;
  }

  static wholeSurveyQuestionLists(list) {
    let questions = this.prepareQuestionsChoices(
      list,
      "survey_question_choices"
    );
    let p = [{ page: questions }];
    return p;
  }

  static prepareQuestionsAnswer(list, type) {
    let answers = [];
    let answeredForm = [];
    if (list.length > 0) {
      list.forEach(response => {
        let answer = [];
        if (response.response != null) {
          answer = response.response;
        } else if (response[type].length > 0) {
          response[type].forEach(choices => {
            if (type == "surveyChoiceResponses") {
              answer.push(choices.survey_question_choice_id);
            } else if (type == "diaryChoiceResponses") {
              answer.push(choices.diary_question_choice_id);
            }
          });
        }

        if (response.attachments.length > 0) {
          var pulled = _.map(response.attachments, "projectAsset");
          answers.push({ answer: answer, attachments: pulled });
        } else {
          answers.push({ answer: answer });
        }
      });
    }
    answeredForm.push({ page: answers });

    return answeredForm;
  }
}

export default ActivityFunctionsHelper;
