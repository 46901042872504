<template>
  <img
    class="pinIcon p-1"
    :class="{ hoverable: hoverable }"
    :src="pinImageFiles[type]"
    :height="height"
    :width="width"
    style="max-width: none !important"
    @click="$emit('pin-selected')"
  />
</template>

<script>
import pin1 from "@/assets/pins/1.png";
import pin2 from "@/assets/pins/2.png";
import pin3 from "@/assets/pins/3.png";
import pin4 from "@/assets/pins/4.png";
import pin5 from "@/assets/pins/5.png";
import pin6 from "@/assets/pins/6.png";
import pin7 from "@/assets/pins/7.png";
import pin8 from "@/assets/pins/8.png";
import pin9 from "@/assets/pins/9.png";
import pin10 from "@/assets/pins/10.png";
import pin11 from "@/assets/pins/11.png";
import pin12 from "@/assets/pins/12.png";
import pin13 from "@/assets/pins/13.png";
import pin14 from "@/assets/pins/14.png";
import pin15 from "@/assets/pins/15.png";
import pin16 from "@/assets/pins/16.png";
import pin17 from "@/assets/pins/17.png";
import pin18 from "@/assets/pins/18.png";
import pin19 from "@/assets/pins/19.png";
import pin20 from "@/assets/pins/20.png";
import pin21 from "@/assets/pins/21.png";
import pin22 from "@/assets/pins/22.png";
import pin23 from "@/assets/pins/23.png";
import pin24 from "@/assets/pins/24.png";
import pin25 from "@/assets/pins/25.png";
import pin26 from "@/assets/pins/26.png";
import pin27 from "@/assets/pins/27.png";
import pin28 from "@/assets/pins/28.png";
import pin29 from "@/assets/pins/29.png";
import pin30 from "@/assets/pins/30.png";
import pin31 from "@/assets/pins/31.png";
import pin32 from "@/assets/pins/32.png";
import pin33 from "@/assets/pins/33.png";
import pin34 from "@/assets/pins/34.png";
import pin35 from "@/assets/pins/35.png";
import pin36 from "@/assets/pins/36.png";
import pin37 from "@/assets/pins/37.png";

export default {
  name: "MarkupPinIcon",

  props: {
    type: { type: Number, required: true },
    height: { type: Number, required: false, default: 35 },
    width: { type: Number, required: false, default: 35 },
    hoverable: { type: Boolean, required: false, default: true },
  },

  data() {
    return {
      pinImageFiles: {
        1: pin1,
        2: pin2,
        3: pin3,
        4: pin4,
        5: pin5,
        6: pin6,
        7: pin7,
        8: pin8,
        9: pin9,
        10: pin10,
        11: pin11,
        12: pin12,
        13: pin13,
        14: pin14,
        15: pin15,
        16: pin16,
        17: pin17,
        18: pin18,
        19: pin19,
        20: pin20,
        21: pin21,
        22: pin22,
        23: pin23,
        24: pin24,
        25: pin25,
        26: pin26,
        27: pin27,
        28: pin28,
        29: pin29,
        30: pin30,
        31: pin31,
        32: pin32,
        33: pin33,
        34: pin34,
        35: pin35,
        36: pin36,
        37: pin37
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.pinIcon {
  border-radius: 35px;
  cursor: default;
}
.pinIcon.hoverable {
  cursor: pointer !important;
}
.pinIcon.hoverable:hover {
  filter: grayscale(100%);
}
</style>