<template>
  <div
    v-if="type === 'input' && !confirmedInstructions"
    class="card"
    style="position: absolute; top: 10%; width: 70%; left: 15%; z-index: 3"
  >
    <div class="card-body">
      <h6 class="mb-10">
        Instructions
      </h6>

      <p class="mb-2">
        Click on the image to place a pin.
      </p>

      <p class="mb-10">
        You can place:
      </p>

      <div class="pin_instruction_container mb-15">
        <template
          v-for="(type, t) in activityInfo.engagement_question_pins"
        >
          <div
            :key="t"
            class="d-flex justify-content-start align-items-center py-2 mx-5"
          >
            <PinIcon
              :type="type.system_markup_pin_type_id"
              :height="40"
              :width="40"
              :hoverable="false"
            />

            <span class="ml-3">
              <span>{{ instructionText(type) }}</span>
              <span class="font-weight-bold pl-2">{{ type.label }}</span>
            </span>
          </div>
        </template>
      </div>

      <div class="d-flex justify-content-end">
        <a
          @click="$emit('on-confirm-instruction')"
          class="btn btn-sm btn-primary"
        >
          Confirm
        </a>
      </div>

    </div>
  </div>
</template>

<script>
import {
  mapGetters
} from "vuex";

import PinIcon from "./PinIcon.vue";

export default {
  name: "PinCompletionCard",

  props: {
    type:                   { type: String, required: true },
    confirmedInstructions:  { type: Boolean, required: true }
  },

  components: {
    PinIcon
  },

  methods: {
    instructionText: function(pin) {
      if (pin.placement_minimum === null && pin.placement_maximum === null) {
        return "Unlimited number of";
      } else if (pin.placement_maximum === null && pin.placement_minimum != null) {
        return `At least ${pin.placement_minimum} of`;
      } else if (pin.placement_maximum != null && pin.placement_minimum === null) {
        return `A maximum of ${pin.placement_maximum}`;
      } else if (pin.placement_maximum === pin.placement_minimum) {
        return `${pin.placement_maximum} of`;
      } else {
        return `Between ${pin.placement_minimum} and ${pin.placement_maximum}`
      }
    }
  },

  computed: {
    ...mapGetters([
      "activityInfo"
    ]),
  }
}
</script>