<template>
  <div>
    <b-form-group
      :id="`${id}-group`"
      :label="label"
      :label-for="`${id}-group`"
      class="w-100 pt-5"
    >
    </b-form-group>

    <draggable
      :list="rankList"
      :class="
        'list-group' + (checkbox.value === 'true' ? ' cursorDisabled' : '')
      "
      tag="ul"
      @end="onEnd(rankList)"
      :disabled="disabled || checkbox.value === 'true'"
      handle=".handle"
      v-bind="dragOptions"
    >
      <li
        class="
          mb-3
          py-0
          shadow
          rounded-lg
          cursor-move
          border
          list-group-item
          handle
        "
        :class="model.$error ? 'draggable-invalid border-red' : 'border-white'"
        v-for="(option, idx) in list"
        :key="idx"
      >
        <i class="fa fa-align-justify"></i>
        <b-button
          v-if="option.choiceStimuli"
          :id="`image-${option.value}`"
          class="no-decoration d-flex justify-content-center flex-wrap w-100"
        >
          <InputGenericThumbGallery
            :projectAssets="option.choiceStimuli"
            classStyle="sm"
            class="mb-0 w-100"
          >
          </InputGenericThumbGallery>
          <h3 class="w-100">
            {{ option.text }}
          </h3>
        </b-button>
        <h3 class="d-flex justify-content-center w-90 py-10" v-else>
          {{ option.text }}
        </h3>
        <b-tooltip
          :target="`image-${option.value}`"
          :title="option.text"
          placement="right"
        ></b-tooltip>
      </li>
    </draggable>

    <b-form-checkbox
      v-show="!rankingChanged"
      :id="id"
      v-model="checkbox.value"
      value="true"
      unchecked-value="false"
      class="w-100 pt-5"
      @change="originalOrder()"
      :disabled="submissionStates.submitting === true"
    >
      {{ checkbox.text }}
    </b-form-checkbox>

    <div class="custom-validation-failure mb-7" v-if="model.$error">
      {{ error }}
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import InputGenericThumbGallery from "@/view/components/form-inputs/GenericThumbGallery.vue";

export default {
  name: "SurveyRankingQuestion",

  props: {
    id: { type: String, required: true },
    label: { type: String, required: false },
    model: { type: Object, required: true },
    submissionStates: { type: Object, required: true },
    disabled: { type: Boolean, required: false },
    list: { type: Array, required: true },
    error: { type: String, required: true }
  },

  components: {
    draggable,
    InputGenericThumbGallery
  },

  data() {
    return {
      rankingChanged: false,
      rankList: [],
      checkbox: {
        text: "I am happy with the current orders",
        value: false
      }
    };
  },

  mounted() {
    this.rankList = this.list;
    if (this.disabled) {
      this.rankingChanged = true;
      // sort ranking choices by response result
      if (this.model.$model) {
        this.rankList.sort((a, b) => {
          var A = a.value,
            B = b.value;
          if (this.model.$model.indexOf(A) > this.model.$model.indexOf(B)) {
            return 1;
          } else {
            return -1;
          }
        });
      }
    }
  },

  methods: {
    onEnd(changedList) {
      this.rankingChanged = true;
      this.$emit("ranking_changed", changedList);
    },

    originalOrder() {
      this.$emit("checkedOriOrder", this.checkbox.value === "true");
    }
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "lists",
        disabled: false,
        ghostClass: "moving-card"
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.list-group.cursorDisabled {
  cursor: not-allowed !important;
  .cursor-move {
    cursor: not-allowed !important;
    .btn {
      cursor: not-allowed !important;
    }
  }
}
</style>
