<template>
  <a
    @click="$emit('show-modal')"
    href="javascript:void(0)"
    class="btn btn-icon-success btn-xs btn-text-dark-50 bg-hover-light-success btn-hover-text-success rounded font-weight-bolder font-size-xs p-2"
  >
    <span class="svg-icon svg-icon-md svg-icon-danger pr-1">
      <i class="far fa-comment-alt text-success"></i>
    </span
    >{{ post.replies.length }}
  </a>
</template>

<script>
export default {
  name: "PictureBookComment",

  props: {
    post: { type: Object, required: true }
  }
};
</script>
