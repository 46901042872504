<template>
  <a
    href="javascript:void(0)"
    @click="onDestroy"
    class="btn btn-icon-danger btn-sm btn-text-dark-50 bg-hover-light-danger btn-hover-text-danger rounded font-weight-bolder font-size-xs p-2"
    v-b-tooltip.hover.bottom
    :title="$t('REVIEW.STREAM.DESTROY')"
  >
    <span class="svg-icon svg-icon-md svg-icon-danger px-0 mx-0">
      <i class="fas fa-trash text-danger fa-lg px-0 mx-0"></i>
    </span>
  </a>
</template>

<script>
import {
  mapActions
} from "vuex";

export default {
  name: "DeleteInteraction",

  props: {
    type:         { type: String, required: true },
    baseResponse: { type: Object, required: true },
    response:     { type: Object, required: true }
  },

  methods: {
    onDestroy: function() {
      this.$swal.fire({
        title: `${this.$t("SWEETALERTS.DELETE_RESPONSE_CHALLENGE.TITLE")}`,
        text: `${this.$t("SWEETALERTS.DELETE_RESPONSE_CHALLENGE.MESSAGE")}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${this.$t("SWEETALERTS.DELETE_RESPONSE_CHALLENGE.CONFIRM")}`,
        cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`,
      }).then((result) => {
        if (result.isConfirmed) {

          let action = null;
          let payload = null;

          if (this.type === 'base') {
            action = this.deleteActivityResponse;
            payload = {
              activity_id: this.baseResponse.activity.id,
              engagement_response_id: this.response.id
            }
          } else {
            action = this.deleteEngagementInteraction;
            payload = {
              engagement_response_id: this.baseResponse.id,
              engagement_interaction_id: this.response.id
            }
          }

          action(payload).then(() => {
            this.$swal.fire({
              title: `${this.$t("SWEETALERTS.DELETE_RESPONSE_SUCCESS.TITLE")}`,
              text: `${this.$t("SWEETALERTS.DELETE_RESPONSE_SUCCESS.MESSAGE")}`,
              icon: 'success',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            });

            this.$emit('on-response-deletion');
          }).catch(() => {
            this.$swal.fire({
              title: `${this.$t("GENERAL.TEXT.VAGUE_ERROR_TITLE")}`,
              text: `${this.$t("SWEETALERTS.DELETE_RESPONSE_FAIL.MESSAGE")}`,
              icon: 'error',
              confirmButtonText: `${this.$t("GENERAL.TEXT.CONFIRM")}`
            })
          })
        }
      });
    },
    ...mapActions({
      deleteActivityResponse: "deleteActivityResponse",
      deleteEngagementInteraction: "deleteEngagementInteraction"
    })
  }
}
</script>