<template>
  <div class="mx-0 mt-5 d-flex flex-wrap justify-content-center align-items-center">
    <span
      class="mr-2 text-gray-600"
      v-html="minPinsToPut"
    ></span>
    <template
      v-for="(type, t) in activityInfo.engagement_question_pins"
    >
      <div
        :key="t"
        class="px-1 d-flex align-items-center"
        :style="{
          opacity: (type.placement_maximum != null && countByPinType(type.system_markup_pin_type_id) === type.placement_maximum) ? .5 : 1
        }"
      >
        <PinIcon
          :type="type.system_markup_pin_type_id"
          :height="30"
          :width="30"
          :hoverable="false"
        />

        <span class="text-gray-800">
          <i
            v-if="type.placement_maximum === null && type.placement_minimum === null"
            class="fas fa-infinity"
            style="font-size:.9rem; padding-top:5px"
          />

          <span v-else>
            <b
              :style="pinCountCSS(type.system_markup_pin_type_id)"
            >
              {{ countByPinType(type.system_markup_pin_type_id) }}
            </b>
            <span>
              {{ type.placement_maximum > 0 ? `/${type.placement_maximum}` : " " }}
            </span>            
          </span>
        </span>
      </div>

    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PinIcon from "./PinIcon.vue";

export default {
  name: "PinCompletionSummary",

  props: {
    model: { type: Array, required: true },
  },

  data() {
    return {
      numberOfPinsPut: 0,
    };
  },

  components: {
    PinIcon
  },

  methods: {
    countByPinType(typeId) {
      let total = 0;
      this.model.filter((pinInModel) => {
        if (
          pinInModel.engagement_question_pin.system_markup_pin_type_id == typeId
        ) {
          total++;
        }
      });
      return total;
    },

    pinCountCSS(typeId) {
      let total = 0;
      this.model.filter((pinInModel) => {
        if (
          pinInModel.engagement_question_pin.system_markup_pin_type_id == typeId
        ) {
          total++;
        }
      });
      let typeMin = this.activityInfo.engagement_question_pins.filter(
        (item) => item.system_markup_pin_type_id == typeId
      )[0];
      if (total < typeMin.placement_minimum) return `color: red;`;
    },
  },

  computed: {
    ...mapGetters(["activityInfo"]),

    minPinsToPut() {
      let total = 0;
      let pinsByIconType = this.activityInfo.engagement_question_pins;
      pinsByIconType.forEach((icons) => {
        let min = icons.placement_minimum;
        this.model.filter((pinInModel) => {
          if (
            pinInModel.engagement_question_pin.system_markup_pin_type_id ==
            icons.system_markup_pin_type_id
          ) {
            return (min = min - 1);
          }
        });

        if (min > 0) {
          return (total += min);
        }
      });

      let sum = total - this.numberOfPinsPut;

      if (sum > 0) {
        return `<b> ${sum} pins</b> to go: `;
      } else {
        return null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.fake-button {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  width: 20px;
  height: 20px;
}
</style>
