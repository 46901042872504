<template>
  <div class="wizard-nav mb-1 mb-lg-5">
    <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
      <div
        v-for="(page, pageIndex) in wizardForm"
        v-bind:key="pageIndex"
        class="wizard-step"
        data-wizard-type="step"
        :data-wizard-state="pageIndex == 0 ? 'current' : ''"
      >
        <div class="wizard-label" v-if="!hideTitle">
          <h3 class="wizard-title">
            <span>{{ $t("ACTIVITY.SURVEY.PAGE") }}</span>
            {{ pageIndex + 1 }}
          </h3>
          <div class="wizard-bar"></div>
        </div>
        <div class="wizard-page-count" v-if="pageIndex + 1 == currentPage">
          <h3 class="wizard-title" v-if="!hideTitle">
            <span>{{ $t("ACTIVITY.SURVEY.PAGE") }}</span>
            {{ currentPage }} / {{ wizardForm.length }}
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WizardNav",

  props: {
    wizardForm: { type: Array, required: true },
    currentPage: { type: Number, required: false },
    hideTitle: { type: Boolean, required: false }
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {}
};
</script>
