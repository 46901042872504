<template>
  <GeneralDisplay v-if="loadingComplete" :activityInfo="activityInfo">
    <div slot="activity-panel">
      <div class="mt-10 d-flex flex-row-reverse">
        <button
          class="btn btn-primary"
          @click="toggleNewMessage()"
          v-show="!showNewMessageBox"
        >
          {{
            activityType == "blog"
              ? $t("RESPONSE_FORMS.INPUTS.NEW_POST")
              : $t("RESPONSE_FORMS.INPUTS.NEW_REPLY")
          }}
        </button>
      </div>

      <!--begin::Message input Box-->
      <InputGenericResponseBox
        mode="post"
        :prepResponse="prepopulatedResponse"
        :reqBlogTitle="responseRequiredTitle"
        v-show="showNewMessageBox"
        @cancelAction="toggleNewMessage"
        @refreshList="fetchActivityInfo"
      ></InputGenericResponseBox>
      <!--end::Message input Box-->

      <!--begin::Response Lists-->
      <div v-if="showResponse" class="mt-10">
        <template v-for="(reply, index) in responseList">
          <Response
            :layer="index"
            :reply="reply"
            :targetResponse="responseId"
            :ref="`response-${reply.id}`"
            v-bind:key="index"
            :type="`${activityType}Response`"
            @deleteResponse="deleteResponse($event)"
            @deleteComment="deleteComment($event)"
            @refreshList="fetchActivityInfo"
          ></Response>
        </template>
      </div>
      <!--end::Response Lists-->
    </div>
  </GeneralDisplay>

  <!--begin::Loading Spinner-->
  <div class="row" v-else>
    <div class="col-xxl-12 min-vh-100">
      <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
        <div class="card-body my-4 pd-12">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner class="main-card-loader" label="Loading..."></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Loading Spinner-->
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { POPULATE_ACTIVITY_INFO } from "@/core/services/store/activity/activity.module";
import {
  POPULATE_ACTIVITY_BLOG_RESPONSES_LIST,
  DELETE_BLOG_RESPONSES
} from "@/core/services/store/activity/activity_blog_responses.module.js";
import {
  POPULATE_ACTIVITY_DISCUSSION_RESPONSES_LIST,
  DELETE_DISCUSSION_RESPONSES
} from "@/core/services/store/activity/activity_discussion_responses.module.js";
import Response from "@/modules/together-helpers/components/Response";
import GeneralDisplay from "@/view/components/activity/GeneralDisplay.vue";
import InputGenericResponseBox from "@/modules/together-helpers/components/generic-inputs/GenericResponseBox";
import { DELETE_REPLY_ENGAGEMENTS } from "@/core/services/store/activity/activity_reply_engagements.module";
export default {
  name: "BlogAndDisuccsionActivity",
  components: {
    Response,
    GeneralDisplay,
    InputGenericResponseBox
  },

  directives: {},

  data() {
    return {
      title: "",
      messageText: "",
      respondingTo: false,
      showNewMessageBox: false,
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },
      error: {
        active: false,
        message: ""
      },
      replies: []
    };
  },

  mounted() {
    this.cleanup();
    this.fetchActivityInfo();
  },

  watch: {
    activityId: function() {
      this.pageLoader.componentsCompleted = 0;
      this.pageLoader.componentsRequired = 1;
      this.fetchActivityInfo();
    },
    responseId: function() {
      this.pageLoader.componentsCompleted = 0;
      this.pageLoader.componentsRequired = 1;
      this.fetchResponse();
    }
  },
  methods: {
    scrollToTheTargetResponse() {
      if (this.responseId) {
        let obj = this.$refs["response-" + this.responseId][0].$el;
        let left = obj.getBoundingClientRect().left;
        let top = obj.getBoundingClientRect().top;
        window.scroll({
          top: top,
          left: left,
          behavior: "smooth",
        });
      }
    },
    fetchActivityInfo() {
      let payload = {
        projectId: this.projectInfo.id,
        activityId: this.activityId,
        params: {
          paginate: false,
          activityType: "with",
          activityStage: "with",
          activitySettings: "with",
          socialBias: "with",
          activityResponses: "with",
          stimulusAssets: "with",
          appendSignedUrls: "1"
        }
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_INFO, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
          this.fetchResponse();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity information, please check back later or contact the helpdesk";
        });
    },
    fetchResponse() {
      let payload = {
        activityId: this.activityId,
        params: {
          sentiments: "with",
          responseId: this.responseId,
          scope: "input",
          paginate: false,
          created_at: "DESC"
        }
      };
      let action =
        this.activityType == "blog"
          ? POPULATE_ACTIVITY_BLOG_RESPONSES_LIST
          : POPULATE_ACTIVITY_DISCUSSION_RESPONSES_LIST;
      this.$store
        .dispatch(action, payload)
        .then(() => {
          this.responseLoading = false;
          this.scrollToTheTargetResponse();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message = `We're having some issues retrieving the ${this.activityType} response list, please check back later or contact the helpdesk`;
        });
    },
    deleteResponse($event) {
      let action =
        this.activityType == "blog"
          ? DELETE_BLOG_RESPONSES
          : DELETE_DISCUSSION_RESPONSES;
      this.$store
        .dispatch(action, $event)
        .then(() => {
          this.$toasted.global.success_toast({
            message: this.$t(
              "RESPONSE_FORMS.DELETE_SUCCESS." + this.activityTypeId
            )
          });
          this.fetchActivityInfo();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues deleting activity response, please check back later or contact the helpdesk";
        });
    },
    deleteComment($event) {
      let payload = {
        activity_id: this.activityInfo.id, // required
        id: $event
      };
      this.$store
        .dispatch(DELETE_REPLY_ENGAGEMENTS, payload)
        .then(() => {
          this.$toasted.global.success_toast({
            message: this.$t(
              "RESPONSE_FORMS.DELETE_SUCCESS." + this.activityTypeId
            )
          });
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues deleting activity comment, please check back later or contact the helpdesk";
        });
    },
    toggleNewMessage() {
      this.showNewMessageBox = !this.showNewMessageBox;
    },
    setError(bool) {
      return (this.error = bool);
    },
    cleanup: function() {
      this.setActivityInfo([]);
      this.resetActivityBlogRepsonsesList([]);
      this.resetActivityDiscussionRepsonsesList([]);
      this.resetActivityReplyEngagementsList([]);
    },
    ...mapMutations({
      setActivityInfo: "setActivityInfo",
      resetActivityBlogRepsonsesList: "resetActivityBlogRepsonsesList",
      resetActivityDiscussionRepsonsesList: "resetActivityDiscussionRepsonsesList",
      resetActivityReplyEngagementsList: "resetActivityReplyEngagementsList"
    })
  },
  computed: {
    ...mapGetters([
      "projectInfo",
      "currentUser",
      "activityInfo",
      "activityBlogRepsonsesList",
      "activityDiscussionRepsonsesList"
    ]),
    activityId: function() {
      return this.$route.params.id;
    },
    responseId: function() {
      return this.$route.params.responseId;
    },
    activityType: function() {
      return this._.toLower(this.activityInfo.activity_type.name);
    },
    activityTypeId: function() {
      return this.activityInfo.activity_type.id;
    },
    activityUnbiased: function() {
      return this.activityInfo.social_bias.id == 1 ? true : false;
    },
    showResponse: function() {
      if (this.currentUser.extended_relation_to_project == "Subscribed Participant") {
        return this.userPosted || !this.activityUnbiased;        
      } else {
        return true;
      }
    },
    responseList: function() {
      return this.activityType == "blog"
        ? this.activityBlogRepsonsesList
        : this.activityDiscussionRepsonsesList;
    },
    userPosted: function() {
      let index = this.responseList.findIndex(
        replies => replies.user.id === this.currentUser.id
      );
      return index == -1 ? false : true;
    },
    prepopulatedResponse: function() {
      let setting = this._.find(this.activityInfo.activity_settings, { id: 1 });
      return setting ? String(setting.pivot.value) : "";
    },
    responseRequiredTitle: function () {
      let setting = this._.find(this.activityInfo.activity_settings, { id: 2 });
      if (setting) {
        return setting.pivot.value == "0"? false : true;
      } else {
        return false;
      }
    },
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
