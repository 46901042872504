<template>
  <a
    href="javascript:void(0)"
    @click="(engagements.length > 0) ? $emit('toggle_replies') : $emit('toggle_reply_input')"
    class="btn btn-icon-success btn-sm btn-text-dark-50 btn-hover-text-success rounded font-weight-bolder font-size-xs p-2"
    v-b-tooltip.hover.bottom
    :title="tooltip"
  >
    <span class="svg-icon svg-icon-md svg-icon-danger pr-1">
      <i
        class="fa-comment-alt text-success fa-lg"
        :class="{ 'far' : myEngagements.length == 0, 'fas' : myEngagements.length > 0 }"
      >
      </i>
    </span>
    {{ engagements.length }}
  </a>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EngagementReply",

  props: {
    engagements:  { type: Array, required: true }
  },

  data() {
    return {
      mySentiment: null,
      submitting: false
    }
  },

  computed: {
    ...mapGetters(["currentUser"]),

    myEngagements: function() {
      const self = this;
      let myEngagements = this.engagements.filter(function(sentiment) {
        return sentiment.user_id == self.currentUser.id;
      });
      return myEngagements;
    },

    tooltip: function() {
      let tooltip = null;

      if (this.myEngagements.length > 0) {
        return `${this.$t("REVIEW.STREAM.REPLY_SUMMARY_ME")}`;
      } else if (this.engagements.length == 1) {
        return this.engagements.length + ` ${this.$t("REVIEW.STREAM.REPLY_SUMMARY_SINGULAR")}`;
      } else if (this.engagements.length > 1 || this.engagements.length == 0) {
        return this.engagements.length + ` ${this.$t("REVIEW.STREAM.REPLY_SUMMARY_PLURAL")}`;
      };

      return tooltip;
    }
  }
};
</script>
