<template>
  <span style="line-height:2rem" class="d-block">
    <template v-for="(block, b) in questionStructure">
      <span
        :key="b"
        v-if="block.type == 'text'"
      >
        {{ block.placeholder }}
      </span>
      <br :key="b" v-else-if="block.type == 'page_break'" />
      <component
        v-else 
        :key="b"
        :is="`fitbComponent-${block.type}`"
        class="d-inline-block"
        :slotNumber="block.slotNumber"
        :fitbErrorSlot="fitbErrorSlot"
        :block="block"
        :questionId="questionId"
        @blank-filled="$emit('blank-filled', $event)"
      >
      </component>
    </template>
  </span>
</template>

<script>
import fitbComponentOpenEnded from "@/modules/together-helpers/components/specialised-inputs/fitbComponentOpenEnded";
import fitbComponentSingleChoice from "@/modules/together-helpers/components/specialised-inputs/fitbComponentSingleChoice";

export default {
  name: "FITBQuestion",

  props: {
    questionStructure: { type: Array, required: true },
    questionId: { type: Number, required: true },
    fitbErrorSlot: { type: Array, required: true }
  },

  components: {
    fitbComponentOpenEnded,
    fitbComponentSingleChoice
  }
}
</script>
<style scoped>
.px-sm-1 {
  padding-left: 0.075rem !important;
  padding-right: 0.075rem !important
}
</style>