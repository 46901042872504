<template>
  <div>
    <a
      id="notepad_toggle"
      @click="$emit('toggle-notepad', {
        tab: 1,
        morphType: morphType,
        morphId: morphId,
        activityId: baseResponse.activity.id,
        baseResponse: baseResponse
      })"
      href="javascript:void(0)"
      class="btn btn-icon-primary btn-sm btn-text-dark-50 bg-hover-light-primary btn-hover-text-primary rounded font-weight-bolder font-size-xs p-2"
      v-b-tooltip.hover.bottom
      :title="tooltip"
    >
      <span class="svg-icon svg-icon-md svg-icon-primary pr-1">
        <i
          class="fa-sticky-note text-primary fa-lg"
          :class="{ 'far' : myEngagements.length == 0, 'fas' : myEngagements.length > 0 }"
        />
      </span>
      {{ engagements.length }}
    </a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "InteractionNote",

  props: {
    morphType:    { type: String, required: true },
    morphId:      { type: Number, required: true },
    engagements:  { type: Array, required: true },
    baseResponse: { type: Object, required: true }
  },

  computed: {
    ...mapGetters([
      "currentUser"
    ]),
    myEngagements: function() {
      const self = this;
      let myEngagements = this.engagements.filter(function(sentiment) {
        return sentiment.user_id == self.currentUser.id;
      });
      return myEngagements;
    },
    tooltip: function() {
      let tooltip = null;

      if (this.myEngagements.length > 0) {
        return `${this.$t("REVIEW.STREAM.NOTE_SUMMARY_ME")}`;
      } else if (this.engagements.length == 1) {
        return this.engagements.length + ` ${this.$t("REVIEW.STREAM.NOTE_SUMMARY_SINGULAR")}`;
      } else if (this.engagements.length > 1 || this.engagements.length == 0) {
        return this.engagements.length + ` ${this.$t("REVIEW.STREAM.NOTE_SUMMARY_PLURAL")}`;
      };

      return tooltip;
    }
  }
}
</script>