<template>
  <b-form inline>
     <b-form-group
        :description="message"
     >
       <b-form-input
          v-model="caption"
          class="mb-2 mr-sm-2 mb-sm-0 text-center w-100"
          placeholder="Caption"
          style="min-width:350px"
       ></b-form-input>
     </b-form-group>
  </b-form>
</template>

<script>
import { mapGetters } from "vuex";

import {
  UPDATE_PICTURE_BOOK_RESPONSES
} from "../../../vuex/activity/response/activity_picture_book_responses.module";

export default {
  name: "PictureBookCaption",

  props: {
    post: { type: Object, required: true }
  },

  data() {
    return {
      caption: "",
      message: "",
      changed: false
    }
  },

  mounted() {
    this.caption = this.post.response;
    this.ready = true;
  },

  methods: {
    updateCaption: function() {
      if (this.caption != this.post.response || (this.changed == true)) {
        let caption = (this.caption == "") ? " " : this.caption;

        let payload = {
          activityId: this.activityInfo.id,
          activityPictureBookResponseId: this.post.id,
          data: {
            response: caption
          }
        };
        this.$store
          .dispatch(UPDATE_PICTURE_BOOK_RESPONSES, payload)
          .then(() => {
            this.changed = true;
            this.$toasted.global.success_toast({
              message: this.$t(
                "Caption Saved"
              )
            });
          })
      }
    }
  },

  watch: {
    caption: function (newValue) {
      const self = this;
      if (newValue == "") {
        this.updateCaption();
      } else {
        setTimeout(function () {
          if (newValue == self.caption) {
            self.updateCaption();
          }
        }, 2000);        
      }
    }
  },

  computed: {
    ...mapGetters(["activityInfo"])
  }
};
</script>