<template>
  <span
    v-if="!haveSelectedAPinLabel"
    class="pin"
    style="position: relative; width: fit-content"
    :style="computedCSSCoordinates"
  >
    <template v-for="(pins, p) in availablePinByType">
      <PinIcon
        v-show="
          pins.placement_maximum == null ||
          (pins.placement_maximum !== null &&
            pins.pinsPut.length < pins.placement_maximum)
        "
        :key="p"
        :type="pins.system_markup_pin_type_id"
        :height="35"
        @pin-selected="$emit('mutatePin', pin, pins, 'select')"
      ></PinIcon>
    </template>
  </span>
  <span
    v-else
    class="pin"
    v-b-tooltip.hover.right
    :title="textResponseTooltip == null ? '' : textResponseTooltip"
    :style="computedCSSCoordinates"
  >
    <PinDraftDetail
      :ref="'test'"
      :pin="pin"
      :domain="type"
      :type="pin.engagement_question_pin.system_markup_pin_type_id"
      :availablePinByType="availablePinByType"
      :selectedPins="selectedPins"
      @mutatePin="mutatePin"
      @clickPin="clickPin"
    />
  </span>
</template>

<script>
import PinDraftDetail from "./PinDraftDetail.vue";
import PinIcon from "./PinIcon.vue";

export default {
  name: "MarkupPinDraft",

  props: {
    pin: { type: Object, required: true },
    type: { type: String, required: true },
    filterValue: { type: Array, required: true },
    availablePinByType: { type: Array, required: true },
  },

  data() {
    return {
      tmp: 1,
    };
  },

  components: { PinDraftDetail, PinIcon },

  methods: {
    mutatePin(pin, pins, action, model = null) {
      this.$emit("mutatePin", pin, pins, action, model);
    },
    clickPin(pin, refName) {
      this.$emit("clickPin", pin, refName);
    },
  },

  watch: {
    pin: {
      handler() {
        this.tmp += 1;
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {
    isMobile: function () {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    computedCSSCoordinates() {
      let coordinates = {
        top: `${this.pin.pin_y - 35}px`,
        left: `${this.pin.pin_x}px`,
      };
      if (
        this.filterValue.length > 0 &&
        !this.filterValue.includes(this.pin.engagement_question_pin_id)
      ) {
        coordinates.display = `none`;
      }
      if (!this.haveSelectedAPinLabel) {
        let count = this.availablePinByType.length;
        let xPx = 35 * count + (count > 1 ? 20 : 0);
        coordinates.borderRadius = `${xPx}px`;
        if (this.isMobile) {
          coordinates.flexDirection = `row`;
          coordinates.width = `50px`;
          coordinates.height = `${xPx}px`;
          coordinates.top = `${this.pin.pin_y - xPx}px`;
        }
      }
      return coordinates;
    },
    haveSelectedAPinLabel() {
      return this.pin.engagement_question_pin.system_markup_pin_type_id;
    },
    selectedPins() {
      return this.availablePinByType.filter(
        (item) =>
          item.system_markup_pin_type_id ==
          this.pin.engagement_question_pin.system_markup_pin_type_id
      )[0];
    },
    textResponseTooltip: function () {
      this.tmp;
      return typeof this.pin.text_response === "string" &&
        this.pin.text_response.length > 0
        ? this.pin.text_response
        : null;
    },
  },
};
</script>
<style lang="scss" scoped>
.pin {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-around;
}
</style>