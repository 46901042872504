<template>
  <div class="d-flex justify-content-between pt-10">
    <div class="mr-2">
      <button
        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
        data-wizard-type="action-prev"
        v-if="wizardForm.length > 1"
      >
        <!-- Previous -->
        {{ prev }}
      </button>
    </div>
    <div>
      <button
        v-on:click="submitAction"
        class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
        data-wizard-type="action-submit"
      >
        <!-- Submit -->
        {{ submit }}
      </button>
      <button
        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
        data-wizard-type="action-next"
      >
        <!-- Next Step -->
        {{ next }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "WizardActions",

  props: {
    wizardForm: { type: Array, required: true },
    prev: { type: String, required: false },
    submit: { type: String, required: false },
    next: { type: String, required: false }
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {
    submitAction() {
      this.$emit("submitAction");
    }
  }
};
</script>
