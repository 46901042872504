<template>
  <div class="mt-10">
    <div v-if="fetchingReplies" class="w-100 text-center">
      <b-spinner
        variant="primary"
        label="Spinning"
        class="table-loader my-3"
      />      
    </div>

    <div v-else>
      <ReplyResponse
        class="px-3"
        v-for="(reply) in replies"
        :key="reply.id"
        :baseResponse="baseResponse"
        :reply="reply"
        :domain="domain"
        @on-response-deletion="handleResponseDeletion(reply.id)"
        @toggle-notepad="$emit('toggle-notepad',$event)"
      />
    </div>
  </div>
</template>

<script>
import {
  mapActions
} from "vuex";

import ReplyResponse from "./ReplyResponse.vue";

export default {
  name: "ReplyContainer",

  props: {
    baseResponse:   { type: Object, required: true },
    morphType:      { type: String, required: true },
    morphId:        { type: Number, required: true },
    domain:         { type: String, required: true }
  },

  data() {
    return {
      fetchingReplies: false,
      replies: []
    }
  },

  components: {
    ReplyResponse
  },

  mounted() {
    this.fetchReplies();
  },

  methods: {
    fetchReplies: function() {
      let payload = {
        route: `api/v1/engagement_responses/${this.baseResponse.id}/engagement_interactions`,
        params: {
          paginate: false,
          engagement_interaction_type: this.morphType,
          engagement_interaction_id: this.morphId
        },
        filters: {
          system_interaction_type_id: 3
        },
        relations: {
          directInteractions: 'with',
          user: 'with'
        }
      };

      this.genericResource(payload).then((res) => {
        this.replies = res._embedded.engagement_interactions;
      }).finally(() => {
        this.fetchingReplies = false;
      })
    },
    handleResponseDeletion: function(removedReplyId) {
      this.replies = this.replies.filter(function(reply) { return reply.id != removedReplyId });
      this.$emit('on-response-deletion', removedReplyId);
    },
    ...mapActions({
      genericResource: "genericResource"
    })
  }
}
</script>