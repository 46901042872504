<template>
  <div class="d-flex w-100 justify-content-center flex-wrap">
    <!--begin::Pin Instruction -->
    <div class="d-flex w-100 flex-row-reverse">
      <span class="font-size-xs text-success text-right"
        >{{ $t("ACTIVITY.MARKUP.VIDEO_PIN_INSTRUCTION") }}
      </span>
    </div>
    <!--end::Pin Instruction -->

    <!--begin::Questions -->
    <h5
      class="mt-5 text-bold w-100 text-center"
      v-html="question.question"
    ></h5>
    <!--end::Questions -->

    <!--begin::Pin Instruction -->
    <div class="d-flex w-100 flex-row-reverse">
      <span
        :class="displayCountPinsError ? 'text-danger' : 'text-success'"
        class="font-size-xs"
        >{{ remainPinLabelText }}
      </span>
      <i class="fa-sm fa fa-map-marker-alt text-danger mr-3"></i>
    </div>
    <!--end::Pin Instruction -->

    <!--begin::video -->
    <div
      class="mt-5 mb-20 d-flex w-100 justify-content-center position-relative mark-up-video"
    >
      <!-- Mark Up Video -->
      <video-player
        class="video-player-box"
        ref="videoPlayer"
        :options="playerOptions"
        :playsinline="true"
        @pause="onPlayerPause($event)"
        @loadeddata="onPlayerLoadeddata($event)"
        @timeupdate="onPlayerTimeupdate($event)"
        @ready="playerReadied"
      >
      </video-player>
      <!-- Mark Up Video -->

      <div
        class="mark-up-video-overlay"
        ref="mark-up-video-overlay"
        :show="videoLoaded"
      >
        <div class="prefix-time">{{ formattedCurrentTime }}</div>
        <b-progress height="2px" :value="currentVideoProgress"></b-progress>
        <MarkUpVideoPins
          v-for="index in availablePins"
          :key="index"
          :index="index"
          :ref="`pin-${index}`"
          :pinsColor="pinsColor(index)"
          :requiredTextResponse="requiredTextResponse"
          :requiredSentiment="requiredSentiment"
          :requiredSentimentTextResponse="requiredSentimentTextResponse"
          :requiredFillPopover="requiredFillPopover"
          :textResponseLabel="textResponseLabel"
          :sentimentLabel="sentimentLabel"
          :sentimentTextResponseLabel="sentimentTextResponseLabel"
          @removePins="resetPutPins($event)"
        ></MarkUpVideoPins>
        <div class="suffix-time">{{ formattedVideoDuration }}</div>
      </div>
    </div>
    <!--end::video -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import "video.js/dist/video-js.css";
import GeneralFunctionsHelper from "@/helpers/GeneralFunctions";
import MarkUpVideoPins from "@/view/components/activity/mark_up/MarkUpVideoPins.vue";
import { videoPlayer } from "vue-video-player";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "MarkUpVideoQuestions",

  props: {
    question: { type: Object, required: true },
    form: { type: Array, required: true },
    pinCountType: { type: Number, required: true },
    pinCount: { type: Number, required: true }
  },

  components: {
    videoPlayer,
    MarkUpVideoPins
  },

  validations: {
    form: {
      $each: {
        pins: {
          required,
          $each: {
            timestamp: {
              required
            },
            text_response: {
              required: requiredIf(function() {
                return this.requiredTextResponse;
              })
            },
            sentiment: {
              required: requiredIf(function() {
                return this.requiredSentiment;
              })
            },
            sentiment_text_response: {
              required: requiredIf(function() {
                return this.requiredSentimentTextResponse;
              })
            }
          }
        }
      }
    }
  },

  data() {
    return {
      pinsPut: 0,
      availablePins: 0,
      videoDuration: 0,
      currentVideoProgress: 0,
      formattedCurrentTime: "00:00",
      formattedVideoDuration: "00:00",
      displayCountPinsError: false,
      videoLoaded: false
    };
  },

  watch: {
    question: {
      handler() {
        this.resetQuestionsPins();
      },
      deep: true
    },
    pinsPut: {
      handler(val) {
        if (val < 1) {
          return (this.displayCountPinsError = true);
        } else if (this.pinCountType !== 0 && val < this.pinCount) {
          return (this.displayCountPinsError = true);
        }
        return (this.displayCountPinsError = false);
      },
      deep: true
    }
  },

  mounted() {
    this.availablePins = this.form[0].pins.length;
  },

  methods: {
    puttingPins(time) {
      //check remaining number of pins exceed
      if (this.pinsPut === this.availablePins) {
        // If it is not exceeding, we have to expand the form arr
        if (this.availablePins < this.pinCount) {
          this.availablePins++;
          this.$emit("addAnotherPins", this.question.id);
        } else {
          this.setError(this.$t("ACTIVITY.MARKUP.EXCEED_PIN_ERRORS"));
          return false;
        }
      }
      let target_pin = this.pinsPut + 1;
      this.settingPinTimeStampData(this.pinsPut, time);
      this.displayClickedInPins(target_pin, time);
      this.pinsPut++;

      return true;
    },
    settingPinTimeStampData(pinIndex, time) {
      this.form[0].pins[pinIndex].timestamp = time;
    },
    resetQuestionsPins() {
      this.$nextTick(function() {
        // reset previous put pins after DOM update
        Object.keys(this.$refs).forEach(pinRefIndex => {
          let pin = this._.toString(pinRefIndex);
          if (this.$refs[pin][0] !== undefined && pin.includes("pin-")) {
            let arr = {
              pinIndex: this._.trim(pin, "pin-"),
              pinRef: pin,
              caller: "resetQuestionsPins"
            };
            this.resetPutPins(arr);
          }
        });
        this.availablePins = this.form[0].pins.length;
        this.pinsPut = 0;
        return false;
      });
    },
    displayClickedInPins(index, time) {
      let pins = `pin-${index}`;
      this.$nextTick(function() {
        // update new pin position after DOM update
        this.$refs[pins][0].newPinsPostions(time, this.videoDuration);
      });
    },
    resetPutPins($event) {
      this.$nextTick(function() {
        let target_pin = $event.pinIndex - 1;
        let refPin = $event.pinRef;
        if (this.form[0].pins[target_pin] !== undefined) {
          this.form[0].pins[target_pin].timestamp = null;
          if (this.requiredTextResponse || this.textResponseLabel) {
            this.form[0].pins[target_pin].text_response = null;
          }
          if (this.requiredSentiment || this.sentimentLabel) {
            this.form[0].pins[target_pin].sentiment = null;
          }
          if (
            this.requiredSentimentTextResponse ||
            this.sentimentTextResponseLabel
          ) {
            this.form[0].pins[target_pin].sentiment_text_response = null;
          }
          this.$refs[refPin][0].resetPinsPostions();
          if ($event.caller === "removePins") {
            this.pinsPut--;
          }
        }
        return false;
      });
    },
    onPlayerPause(player) {
      setTimeout(() => {
        const playing = player =>
          !!(
            player.currentTime() > 0 &&
            !player.paused() &&
            !player.ended() &&
            player.readyState() > 2
          );
        if (!playing(player) && !player.ended()) {
          this.puttingPins(player.currentTime());
        }
      }, 100);
    },
    onPlayerLoadeddata(player) {
      this.videoDuration = player.duration();
      this.formattedVideoDuration = GeneralFunctionsHelper.getSecondsToTimeString(
        this.videoDuration
      );
    },
    onPlayerTimeupdate(player) {
      let currentTime = player.currentTime();
      this.formattedCurrentTime = GeneralFunctionsHelper.getSecondsToTimeString(
        currentTime
      );
      this.currentVideoProgress = Math.floor(
        (currentTime / this.videoDuration) * 100
      );
    },
    playerReadied() {
      this.videoLoaded = true;
    },
    pinsColor(index) {
      return GeneralFunctionsHelper.getSampleColor(index);
    },
    setError(msg) {
      this.$toasted.global.error_toast({
        message: msg
      });
      return;
    }
  },

  computed: {
    ...mapGetters(["activityInfo"]),
    playerOptions: function() {
      let asset = this.activityInfo.stimulus_assets[0];
      return {
        height: "435",
        width: "580",
        language: "en",
        sources: [
          {
            type: "video/webm",
            src: asset.signedUrl
          }
        ],
        poster: asset.signedThumbUrl
      };
    },
    remainingMaxPins: function() {
      return this.question.pin_count - this.pinsPut;
    },
    remainPinLabelText: function() {
      let prefix = this.$t("ACTIVITY.MARKUP.PIN_DESC_PREFIX");
      let suffix = this.$t("ACTIVITY.MARKUP.PIN_DESC_SUFFIX");
      return `${prefix} ${this.question.type_label} ${this.remainingMaxPins} ${suffix}`;
    },
    requiredTextResponse: function() {
      let prompt = this._.isString(this.question.text_response_prompt);
      let required = this.question.text_response_required;
      return prompt && required ? true : false;
    },
    requiredSentiment: function() {
      let prompt = this._.isString(this.question.sentiment_response_prompt);
      let required = this.question.sentiment_response_required;
      return prompt && required ? true : false;
    },
    requiredSentimentTextResponse: function() {
      let prompt = this._.isString(
        this.question.sentiment_text_response_prompt
      );
      let required = this.question.sentiment_text_response_required;
      return prompt && required ? true : false;
    },
    textResponseLabel: function() {
      return this.question.text_response_prompt;
    },
    sentimentLabel: function() {
      return this.question.sentiment_response_prompt;
    },
    sentimentTextResponseLabel: function() {
      return this.question.sentiment_text_response_prompt;
    },
    requiredFillPopover: function() {
      let requiredTextResponse = this._.isString(
        this.question.text_response_prompt
      );
      let requiredSentiment = this._.isString(
        this.question.sentiment_response_prompt
      );
      let requiredSentimentTextResponse = this._.isString(
        this.question.sentiment_text_response_prompt
      );
      return requiredTextResponse ||
        requiredSentiment ||
        requiredSentimentTextResponse
        ? true
        : false;
    },
    markUpVideoPlayer() {
      return this.$refs.videoPlayer.player;
    }
  }
};
</script>

