<template>
  <div>
    <template v-for="(reply, index) in computedResponseList">
      <Response
        :layer="index"
        :reply="reply"
        :targetResponse="targetResponse"
        v-bind:key="index"
        :ref="`response-${reply.id}`"
        type="ideastormResponse"
        @deleteResponse="deleteResponse($event)"
        @deleteComment="deleteComment($event)"
      >
        <div slot="ideastorm-vote-panel" class="mr-5 mt-1">
          <div
            class="d-flex flex-column justify-content-center align-items-center"
          >
            <span
              class="bg-hover-light-success btn-hover-text-success rounded p-2"
              @click="toggleVote(reply.id, 1)"
            >
              <i
                class="font-size-h3 fas fa-caret-up"
                :class="reply.upvoted ? 'text-success' : 'text-muted'"
              ></i
            ></span>
            <span class="font-size-h3">{{ reply.totalVotesCount }}</span>
            <span
              @click="toggleVote(reply.id, 0)"
              class="bg-hover-light-danger btn-hover-text-danger rounded p-2"
            >
              <i
                class="font-size-h3 fas fa-caret-down"
                :class="reply.downvoted ? 'text-danger' : 'text-muted'"
              ></i
            ></span>
          </div>
        </div>

        <div class="w-100 mt-2" slot="ideastorm-title-panel">
          <a
            href="javascript:void(0)"
            class="text-primary text-hover-primary mb-1 font-size-lg font-weight-bolder pr-6"
            >{{ _.capitalize(reply.title) }}</a
          >
        </div>

        <div
          class="w-100 my-2"
          slot="ideastorm-category-panel"
          v-if="reply.activity_ideastorm_categories.length > 0"
        >
          <template
            v-for="(category, idx) in reply.activity_ideastorm_categories"
          >
            <span
              v-bind:key="idx"
              class="label label-lg label-inline font-weight-bold py-4 mr-1"
              :class="getLabelColor(category)"
              >{{ _.capitalize(category.name) }}</span
            >
          </template>
        </div>

        <div style="width: 20px" slot="margin-inner-replies"></div>
      </Response>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Response from "@/modules/together-helpers/components/Response";
import { CREATE_IDEASTORM_RESPONSES_VOTE } from "@/core/services/store/activity/activity_ideastorm_responses.module.js";
import { DELETE_IDEASTORM_RESPONSES } from "@/core/services/store/activity/activity_ideastorm_responses.module.js";
import { DELETE_REPLY_ENGAGEMENTS } from "@/core/services/store/activity/activity_reply_engagements.module";
export default {
  name: "IdeastormResponse",
  props: {
    ideastormCategories: { type: Array, required: true },
    targetResponse: { required: false, default: null }
  },

  components: {
    Response
  },
  mounted() {
    this.scrollToTheTargetResponse();
  },

  watch: {
    responseId: function (val) {
      this.scrollToTheTargetResponse();
    },
  },

  methods: { 
    scrollToTheTargetResponse() {
      if (this.responseId) {
        let obj = this.$refs["response-" + this.responseId][0].$el;
        let left = obj.getBoundingClientRect().left;
        let top = obj.getBoundingClientRect().top;
        window.scroll({
          top: top,
          left: left,
          behavior: "smooth",
        });
      }
    },
    getLabelColor(category) {
      let cate = this.ideastormCategories.find(cat => cat.id === category.id);
      return "label-light-" + cate.labelColor;
    },
    toggleVote(response_id, vote) {
      let payload = {
        activityId: this.activityInfo.id, // required
        data: {
          vote: vote,
          votable_type: "ActivityIdeastormResponse",
          votable_id: response_id,
        }
      };

      this.$store
        .dispatch(CREATE_IDEASTORM_RESPONSES_VOTE, payload)
        .then(() => {
          this.$parent.$parent.pageLoader.componentsCompleted = 0;
          this.$parent.$parent.fetchActivityInfo();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues up/down voting the ideastorm response, please check back later or contact the helpdesk";
        });
    },
    deleteResponse($event) {
      this.$store
        .dispatch(DELETE_IDEASTORM_RESPONSES, $event)
        .then(() => {
          this.$toasted.global.success_toast({
            message: this.$t(
              "RESPONSE_FORMS.DELETE_SUCCESS." + this.activityTypeId
            )
          });
          this.$parent.$parent.pageLoader.componentsCompleted = 0;
          this.$parent.$parent.fetchActivityInfo();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues deleting activity response, please check back later or contact the helpdesk";
        });
    },
    deleteComment($event) {
      let payload = {
        activity_id: this.activityInfo.id, // required
        id: $event
      };
      this.$store
        .dispatch(DELETE_REPLY_ENGAGEMENTS, payload)
        .then(() => {
          this.$toasted.global.success_toast({
            message: this.$t(
              "RESPONSE_FORMS.DELETE_SUCCESS." + this.activityTypeId
            )
          });
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues deleting activity comment, please check back later or contact the helpdesk";
        });
    }
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "activityInfo",
      "activityIdeastormRepsonsesList",
      "activityIdeastormResponsesFilter"
    ]),

    activityTypeId: function() {
      return this.activityInfo.activity_type.id;
    },
    responseId: function() {
      return this.$route.params.responseId;
    },
    computedResponseList: function() {
      let list = this.activityIdeastormRepsonsesList;
      list.forEach(responses => {
        let tempArr = this._.map(responses.activity_ideastorm_votes, "vote");
        let totalVotesCount = 0;
        if (tempArr.length > 0) {
          for (let index = 0; index < tempArr.length; index++) {
            const upOrDownVote = tempArr[index] == 0 ? -1 : 1;
            totalVotesCount += upOrDownVote;
          }
        }
        responses.totalVotesCount = totalVotesCount;

        responses.upvoted = false;
        responses.downvoted = false;
        let voted = undefined;

        if (responses.activity_ideastorm_votes) {
          voted = responses.activity_ideastorm_votes.find(
            votes => votes.user_id === this.currentUser.id
          );
        }

        if (voted !== undefined) {
          responses.upvoted = voted["vote"] !== 1 ? false : true;
          responses.downvoted = voted["vote"] !== 0 ? false : true;
        }
      });

      let chronological = [];
      if (this.activityIdeastormResponsesFilter == "date") {
        chronological = this._.sortBy(list, "created_at");
      } else {
        chronological = this._.sortBy(list, "totalVotesCount");
      }

      return chronological.reverse();
    }
  }
};
</script>
