<template>
  <b-card
    class="card card-custom gutter-b"
    header-text-variant="white"
    header-tag="header"
    header-bg-variant="primary"
  >
    <template #header class="py-2">
      <div class="d-flex justify-content-between w-100">
        <h5 class="mb-0" style="margin-top: 8px">
          {{ $t("ACTIVITY.PICTURE_BOOK.RESPONSES") }}
        </h5>
        <b-dropdown id="filterDropdown" :text="filterText">
          <b-dropdown-item @click="filterOption = 1">{{
            $t("ACTIVITY.PICTURE_BOOK.FILTER_1")
          }}</b-dropdown-item>
          <b-dropdown-item @click="filterOption = 2">{{
            $t("ACTIVITY.PICTURE_BOOK.FILTER_2")
          }}</b-dropdown-item>
        </b-dropdown>
      </div>
    </template>

    <!--begin::Media File-->
    <b-card-text class="d-flex justify-content-center">
      <div
        v-if="
          pageRefresher.componentsCompleted >= pageRefresher.componentsRequired
        "
        class="row mx-0"
      >
        <template v-for="(post, i) in computedPosts">
          <PictureBookThumb
            :key="i"
            :post="post"
            :response="post.activity_response"
            class="col-4 mb-5"
          >
          </PictureBookThumb>
        </template>
      </div>
      <b-spinner v-else class="main-card-loader" label="Loading...">
      </b-spinner>
    </b-card-text>
    <!--end::Media File-->

    <!--begin::Load More Replies-->
    <b-overlay :show="loadingMoreRepliesList" rounded="sm" class="d-flex justify-content-center">
      <a
        v-if="showMorePageLogo"
        @click="loadMoreRepliesList()"
        class="btn btn-hover-text-primary btn-hover-icon-primary btn-sm font-size-xs btn-text-dark-50 bg-hover-gray-100 rounded font-weight-bolder p-2"
      >
        <i class="fa fa-reply text-success mr-2 fa-rotate-180"></i
        >{{ $t("RESPONSE_FORMS.LABELS.LOAD_MORE_REPLIES") }}</a
      >
    </b-overlay>
    <!--end::Load More Replies-->
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";

import PictureBookThumb from "@/modules/together-helpers/activities/response/picture_book/PictureBookThumb";

export default {
  name: "PictureBookResponse",

  props: {
    responses: { type: Array, required: true },
    posts: { type: Array, required: true },
    pageRefresher: { type: Object, required: true },
    showMorePageLogo: { type: Boolean, required: true },
    loadingMoreRepliesList: { type: Boolean, required: true },
  },

  data() {
    return {
      filterOption: 1,
    };
  },

  components: {
    PictureBookThumb,
  },

  mounted() {
    this.filterOption =
      this.currentUser.relation_to_project == "participant" ? 1 : 2;
  },

  methods: {
    getPost: function (activityResponseId) {
      return this.posts.filter(function (post) {
        return post.activity_response_id == activityResponseId;
      })[0];
    },
    loadMoreRepliesList: function () {
      this.$emit("loadMoreRepliesList");
    }
  },

  computed: {
    ...mapGetters(["activityInfo", "currentUser"]),

    filterText: function () {
      return this.filterOption == 1
        ? `${this.$t("ACTIVITY.PICTURE_BOOK.FILTER_1")}`
        : `${this.$t("ACTIVITY.PICTURE_BOOK.FILTER_2")}`;
    },

    computedPosts: function () {
      if (this.filterOption == 2) {
        return this.posts;
      } else {
        const self = this;
        return this.posts.filter(function (post) {
          return post.user.id == self.currentUser.id;
        });
      }
    },
  },
};
</script>

<style scoped>
.card-header {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
</style>