<template>
  <div>
    <component
      :is="inputComponent"
    />

    <div
      v-if="inputComponent === null"
      class="row"
    >
      <div class="col-xxl-12 min-vh-100">
        <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
          <div class="card-body my-4 pd-12">
            <div class="d-flex justify-content-center mb-3">
              <b-spinner class="main-card-loader" label="Loading..."></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>    
  </div>

</template>

<script>
import { mapGetters } from "vuex";
import BlogAndDisuccsionActivity from "@/view/content/activity/BlogAndDisuccsionActivity.vue";
import MarkupActivity from "@/view/content/activity/MarkUpActivity.vue";
import ExternalActivity from "@/view/content/activity/ExternalActivity.vue";
import DiaryActivity from "@/view/content/activity/DiaryActivity.vue";
import IdeastormActivity from "@/view/content/activity/IdeastormActivity.vue";
import SurveyActivity from "@/view/content/activity/SurveyActivity.vue";
import PictureBookActivity from "@/view/content/activity/PictureBookActivity.vue";
import NewMarkupActivity from "@/view/content/activity/NewMarkupActivity.vue";

export default {
  name: "view_activity",
  watch: {
    pageTitle: {
      handler(val) {
        document.title =
          (val !== undefined ? val : " ••• ") + " | Input | Together";
      },
      deep: true,
      immediate: true
    }
  },

  components: {
    BlogAndDisuccsionActivity,
    DiaryActivity,
    IdeastormActivity,
    SurveyActivity,
    PictureBookActivity,
    MarkupActivity,
    ExternalActivity,
    NewMarkupActivity
  },
  mounted() {
    // if activity type is poll, redirect back to home page
    if (this.activityType.name == "Poll") {
      this.$router.push({ path: "/" });
    }
  },
  computed: {
    ...mapGetters(["projectInfo", "activityInfo", "activityList"]),
    activityId: function() {
      return this.$route.params.id;
    },
    pageTitle: function() {
      return (this.activityInfo != null) ? this.activityInfo.title : "";
    },
    activityType: function() {
      let activity = this._.find(this.activityList, {
        id: parseInt(this.activityId)
      });
      return activity.activity_type;
    },
    inputComponent: function() {
      switch (this.activityType.id) {
        case 1:
        case 3:
          return 'BlogAndDisuccsionActivity';
        case 2:
          return 'DiaryActivity';
        case 4:
          return 'SurveyActivity';
        case 5:
          return 'PictureBookActivity';
        case 6:
          return 'IdeastormActivity';
        case 7:
          return 'ExternalActivity';
        case 8:
          return 'MarkupActivity';
        case 10:
          return 'NewMarkupActivity';
        default:
          return null;
      }
    }
  }
};
</script>
