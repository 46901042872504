<template>
  <div class="p-2 d-flex justify-content-start">
    <div
      v-for="(pinSummary, ps) in aggregatedPinSummary"
      :key="ps"
      class="d-flex align-items-center pr-3"
    >
      <PinIcon
        :key="ps"
        :type="pinSummary.pin_class_id"
        :width="25"
        :height="25"
        :hoverable="false"
      />
      <span style="font-size:.9rem; padding-top: 2px">
        {{ pinSummary.count }}
      </span>
    </div>
  </div>
</template>

<script>
import PinIcon from "./PinIcon.vue";

export default {
  name: "PinCompletionTally",

  props: {
    model:  { type: Array, required: true }
  },

  data() {
    return {
      aggregatedPinSummary: []
    }
  },

  components: {
    PinIcon
  },

  watch: {
    model: {
      handler(newModelValue) {
        const newArray = newModelValue.reduce((acc, curr) => {
          const id = curr.engagement_question_pin_id;
          const existingItem = acc.find(item => item.engagement_question_pin_id === id);

          if (existingItem) {
            existingItem.count++;
          } else {
            acc.push(
              {
                engagement_question_pin_id: id,
                count: 1,
                pin_class_id: curr.engagement_question_pin.system_markup_pin_type_id
              }
            );
          }

          return acc;
        }, []);

        this.aggregatedPinSummary = newArray;
      },
      immediate: true,
      deep: true
    }
  }
}
</script>