<template>
  <div class="fitb">
    <b-form-select
      :id="`${questionId}-${this.slotNumber}-input`"
      v-model="selected"
      :options="options"
      class="fitb-input fitb-single-choice"
      :class="{ 'filled' : selected != null , 'fitb-invalid' : invalid }"
    >
    </b-form-select>

    <b-tooltip
      v-if="invalid"
      :target="`${questionId}-${this.slotNumber}-input`"
      triggers="hover"
      placement="right"
    >
      {{ $t('FITB.ERROR.CHOICE') }}
    </b-tooltip>

  </div>
</template>

<script>
export default {
  name: "fitbComponentSingleChoice",

  props: {
    block: { type: Object, required: true },
    fitbErrorSlot: { type: Array, required: true },
    slotNumber: { type: Number, required: true }
  },

  data() {
    return {
      selected: null,
      invalid: false
    }
  },

  watch: {
    selected(newSlotResponse) {
      this.$emit('blank-filled',{ slotResponse: newSlotResponse, slotNumber: this.slotNumber });
    },
    fitbErrorSlot: {
      handler(slots) {
        let inArray = false;
        for (let index = 0; index < slots.length; index++) {
          const element = slots[index];
          if (this.slotNumber == element) inArray = true;
        }
        this.invalid = inArray
      },
      immediate: false
    }
  },

  computed: {
    options: function() {
      let options = [
        { value: null, text: `${this.$t("FITB.PLACEHOLDER.CHOICE")}`, disabled: true }
      ];

      let choices = (Array.isArray(this.block.choices)) ? this.block.choices : Object.values(this.block.choices);

      choices.forEach(choice => {
        options.push({
          value: choice.id,
          text: choice.choice
        });
      });

      return options;
    }
  }
}
</script>

<style scoped>
.custom-validation-failure {
  width: auto!important;
  margin-top: unset !important;
}
</style>