<template>
  <div>
    <!--begin::Response Div-->
    <div
      class="d-flex p-5 bg-hover-gray-100 rounded position-relative"
      v-on:mouseover="hovering = true"
      v-on:mouseleave="hovering = false"
    >
      <slot name="ideastorm-vote-panel"></slot>
      <!--begin::Avatar-->
      <UserThumbnail class="mr-5" :user="reply.user" size="40"></UserThumbnail>
      <!--end::Avatar-->
      <!--begin::Info-->
      <div class="d-flex flex-column flex-row-fluid position-relative">
        <!--begin::Info-->
        <div class="d-flex align-items-start justify-content-between position-relative">
          <div class="w-75 d-flex flex-wrap">
            <slot name="ideastorm-title-panel"></slot>
            <div class="w-100">
              <a
                href="javascript:void(0)"
                class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder pr-6"
              >
                <AnonymisedUsername :user="reply.user"></AnonymisedUsername>
              </a>
              <span
                class="text-muted font-weight-normal flex-grow-1 font-size-sm"
              >
                <GenericDatetimeDisplay
                  :long="true"
                  :date="reply.updated_at"
                  :lastUpdated="true"
                  :relative="false"
                >
                </GenericDatetimeDisplay>
              </span>
            </div>
            <div class="w-100">
              <span
                class="text-dark-75 font-size-sm font-weight-normal pt-1"
                v-if="clickedAction !== 'update'"
                >{{ reply.response }}</span
              >
            </div>
            <slot name="ideastorm-category-panel"></slot>
            <div
              class="w-100"
              v-if="clickedAction !== 'update' && attachments.length > 0"
            >
              <GenericThumbGallery :projectAssets="attachments" classStyle="sm">
              </GenericThumbGallery>
            </div>
            <div class="w-100">
              <div class="d-flex align-items-center">
                <b-overlay :show="loadingToggleRepliesList" rounded="sm">
                  <a
                    @click.once="toggleRepliesList(reply.id)"
                    class="btn btn-hover-text-primary btn-hover-icon-primary btn-sm font-size-xs btn-text-dark-50 bg-hover-gray-100 rounded font-weight-bolder p-2"
                    v-if="reply.totalRepliesCount > 0 && !showedMoreReplies"
                  >
                    <i class="fa fa-comment-alt text-success mr-2"></i
                    >{{ reply.totalRepliesCount }}
                    {{
                      reply.totalRepliesCount > 1
                        ? $t("RESPONSE_FORMS.LABELS.REPLIES")
                        : $t("RESPONSE_FORMS.LABELS.REPLY")
                    }}</a
                  >
                </b-overlay>

                <b-overlay v-if="type != 'surveyResponse'" :show="loadingToggleHeartSentiments" rounded="sm">
                  <a
                    href="javascript:void(0)"
                    @click="toggleHeartSentiments()"
                    class="btn btn-icon-danger btn-text-dark-50 bg-hover-light-danger btn-hover-text-danger rounded font-weight-bolder btn-sm font-size-xs p-2"
                    v-if="type !== 'ideastormResponse'"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-danger pr-1">
                      <i
                        :class="
                          (likedId == -1 ? 'far fa-heart' : 'fa fa-heart') +
                          ' text-danger'
                        "
                      ></i></span
                    >{{ sentimentCounts }}</a
                  >
                </b-overlay>
              </div>
            </div>
          </div>

          <div v-if="hovering" class="d-flex w-100 position-absolute flex-row-reverse">
            <a
              v-if="editable"
              href="javascript:void(0)"
              class="btn btn-icon btn-secondary btn-hover-primary btn-sm font-size-xs mx-1"
              v-b-tooltip.hover.bottom
              :title="$t('RESPONSE_FORMS.BUTTON.EDIT')"
              @click="toggleMessageBox('update')"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="media/svg/icons/Design/Edit.svg" />
              </span>
            </a>
            <a
              v-if="!['diaryResponse','surveyResponse'].includes(type) && currentUser.relation_to_project != 'observer'"
              href="javascript:void(0)"
              class="btn btn-icon btn-secondary btn-hover-success btn-sm font-size-xs mx-1"
              v-b-tooltip.hover.bottom
              :title="$t('RESPONSE_FORMS.BUTTON.COMMENT')"
              @click="toggleMessageBox('post-comment')"
            >
              <span class="svg-icon svg-icon-md svg-icon-success">
                <inline-svg
                  src="media/svg/icons/Communication/Group-chat.svg"
                />
              </span>
            </a>

            <a
              href="javascript:void(0)"
              v-if="ownReplies && currentUser.relation_to_project != 'observer'"
              class="btn btn-icon btn-secondary btn-hover-danger btn-sm font-size-xs mx-1"
              v-b-tooltip.hover.bottom
              v-b-modal="
                type
                  ? `delete-response-${responseId}`
                  : `delete-comment-${responseId}`
              "
              :title="$t('RESPONSE_FORMS.BUTTON.DELETE')"
            >
              <span class="svg-icon svg-icon-md svg-icon-danger">
                <inline-svg src="media/svg/icons/Home/Trash.svg" />
              </span>
            </a>
          </div>
        </div>

        <slot name="questions-response-panel" v-if="!isMobile"></slot>

        <!--begin::Message input Box-->
        <GenericResponseBox
          :dataMessageText="reply.response"
          :dataTitle="reply.title"
          :dataRespondingTo="responseId"
          :mode="clickedAction"
          :responseType="baseResponseOrResponseReply()"
          v-show="showNewMessageBox"
          :dataProjectAssets="clickedAction == 'update' ? attachments : null"
          :activity="computedActivity"
          @cancelAction="toggleMessageBox(false)"
          @refreshList="refreshList"
        ></GenericResponseBox>
        <!--end::Message input Box-->

        <!--begin::Confirm Delete Modal Box-->
        <b-modal
          :id="
            type
              ? `delete-response-${responseId}`
              : `delete-comment-${responseId}`
          "
          modal-class="dark-bg"
          centered
          :title="$t('RESPONSE_FORMS.DELETE_BOX.TITLE')"
          :ok-title="$t('RESPONSE_FORMS.DELETE_BOX.YES')"
          :cancel-title="$t('RESPONSE_FORMS.DELETE_BOX.NO')"
          @ok="type ? deleteResponse(responseId) : deleteComment(responseId)"
        >
          <p class="my-4">{{ $t("RESPONSE_FORMS.DELETE_BOX.MESSAGE_TEXT") }}</p>
        </b-modal>
        <!--end::Confirm Delete Modal Box-->
        <!--end::Info-->
      </div>
      <!--end::Info-->
    </div>
    <!--end::Response Div-->

    <slot name="questions-response-panel" v-if="isMobile"></slot>
    <!--begin::Inner Response Lists-->
    <div v-if="innerReplies.length > 0">
      <div
        v-for="(innerReply, innnerReplyIndex) in innerReplies"
        v-bind:key="innnerReplyIndex"
        :class="computedMargin"
      >
        <slot name="margin-inner-replies"></slot>

        <Response
          :layer="innnerReplyIndex"
          :reply="innerReply"
          :targetResponse="targetResponse"
          :activity="computedActivity"
          @deleteComment="deleteComment($event)"
          @refreshList="refreshList()"
        ></Response>
      </div>

      <div
        v-if="reply.totalRepliesCount > innerReplies.length"
        :class="computedMargin"
        class="pl-20 d-flex"
      >
        <b-overlay :show="loadingMoreRepliesList" rounded="sm">
          <a
            @click="loadMoreRepliesList()"
            class="btn btn-hover-text-primary btn-hover-icon-primary btn-sm font-size-xs btn-text-dark-50 bg-hover-gray-100 rounded font-weight-bolder p-2"
          >
            <i class="fa fa-reply text-success mr-2 fa-rotate-180"></i
            >{{ $t("RESPONSE_FORMS.LABELS.LOAD_MORE_REPLIES") }}</a
          >
        </b-overlay>
      </div>
    </div>
    <!--end::Inner Response Lists-->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay";
import UserThumbnail from "@/modules/together-helpers/components/generic-displays/GenericUserThumbnail";
import Response from "./Response.vue";
import GenericResponseBox from "../components/generic-inputs/GenericResponseBox";
import GenericThumbGallery from "../components/generic-inputs/generic_integrated_dropzone/GenericThumbGallery";

import {
  POPULATE_ACTIVITY_REPLY_ENGAGEMENTS_LIST,
  LOAD_MORE_ACTIVITY_REPLY_ENGAGEMENTS_LIST,
} from "../vuex/activity/engagement/activity_reply_engagements.module";

import {
  CREATE_SENTIMENT_ENGAGEMENTS,
  DELETE_SENTIMENT_ENGAGEMENTS,
} from "../vuex/activity/engagement/activity_sentiment_engagements.module";

export default {
  name: "Response",
  components: {
    Response,
    GenericResponseBox,
    GenericThumbGallery,
    GenericDatetimeDisplay,
    UserThumbnail,
  },
  props: {
    activity: { type: Object, required: false },
    layer: { type: Number, required: false },
    reply: { type: Object, required: true },
    type: { type: String, required: false },
    targetResponse: { type: String, required: false, default: null },
  },

  data() {
    return {
      clickedAction: "",
      error: true,
      hovering: false,
      innerReplies: [],
      loadingToggleRepliesList: false,
      loadingToggleHeartSentiments: false,
      loadingMoreRepliesList: false,
      likedId: -1,
      page: 1,
      page_length: 5,
      sentimentCounts: 0,
      showNewMessageBox: false,
      showConfimDelete: false,
      showedMoreReplies: false,
    };
  },

  watch: {
    // this handler is specifically used for watching increment or decrement in innerReplies
    activityReplyEngagementsList: {
      handler(val) {
        let arr = this._.find(val, {
          response_id: this.responseId,
        });
        if (arr) {
          if (this.reply.totalRepliesCount > this.innerReplies.length) {
            this.innerReplies = arr.list;
          }
        }
      },
      deep: true,
    },
    // this handler is specifically used for deleting any response without re-appending their innerReplies to the response that replaces it
    responseId: {
      handler(val) {
        let arr = this._.find(this.activityReplyEngagementsList, {
          response_id: val,
        });
        if (arr) {
          if (this.reply.totalRepliesCount > this.innerReplies.length) {
            this.innerReplies = arr.list;
          }
        }
      },
      deep: true,
    },
    // this handler is specifically used for watching increment or decrement in sentiments
    reply: {
      handler() {
        this.mountSentiments();
      },
      deep: true,
    },
  },

  mounted() {
    this.mountSentiments();
    if (this.targetResponse) {
      this.toggleRepliesList();
    }
  },

  methods: {
    toggleMessageBox(action) {
      this.showNewMessageBox = !this.showNewMessageBox;
      if (!this.showNewMessageBox) {
        this.clickedAction = "";
      } else {
        this.clickedAction = action;
      }
    },
    toggleDeleteMessage() {
      this.showConfimDelete = !this.showConfimDelete;
    },
    mountSentiments() {
      let sentiment = -1;
      if (this.type !== "ideastormResponse") {
        sentiment = this.reply.sentiments.find(
          (sentiments) => sentiments.user_id == this.currentUser.id
        );
        this.sentimentCounts = this.reply.sentiments.length;
        this.likedId = sentiment ? sentiment.id : -1;
      }
    },
    loadMoreRepliesList() {
      this.loadingMoreRepliesList = true;
      let payload = {
        activityId: this.activityInfo.id,
        responseId: this.responseId,
        params: {
          repliable_type: this.repliable_type,
          repliable_id: this.responseId,
          replies: "with",
          attachment: "with",
          created_at: "ASC",
          paginate: true,
          page: this.page + 1,
          pageLength: this.page_length,
        },
      };
      this.$store
        .dispatch(LOAD_MORE_ACTIVITY_REPLY_ENGAGEMENTS_LIST, payload)
        .then(() => {
          this.page++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity list, please check back later or contact the helpdesk";
        })
        .finally(() => {
          this.loadingMoreRepliesList = false;
        });
    },
    toggleRepliesList() {
      // set overlay to toggle button
      this.loadingToggleRepliesList = true;
      let payload = {
        activityId: this.computedActivity.id,
        responseId: this.responseId,
        params: {
          repliable_type: this.repliable_type,
          repliable_id: this.responseId,
          replies: "with",
          attachment: "with",
          created_at: "ASC",
          paginate: true,
          page: this.page,
          pageLength: this.page_length,
        },
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_REPLY_ENGAGEMENTS_LIST, payload)
        .then(() => {
          this.loadingToggleRepliesList = false;
          this.showedMoreReplies = true;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity list, please check back later or contact the helpdesk";
        });
    },
    toggleHeartSentiments() {
      this.loadingToggleHeartSentiments = true;
      if (this.likedId !== -1) {
        let payload = {
          activityId: this.computedActivity.id,
          engagementId: this.likedId,
        };
        this.$store.dispatch(DELETE_SENTIMENT_ENGAGEMENTS, payload).then(() => {
          this.loadingToggleHeartSentiments = false;
          this.likedId = -1;
          this.sentimentCounts--;
        });
      } else {
        let payload = {
          activityId: this.computedActivity.id,
          responseId: this.responseId,
          data: {
            sentiment: 1,
            sentimentable_type: this.repliable_type,
            sentimentable_id: this.responseId,
          },
        };
        this.$store
          .dispatch(CREATE_SENTIMENT_ENGAGEMENTS, payload)
          .then((res) => {
            this.loadingToggleHeartSentiments = false;
            this.likedId = res.id;
            this.sentimentCounts++;
          })
          .catch(() => {
            this.loadingToggleHeartSentiments = false;
            this.error.active = true;
            this.error.message =
              "We're having some issues retrieving the activity list, please check back later or contact the helpdesk";
          });
      }

      return false;
    },
    deleteResponse(id) {
      let payload = {
        activity_id: this.computedActivity.id, // required
        activity_response_id: id,
      };
      this.$emit("deleteResponse", payload);
    },
    deleteComment(id) {
      this.$emit("deleteComment", id);
    },
    refreshList() {
      this.$emit("refreshList");
    },

    baseResponseOrResponseReply() {
      let result = "";
      switch (this.type) {
        case undefined:
        case "pictureBookResponse":
          result = "ResponseReply";
          break;

        default:
          result = "BaseResponse";
          break;
      }
      return result;
    },
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "projectInfo",
      "activityInfo",
      "activityReplyEngagementsList",
    ]),
    responseId: function () {
      return this.reply.id;
    },
    repliable_type: function () {
      let result = "";
      switch (this.type) {
        case undefined:
        case "pictureBookResponse":
          result = "ActivityResponseReply";
          break;

        default:
          // blog & discussion
          result =
            "Activity" +
            this._.replace(
              this._.capitalize(this.type),
              "response",
              "Response"
            );
          break;
      }
      return result;
    },
    attachments: function () {
      let arr = this.reply.attachment;
      var pulled = this._.map(arr, "projectAsset");
      return pulled;
    },
    ownReplies: function () {
      return this.reply.user.id == this.currentUser.id;
    },
    editable: function () {
      let editableType = !this._.includes(
        ["diaryResponse", "ideastormResponse", "surveyResponse"],
        this.type
      );
      return this.ownReplies && editableType;
    },
    computedMargin: function () {
      let margin = "ml-10";
      if (this.type == "ideastormResponse") {
        margin = "ml-20";
      }
      return margin;
    },
    computedActivity: function () {
      if (this.activity != undefined) {
        return this.activity;
      } else {
        return this.activityInfo;
      }
    },
    isMobile: function () {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.bg-hover-gray-100 {
  cursor: default;
}
</style>
