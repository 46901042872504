<template>
  <transition name="slide-fade">
    <b-overlay :show="!loadingComplete" rounded="sm">
      <b-card bg-variant="gray-200" border-variant="Secondary" class="mt-5">
        <!--begin::Response Title-->
        <GenericGroup
          v-if="requiredTitle"
          id="title"
          :model="$v.form.title"
          :label="computedTitleInputs.label"
          :showInline="false"
          :submissionStates="submissionStates"
          :error="computedTitleInputs.error"
        ></GenericGroup>
        <!--end::Response Title-->

        <!--begin::Response IdeamStorm Categories CheckBox-->
        <GenericCheckboxGroup
          v-if="hasIdeastormCategories"
          id="ideastorm_categories"
          class="my-5"
          :model="$v.form.ideastorm_categories"
          :options="ideastormCategories"
          :label="$t('RESPONSE_FORMS.INPUTS.CATEGORIES')"
          :showInline="true"
          :submissionStates="submissionStates"
          :error="$t('RESPONSE_FORMS.ERROR.CATEGORIES')"
        ></GenericCheckboxGroup>
        <!--end::Response IdeamStorm Categories CheckBox-->

        <!--begin::Response Message-->
        <GenericTextArea
          v-if="requiredResponse"
          id="response"
          :label="computedResponseInputs.label"
          :model="$v.form.response"
          :prepResponse="prepResponse"
          :submissionStates="submissionStates"
          :error="computedResponseInputs.error"
        >
        </GenericTextArea>
        <!--end::Response Message-->

        <!--Begin: Drop Zone ---->
        <IntegratedDropzone
          v-if="requiredMedia"
          class="col-xl-12 px-0"
          :assetReservation="calculatedAssetReservationData"
          :idx="1"
          :projectAssets="dataProjectAssets"
          :wholeWidth="true"
          :removable="true"
          :canAddCaption="attachmentCanAddCaption"
          classStyle="sm"
          :inlineSR="inlineSR"
          :element="{ previews: [] }"
          ref="dropZone"
          @asset_reservation_created="createAttachments($event)"
          @attachment_caption_changed="updateAttachmentsCaption($event)"
          @asset_reservation_deleted="removeAttachments($event)"
          @remove_project_asset="form.attachments_remove.push($event)"
        >
        </IntegratedDropzone>
        <!--End: Drop Zone ---->

        <!--Begin: Action Button ---->
        <div class="mt-10 d-flex justify-content-between">
          <button class="btn btn-white" @click="actionReturn('cancel')">
            {{ $t("RESPONSE_FORMS.BUTTON.CANCEL") }}
          </button>
          <button class="btn btn-primary" @click="handleMessage">
            {{ actionButtonText }}
          </button>
        </div>
        <!--End: Action Button ---->
      </b-card>
    </b-overlay>
  </transition>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

import Swal from "sweetalert2";
import GenericTextArea from "../generic-inputs/GenericTextArea";
import GenericCheckboxGroup from "../generic-inputs/GenericCheckboxGroup";
import GenericGroup from "../generic-inputs/GenericInputGroup";
import IntegratedDropzone from "@/modules/together-helpers/components/generic-inputs/GenericIntegratedDropzone";

import {
  CREATE_BLOG_RESPONSES,
  UPDATE_BLOG_RESPONSES
} from "../../vuex/activity/response/activity_blog_responses.module";

import {
  CREATE_DISCUSSION_RESPONSES,
  UPDATE_DISCUSSION_RESPONSES
} from "../../vuex/activity/response/activity_discussion_responses.module";

import { CREATE_PICTURE_BOOK_RESPONSES } from "../../vuex/activity/response/activity_picture_book_responses.module";

import {
  CREATE_IDEASTORM_RESPONSES,
  UPDATE_IDEASTORM_RESPONSES
} from "../../vuex/activity/response/activity_ideastorm_responses.module";

import { CREATE_EXTERNAL_RESPONSES } from "../../vuex/activity/response/activity_external_responses.module";

import {
  CREATE_REPLY_ENGAGEMENTS,
  UPDATE_REPLY_ENGAGEMENTS
} from "../../vuex/activity/engagement/activity_reply_engagements.module";

export default {
  name: "GenericResponseBox",

  props: {
    activity: { type: Object, required: false },
    prepResponse: { type: String, required: false },
    ideastormCategories: { type: Array, required: false },
    mode: { type: String, required: true },
    responseType: { type: String, required: false },
    dataMessageText: { type: String, required: false },
    dataTitle: { type: String, required: false },
    dataRespondingTo: { type: Number, required: false },
    dataProjectAssets: { type: Array, required: false },
    overrideAssetReservationData: { type: Object, required: false },
    inlineSR: { type: Boolean, required: false, default: false },
    reqBlogTitle: { type: Boolean, required: false, default: false }
  },

  components: {
    IntegratedDropzone,
    GenericTextArea,
    GenericCheckboxGroup,
    GenericGroup
  },

  data: function() {
    return {
      form: {
        title: "",
        response: "",
        ideastorm_categories: [],
        respondingTo: false,
        attachments_remove: [],
        captions: [],
        attachments: []
      },

      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 0
      },
      submissionStates: {
        submitting: false
      }
    };
  },

  validations: {
    form: {
      title: {
        required: requiredIf(function() {
          return this.requiredTitle;
        })
      },
      response: {
        required: requiredIf(function() {
          return this.requiredResponse;
        })
      },
      ideastorm_categories: {},

      respondingTo: {
        required: requiredIf(function() {
          return this.mode == "post-comment";
        })
      }
    }
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    createAttachments($event) {
      this.form.attachments.push($event);
    },

    updateAttachmentsCaption($event) {
      let existingCaptions = this.form.captions.findIndex(
        p => p.asset_reservation_id == $event.asset_reservation_id
      );
      if (existingCaptions !== -1) {
        this.form.captions[existingCaptions] = $event;
      } else {
        this.form.captions.push($event);
      }
    },

    removeAttachments($event) {
      this.form.attachments = this.form.attachments.filter(function(stim) {
        return stim !== $event;
      });
      this.form.captions = this.form.captions.filter(function(stim) {
        return stim.asset_reservation_id !== $event;
      });

      // update caption value using refs
      if (this.attachmentCanAddCaption) {
        let Refs = this.$refs.dropZone.$refs.thumbGallery.$refs;
        this._.forOwn(Refs, (component, refName) => {
          if (refName.includes("thumbFile-")) {
            const id = this._.trimStart(refName, "thumbFile-");
            let existingCaption = this.form.captions.find(
              p => p.asset_reservation_id == id
            );
            if (existingCaption !== undefined && component[0] !== undefined) {
              this.$nextTick(() => {
                component[0].caption = existingCaption.caption;
              });
            }
          }
        });
      }
    },

    preparePayload() {
      let data = {
        project_profile_id: this.currentUser.project_profiles.id, // required
        user_id: this.currentUser.id, // required
        response: this.form.response,
        attachments: this.form.attachments,
        captions: this.form.captions,
        attachments_remove: this.form.attachments_remove,
        title: this.form.title
      };
      if (this.mode == "post-comment" || this.responseType == "ResponseReply") {
        data.repliable_type = this.repliable_type;
        data.repliable_id = this.dataRespondingTo;
      }
      if (this.form.ideastorm_categories) {
        data.ideastorm_categories = this.form.ideastorm_categories;
      }

      let payload = {
        activityId: this.calculatedActivity.id, // required
        data: data
      };
      if (this.mode == "update") {
        payload.responseId = this.dataRespondingTo;
      }
      return payload;
    },

    handleMessage() {
      this.pageLoader.componentsRequired++;
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        this.pageLoader.componentsCompleted++;
        return;
      }

      let payload = this.preparePayload();
      this.submissionStates.submitting = true;
      this.$store
        .dispatch(this.moduleActionName, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;

          let sweetAlertMsg = "";

          if (this.postingReply || this.isParticipant) {
            let type = this.activityTypeId;
            sweetAlertMsg = this.$t("RESPONSE_FORMS.ADD_SUCCESS." + type);
          }

          if (!this.postingReply && !this.isParticipant) {
            sweetAlertMsg = this.$t("RESPONSE_FORMS.ADD_SUCCESS.TEST_ACTIVITY");
          }

          Swal.fire({
            title: "",
            text: sweetAlertMsg,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            allowOutsideClick: false
          }).then(() => {
            if (this.activityType == "external") {
              this.actionReturn("redirectExternalActivity");
            } else {
              this.actionReturn("success");
            }
          });
        })
        .catch(msg => {
          this.pageLoader.componentsCompleted++;
          this.$log.debug(msg);
          this.$toasted.global.error_toast({
            message: msg.errors
          });
        });
    },
    resetInput() {
      this.submissionStates.submitting = false;
      this.$v.$reset();
      this.form.title = "";
      this.form.response = "";
      this.form.attachments = [];
      this.form.attachments_remove = [];
      this.form.captions = [];
      this.form.ideastorm_categories = [];
      this.form.respondingTo = false;
    },
    actionReturn(result) {
      switch (result) {
        case "success":
          this.$emit("cancelAction");
          this.$emit("refreshList");
          break;

        case "redirectExternalActivity":
          this.$emit("cancelAction");
          this.$emit("postExternalResponse");
          break;

        case "cancel":
          this.$emit("cancelAction");
          break;

        default:
          break;
      }

      this.resetInput();
    }
  },

  watch: {
    mode: {
      handler(val) {
        this.resetInput();
        if (val == "update") {
          this.form.title = this.dataTitle;
          this.form.response = this.dataMessageText;
          this.form.respondingTo = this.dataRespondingTo;
        } else if (val == "post-comment") {
          this.form.respondingTo = this.dataRespondingTo;
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "projectInfo",
      "activityInfo",
      "newActivityResponseInfo"
    ]),

    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    },
    requiredTitle() {
      const type = !(
        this.activityType == "discussion" ||
        this.activityType == "picture book" ||
        this.activityType == "external"
      );
      const mode = this.mode == "post";
      const reqBlogTitle = this.reqBlogTitle;
      return type && mode && reqBlogTitle;
    },

    computedTitleInputs() {
      if (this.requiredTitle) {
        let label = this.$t("RESPONSE_FORMS.INPUTS.TITLE");
        let error = this.$t("RESPONSE_FORMS.ERROR.TITLE");
        if (this.requiredIdeamstormCategories) {
          label = this.$t("RESPONSE_FORMS.INPUTS.IDEASTORM_TITLE");
        }
        return { label: label, error: error };
      } else {
        return false;
      }
    },

    computedResponseInputs() {
      if (this.requiredResponse) {
        let label = this.$t("RESPONSE_FORMS.INPUTS.MESSAGE_TEXT");
        let error = this.$t("RESPONSE_FORMS.ERROR.MESSAGE_TEXT");
        if (this.requiredIdeamstormCategories) {
          label = this.$t("RESPONSE_FORMS.INPUTS.IDEASTORM_RESPONSE");
        }
        return { label: label, error: error };
      } else {
        return false;
      }
    },

    requiredResponse: function() {
      const type = !(
        (this.activityType == "picture book" ||
          this.activityType == "external") &&
        this.mode != "post-comment"
      );

      return type;
    },

    requiredIdeamstormCategories: function() {
      const type = this.activityType == "ideastorm" && this.mode == "post";

      return type;
    },

    requiredMedia: function() {
      const type = !(
        this.activityType == "picture book" && this.mode != "post"
      );

      return type;
    },

    activityType: function() {
      return this._.toLower(this.calculatedActivity.activity_type.name);
    },
    activityTypeId: function() {
      return this.calculatedActivity.activity_type.id;
    },

    assetReservationData: function() {
      return {
        scope: "activity",
        data: {
          directory: "attachments",
          projectId: this.projectInfo.id,
          activityId: this.calculatedActivity.id
        }
      };
    },

    actionButtonText() {
      let text = "";
      switch (this.mode) {
        case "post":
          switch (this.activityType) {
            case "blog":
              text = "RESPONSE_FORMS.BUTTON.POST";
              break;
            case "diary":
              text = "RESPONSE_FORMS.BUTTON.POST";
              break;
            case "discussion":
              text = "RESPONSE_FORMS.BUTTON.REPLY_DISCUSSION";
              break;
            case "external":
              text = this.calculatedActivity.recording_override
                ? "RESPONSE_FORMS.BUTTON.POST"
                : "RESPONSE_FORMS.BUTTON.PROCEED_LINK";
              break;
            case "ideastorm":
              text = "RESPONSE_FORMS.BUTTON.ADD_IDEA";
              break;
            case "picture book":
              text = "RESPONSE_FORMS.BUTTON.POST";
              break;
          }
          break;
        case "update":
          text = "RESPONSE_FORMS.BUTTON.EDIT";
          break;
        case "post-comment":
          switch (this.activityType) {
            case "blog":
              text = "RESPONSE_FORMS.BUTTON.REPLY";
              break;
            case "diary":
              text = "RESPONSE_FORMS.BUTTON.REPLY";
              break;
            case "discussion":
              text = "RESPONSE_FORMS.BUTTON.DISCUSSION_COMMENT";
              break;
            case "ideastorm":
              text = "RESPONSE_FORMS.BUTTON.POST";
              break;
            case "picture book":
              text = "RESPONSE_FORMS.BUTTON.REPLY";
          }
          break;
        default:
          text = "RESPONSE_FORMS.BUTTON.POST";
          break;
      }
      return `${this.$t(text)}`;
    },

    moduleActionName() {
      let action = "";
      switch (this.mode) {
        case "post":
          switch (this.activityType) {
            case "blog":
              action = CREATE_BLOG_RESPONSES;
              break;
            case "discussion":
              action = CREATE_DISCUSSION_RESPONSES;
              break;
            case "ideastorm":
              action = CREATE_IDEASTORM_RESPONSES;
              break;
            case "external":
              action = CREATE_EXTERNAL_RESPONSES;
              break;
            case "picture book":
              action = CREATE_PICTURE_BOOK_RESPONSES;
              break;
          }
          break;
        case "update":
          if (this.responseType == "BaseResponse") {
            switch (this.activityType) {
              case "blog":
                action = UPDATE_BLOG_RESPONSES;
                break;
              // case "Diary":
              //   action = UPDATE_DIARY_RESPONSES;
              //   break;
              case "discussion":
                action = UPDATE_DISCUSSION_RESPONSES;
                break;
              case "ideastorm":
                action = UPDATE_IDEASTORM_RESPONSES;
                break;
            }
          } else if (this.responseType == "ResponseReply") {
            action = UPDATE_REPLY_ENGAGEMENTS;
          }
          break;
        case "post-comment":
          action = CREATE_REPLY_ENGAGEMENTS;
          break;
      }
      return action;
    },

    repliable_type() {
      let type = null;
      if (this.responseType == "ResponseReply") {
        type = "ActivityResponseReply";
      } else {
        switch (this.activityType) {
          case "blog":
            type = "ActivityBlogResponse";
            break;
          case "diary":
            type = "ActivityDiaryResponse";
            break;
          case "discussion":
            type = "ActivityDiscussionResponse";
            break;
          case "ideastorm":
            type = "ActivityIdeastormResponse";
            break;
          case "picture book":
            type = "ActivityPictureBookResponse";
            break;
        }
      }
      return type;
    },

    attachmentCanAddCaption() {
      const type = this.activityType == "picture book";
      const mode = this.mode == "post";
      return type && mode;
    },
    calculatedAssetReservationData: function() {
      if (this.overrideAssetReservationData != undefined) {
        return this.overrideAssetReservationData;
      } else {
        return this.assetReservationData;
      }
    },

    calculatedActivity: function() {
      if (this.activity != undefined) {
        return this.activity;
      } else {
        return this.activityInfo;
      }
    },

    postingReply: function() {
      if (this.mode == "post-comment" || this.responseType == "ResponseReply") {
        return true;
      } else {
        return false;
      }
    },

    isParticipant: function() {
      if (this.currentUser.relation_to_project == "participant") {
        return true;
      } else {
        return false;
      }
    },

    hasIdeastormCategories: function() {
      if (this.activityType == "ideastorm" && this.ideastormCategories) {
        return this.ideastormCategories.length > 0 ? true : false;
      } else {
        return false;
      }
    }
  }
};
</script>
