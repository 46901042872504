<template>
  <div>
    <GeneralDisplay v-if="loadingComplete" :activityInfo="activityInfo">
      <div slot="activity-panel">
        <div class="py-10" v-if="supportedScreenRecord">
          <ol class="ml-7">
            <li v-show="isFirefox">{{ $t('ACTIVITY.SCREEN_RECORD.INSTRUCT_LINE_0') }}</li>
            <li>{{ $t('ACTIVITY.SCREEN_RECORD.INSTRUCT_LINE_1') }}</li>
            <li>{{ $t('ACTIVITY.SCREEN_RECORD.INSTRUCT_LINE_2') }}</li>
            <li>{{ $t('ACTIVITY.SCREEN_RECORD.INSTRUCT_LINE_3') }}</li>
            <li>{{ $t('ACTIVITY.SCREEN_RECORD.INSTRUCT_LINE_4') }}</li>
            <li>{{ $t('ACTIVITY.SCREEN_RECORD.INSTRUCT_LINE_5') }}</li>
          </ol>
        </div>

        <!--begin::Message input Box-->
        <InputGenericResponseBox
          mode="post"
          v-if="
            supportedScreenRecord == true && externalOpened && !completedMarkUp
          "
          :activity="activityInfo"
          @postExternalResponse="responses_created"
          :overrideAssetReservationData="assetReservationData"
          :inlineSR="true"
        ></InputGenericResponseBox>
        <!--end::Message input Box-->

        <div v-else class="d-flex justify-content-center">
          <b-button variant="primary" @click="redirectLink">{{
            completedMarkUp ? "Proceed to Link" : beginActivityText
          }}</b-button>
        </div>
      </div>
    </GeneralDisplay>

    <!--begin::Loading Spinner-->
    <div class="row" v-else>
      <div class="col-xxl-12 min-vh-100">
        <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
          <div class="card-body my-4 pd-12">
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                class="main-card-loader"
                label="Loading..."
              ></b-spinner>
            </div>
          </div>
        </div>
      </div>
      <!--end::Loading Spinner-->
    </div>

    <!--begin::External Modal Box-->
    <b-modal
      modal-class="dark-bg"
      dialog-class="fill-height"
      :id="'external-activity-' + activityInfo.id"
      :title="activityInfo.title"
      hide-footer
      size="xl"
      @close="closeModal()"
      centered
    >
      <iframe
        :src="externalLink"
        frameborder="0"
        height="100%"
        width="100%"
        allow="autoplay; encrypted-media"
        allowfullscreen
      ></iframe>
    </b-modal>
    <!--end::External Modal Box-->
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { POPULATE_ACTIVITY_INFO } from "@/core/services/store/activity/activity.module";
import GeneralDisplay from "@/view/components/activity/GeneralDisplay.vue";
import SystemIconHelper from "@/modules/together-helpers/helpers/SystemIconHelper";
import SystemLabelHelper from "@/modules/together-helpers/helpers/SystemLabelHelper";
import GeneralFunctionsHelper from "@/helpers/GeneralFunctions";
import InputGenericResponseBox from "@/modules/together-helpers/components/generic-inputs/GenericResponseBox";

export default {
  name: "ExternalActivity",

  components: {
    GeneralDisplay,
    InputGenericResponseBox
  },

  data() {
    return {
      modalShow: false,
      completedMarkUp: false,
      page: 1,
      maxX: 0,
      maxY: 0,
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1
      },
      no_response_role: false,
      error: {
        active: false,
        message: ""
      },
      externalOpened: false
    };
  },

  mounted() {
    this.cleanup();
    this.fetchActivityInfo();
  },

  watch: {
    activityId: function() {
      this.pageLoader.componentsCompleted = 0;
      this.pageLoader.componentsRequired = 1;
      this.fetchActivityInfo();
    },
    no_response_role: function(val) {
      if (val == true) {
        this.$root.$bvToast.toast(
          `${this.$t("TOASTS.ACTIVITY.ROLE_NOT_ELIGIBLE_TO_RESPONSE_MSG")}`,
          {
            title: `${this.$t(
              "TOASTS.ACTIVITY.ROLE_NOT_ELIGIBLE_TO_RESPONSE_TITLE"
            )}`,
            variant: "warning",
            noAutoHide: true
          }
        );
      }
    }
  },
  methods: {
    responses_created() {
      this.fetchActivityInfo();
    },
    fetchActivityInfo() {
      let payload = {
        projectId: this.projectInfo.id,
        activityId: this.activityId,
        params: {
          paginate: false,
          activityType: "with",
          activityStage: "with",
          activitySettings: "with",
          activityExternal: "with",
          stimulusAssets: "with",
          appendSignedUrls: "1"
        }
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_INFO, payload)
        .then(res => {
          if (res.responseStatus == "completed") {
            this.completedMarkUp = true;
          } else {
            if (res.responseStatus !== "eligible") {
              this.no_response_role = true;
            }
          }
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity information, please check back later or contact the helpdesk";
        });
    },
    redirectLink() {
      this.externalOpened = true;
      if (this.isExt || this.supportedScreenRecord) {
        window.open(this.externalLink, "_blank");
        this.fetchActivityInfo();
      } else if (this.isExtIframe) {
        this.$bvModal.show("external-activity-" + this.activityInfo.id);
        this.fetchActivityInfo();
      }
    },
    activityIcon(activity) {
      return SystemIconHelper.activityType(
        activity.activity_type,
        activity.recording_override
      );
    },
    iconLabel(activity) {
      return SystemLabelHelper.activityType(
        activity.activity_type,
        activity.recording_override
      );
    },
    activityHeader(activityInfo) {
      return GeneralFunctionsHelper.activityHeader(
        this.activityIcon(activityInfo),
        activityInfo,
        this.$t(this.iconLabel(activityInfo))
      );
    },
    setError(msg) {
      this.$toasted.global.error_toast({
        message: msg
      });
      return;
    },
    closeModal() {
      this.fetchActivityInfo();
    },
    openExternal: function() {
      this.externalOpened = true;
      window.open(this.activityInfo.activity_external.link, "_blank");
    },
    cleanup: function() {
      this.setActivityInfo([]);
      this.resetActivityReplyEngagementsList([]);
    },
    ...mapMutations({
      setActivityInfo: "setActivityInfo",
      resetActivityReplyEngagementsList: "resetActivityReplyEngagementsList"
    })
  },
  computed: {
    ...mapGetters(["projectInfo", "currentUser", "activityInfo"]),
    activityId: function() {
      return this.$route.params.id;
    },
    activityType: function() {
      return this._.toLower(this.activityInfo.activity_type.name);
    },
    activityTypeId: function() {
      return this.activityInfo.activity_type.id;
    },
    externalLink: function() {
      return this.activityInfo.activity_external.user_curated_link;
    },
    supportedScreenRecord: function() {
      let setting = this._.find(this.activityInfo.activity_settings, { id: 6 });
      return setting ? (setting.pivot.value == 0 ? false : true) : undefined;
    },
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    },
    isExt: function() {
      if (this.activityInfo.activity_external) {
        let ext = this.activityInfo.activity_external;
        if (ext.popout) {
          return true;
        }
      }
      return false;
    },
    isExtIframe: function() {
      if (this.activityInfo.activity_external) {
        let ext = this.activityInfo.activity_external;
        if (!ext.popout) {
          return true;
        }
      }
      return false;
    },
    isMobile: function() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isFirefox: function() {
      if (typeof InstallTrigger !== 'undefined') {
        return true;
      } else {
        return false;
      }
    },
    assetReservationData: function() {
      let data = {
        scope: "activity",
        data: {
          directory: "attachments_videos",
          activityId: this.activityInfo.id,
          projectId: window.location.host.split(".")[0]
        }
      };

      return data;
    },
    beginActivityText: function() {
      return `${this.$t('ACTIVITY.SCREEN_RECORD.BUTTON_BEGIN')}`;
    }
  }
};
</script>
