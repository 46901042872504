//import ToastService from "./ToastService";

/**
 * @description service to submit forms
 */
class EngageService {
  element;
  displayErrorHandler;
  formErrorHandler;
  engageAction;

  /**
   * Components are a used to dynamically used to build the engagement, for example a blog activity may
   * have title, response, and media upload components. 
   * See this.Component for more information
   * 
   * @param Array<Component>
   */
  components = [];

  /**
   * The response object is used to contain the response values for each component, and is ultimately used
   * when submitting to the API.
   * Within the object the key is binded to the ID within a component, the value is the response value.
   * 
   * @param Object
   */
  response = {};

  /**
   * Response reset is used to store the state of the engagement response before any changes are made by the user.
   * This should be populated after the engagement has been built and before any user interaction using the 
   * this.buildEngagementReset()
   * 
   * @param Object
   */
  responseReset = {};

  /**
   * Interactions are used to store the current state of interacts made to a given response
   */
  interactions = {};

  /**
   * All engagement are dependent on an parent Activity, this is stored here and shared with child components
   * 
   * @param Activity
   */
  activity = null;

  /**
   * This map is used to determine which components are used to display a response based on 'system_answer_type_id'
   */
  typeComponentMap = {
    1: 'Open',
    2: 'Check',
    3: 'Radio',
    5: 'Ranking',
    8: 'FITB',
    9: 'Media'
  }

  /**
   * This map is used to determine a foreign key based on a 'system _activity_type_id'
   * 
   * @deprecating This will be deprecated by the new Engagement schema
   */
  choiceKeyMap = {
    2: 'diary_question_choice_id',
    4: 'survey_question_choice_id',
    9: 'poll_question_choice_id'
  }

  /**
   * This map is used to fetch the default response for a given 'system_activity_type_id'
   */
  typeDefaultValueMap = {
    1: {
      response: "",
      attachments: []
    },
    2: [],
    3: null,
    5: [],
    8: null,
    9: []
  }

  /**
   * Interact activity map
   */
  interactActivityMap = {
    1: "ActivityBlogResponse",
    2: "DiaryQuestionResponse",
    3: "ActivityDiscussionResponse",
    4: "SurveyQuestionResponse",
    5: "ActivityPictureBookResponse",
    6: "ActivityIdeastormResponse",
    7: "ActivityExternalResponse",
    8: "MarkupQuestionResponse",
    9: "PollQuestionResponse"
  }

  /**
   * The engage service is used to control both the displaying and submitting of user responses to activities
   * "components" are used to build the engagement UI and behaviour, and "response" is used to store user responses to each component
   * 
   * @param element The component where the engage service is initiated (this)
   * @param displayErrorHandler A mapped mutation that can be used to control what error is displayed if an engagement is unable to fetch data
   * @param formErrorHandler  A mappted mutation that can be used to control what error is displayed if an engagement cannot submit it's data
   * @param engageAction A mappaed Vuex action to determine what happens when an engagement is submitted.
   */
  constructor(
    element,
    displayErrorHandler = null,
    formErrorHandler = null,
    engageAction
  ) {
    this.element = element;
    this.displayErrorHandler = displayErrorHandler;
    this.formErrorHandler = formErrorHandler;
    this.engageAction = engageAction;

    this.clearErrors();
  };

  /**
   * Adds a component to this.components
   * 
   * @param  component the component to be added to this.components
   * @returns void
   */
  addComponent(component) {
    this.components.push(component);
  }

  /**
   * Adds or updates a response value
   * 
   * @param field This should be the "id" of a component
   * @param value The value to be added/updated
   * @returns void
   */
  setResponseValue(field, value) {
    let fieldArray = field.toString().split('.');

    if (fieldArray.length == 1) {
      this.response[fieldArray[0]] = value;
    } else if (fieldArray.length == 2) {
      this.response[fieldArray[0]][fieldArray[1]] = value;
    }
  }

  /**
   * Adds or updates interactions for a given response (identified by a reference)
   * 
   * @param interactions The interactions (sentiments tags, notes etc.)
   * @param reference Optionally this can be used to nest interactions under a given reference (for example question Ids)
   * @param morph Optionally the can be used to indicate we are just passing a subset of interactions (sentiments, notes, tags etc.)
   */
  setInteractionValue(interactions, reference = null, morph = null) {
    if (reference === null) {
      (morph === null) ? this.interactions = interactions : this.interactions[morph] = interactions;
    } else {
      if (morph === null) {
        this.interactions[reference] = interactions;
      } else {
        if (this.interactions[reference] === undefined) {
          this.interactions[reference] = {};
        }

        this.interactions[reference][morph] = interactions;
      }
    }
  }

  /**
   * Used to submit form data to the API.
   * If an engageAction is not passed into the constructor this will not be available and the submit button will be hidden
   * If a 422 error is returned the promise is reject
   * If a 200 is returned the promise is resolved
   *  - If no data is returned in the response we know validation passed but no data was stored (indicating a test submission)
   *  - Else the submission was real and should be stored by the component calling this method.
   * 
   * @returns Promise(resolve, reject)
   */
  submit() {
    return new Promise((resolve, reject) => {
      if (this.engageAction != null && this.activity != null) {
        let activityType = this.activity.activity_type.name.toLowerCase().replace(/ /g, '_');

        let payload = {
          activityId: this.activity.id,
          activityType: activityType,
          response: this.response
        };

        return this.engageAction(payload)
          .then((res) => {
            this.clearErrors();
            resolve(res);
          }).catch((error) => {
            if (this.formErrorHandler != null && this.activity != null && this.activity.system_activity_type_id == 10) {
              this.formErrorHandler(error.response.data);
            }
            reject(error);
          })
      } else {
        reject();
      }
    })
  }

  /**
   * Method used to store/update/delete an interaction with an engagement 
   */
  interact(interactPayload, typeResponse, reference = null) {
    return new Promise((resolve, reject) => {
      if (this.activity != null) {
        let payload = {
          activityId: this.activity.id,
          scope: interactPayload.scope,
          engagementId: interactPayload.engagementId,
          engagement: {
            ...interactPayload.engagement,
            ...{
              [`${interactPayload.morph}_type`]: this.interactActivityMap[this.activity.system_activity_type_id],
              [`${interactPayload.morph}_id`]: typeResponse.id
            }
          }
        }

        interactPayload.action(payload).then((res) => {
          if (res == undefined) {
            let currentInteractions = (reference === null) ? this.interactions[interactPayload.type] : this.interactions[reference][interactPayload.type];
            let newInteractions = currentInteractions.filter(function(interaction) {
              return interaction.id != interactPayload.engagementId
            });
            this.setInteractionValue(newInteractions, reference, interactPayload.type);
          } else {
            (reference === null) ? this.interactions[interactPayload.type].push(res) : this.interactions[reference][interactPayload.type].push(res);
          }
        })
      }
    })
  }

  /**
   * Used to store the initial value of the response fields
   */
  buildEngagementReset() {
    this.responseReset = JSON.parse(JSON.stringify(this.response));
  }

  /**
   * Used to reset the response fields back to their original state
   */
  resetEngagement() {
    this.response = JSON.parse(JSON.stringify(this.responseReset));
  }

  setActivity(activity) {
    this.activity = activity;
  }

  /**
   * Resets display and form errors back to their original state
   */
  clearErrors() {
    if (this.displayErrorHandler != null) {
      this.displayErrorHandler();
    }

    if (this.formErrorHandler != null) {
      this.formErrorHandler();
    }
  }
}

export default EngageService;