<template>
  <!--begin::Response Lists-->
  <div>
    <div
      class="mt-10"
      v-for="(responseByDate, date) in computedDiaryResponse"
      v-bind:key="date"
    >
      <div class="card-title">
        <div class="card-label">
          <div class="font-weight-bolder">{{ date }}</div>
        </div>
      </div>
      <template v-for="(reply, index) in responseByDate">
        <Response
          :layer="index"
          :reply="reply"
          v-bind:key="index"
          :ref="`response-${reply.id}`"
          type="diaryResponse"
          @deleteResponse="deleteResponse($event)"
          @deleteComment="deleteComment($event)"
        >
          <div slot="questions-response-panel">
            <div class="accordion" role="tablist">
              <b-card-body class="px-0 py-0">
                <!--begin::Diary Form Questions List Panel-->
                <StreamSurvey
                  :response="reply"
                  :questions="diaryQuestionList"
                  :activityId="activityId"
                  :baseResponse="reply.id"
                  domain="input"
                  activityType="diary"
                  @open-chart-modal="$emit('open-chart-modal',$event)"
                  @toggleNotepad="$emit('toggleNotepad',$event)"
                ></StreamSurvey>
                <!--end::Diary Form Questions List Panel-->
              </b-card-body>
            </div>
          </div>
        </Response>
      </template>
    </div>
  </div>

  <!--end::Response Lists-->
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import Response from "@/modules/together-helpers/components/Response";
import { DELETE_DIARY_RESPONSES } from "@/core/services/store/activity/activity_diary_responses.module.js";
import { DELETE_REPLY_ENGAGEMENTS } from "@/core/services/store/activity/activity_reply_engagements.module";
import ActivityFunctionsHelper from "@/helpers/ActivityFunctions";
import StreamSurvey from "@/modules/together-helpers/activities/response/StreamSurvey";
export default {
  name: "DiaryResponse",
  components: {
    Response,
    StreamSurvey
  },

  props: {
    activityId: { type: Number,required: true }
  },

  mounted() {
    this.scrollToTheTargetResponse();
  },

  watch: {
    responseId: function (val) {
      this.scrollToTheTargetResponse();
    },
  },

  methods: {
    scrollToTheTargetResponse() {
      if (this.responseId) {
        let obj = this.$refs["response-" + this.responseId][0].$el;
        let left = obj.getBoundingClientRect().left;
        let top = obj.getBoundingClientRect().top;
        window.scroll({
          top: top,
          left: left,
          behavior: "smooth",
        });
      }
    },
    deleteResponse($event) {
      this.$store
        .dispatch(DELETE_DIARY_RESPONSES, $event)
        .then(() => {
          this.$toasted.global.success_toast({
            message: this.$t(
              "RESPONSE_FORMS.DELETE_SUCCESS." + this.activityTypeId
            )
          });
          this.$parent.$parent.pageLoader.componentsCompleted = 0;
          this.$parent.$parent.fetchActivityInfo();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues deleting activity response, please check back later or contact the helpdesk";
        });
    },
    deleteComment($event) {
      let payload = {
        activity_id: this.activityInfo.id, // required
        id: $event
      };
      this.$store
        .dispatch(DELETE_REPLY_ENGAGEMENTS, payload)
        .then(() => {
          this.$toasted.global.success_toast({
            message: this.$t(
              "RESPONSE_FORMS.DELETE_SUCCESS." + this.activityTypeId
            )
          });
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues deleting activity comment, please check back later or contact the helpdesk";
        });
    },
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "activityInfo",
      "diaryQuestionList",
      "activityDiaryRepsonsesList"
    ]),
    responseId: function() {
      return this.$route.params.responseId;
    },
    computedDiaryResponse() {
      this.activityDiaryRepsonsesList.forEach(element => {
        element.dateLabel = moment(element["created_at"]).calendar(null, {
          lastDay: "[Yesterday], Do MMMM YY",
          sameDay: "[Today], Do MMMM YY",
          lastWeek: "[Last Week], Do MMMM YY",
          sameElse: "Do MMMM YY"
        });
      });
      return this._.groupBy(this.activityDiaryRepsonsesList, "dateLabel");
    },
    activityTypeId: function() {
      return this.activityInfo.activity_type.id;
    }
  }
};
</script>
<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.when-closed,
.when-opened {
  float: right;
}
</style>
