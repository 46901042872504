<template>
  <div v-if="loadingComplete">
    <GeneralDisplay :activityInfo="activityInfo">
      <div slot="activity-panel">
        <div class="mt-10 d-flex flex-row-reverse">
          <button
            class="btn btn-primary"
            @click="toggleNewMessage()"
            v-show="!showNewMessageBox"
          >
            {{ $t("RESPONSE_FORMS.INPUTS.NEW_POST") }}
          </button>
        </div>

        <!--begin::Message input Box-->
        <InputGenericResponseBox
          mode="post"
          :prepResponse="prepopulatedResponse"
          :overrideAssetReservationData="assetReservationData"
          v-show="showNewMessageBox"
          @cancelAction="toggleNewMessage"
          @refreshList="fetchPictureBookInfo"
        ></InputGenericResponseBox>
        <!--end::Message input Box-->

      </div>
    </GeneralDisplay>
  
    <PictureBookResponse
      v-if="showResponse && activityInfo.activity_responses.length > 0"
      :responses="activityInfo.activity_responses"
      :posts="activityPictureBookRepsonsesList"
      :pageRefresher="pageRefresher"
      :showMorePageLogo="hasMorePage"
      @loadMoreRepliesList="fetchActivityResponses"
      :loadingMoreRepliesList="loadingMoreRepliesList"
    >
    </PictureBookResponse>
  </div>


  <!--begin::Loading Spinner-->
  <div class="row" v-else>
    <div class="col-xxl-12 min-vh-100">
      <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
        <div class="card-body my-4 pd-12">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner class="main-card-loader" label="Loading..."></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Loading Spinner-->
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { POPULATE_ACTIVITY_INFO } from "@/core/services/store/activity/activity.module";
import { POPULATE_ACTIVITY_PICTURE_BOOK_RESPONSES_LIST } from "@/core/services/store/activity/activity_picture_book_responses.module.js";
import GeneralDisplay from "@/view/components/activity/GeneralDisplay.vue";
import InputGenericResponseBox from "@/modules/together-helpers/components/generic-inputs/GenericResponseBox";
import PictureBookResponse from "@/view/components/activity/PictureBookResponse.vue";

export default {
  name: "PictureBookActivity",

  components: {
    GeneralDisplay,
    InputGenericResponseBox,
    PictureBookResponse
  },

  directives: {},

  data() {
    return {
      title: "",
      messageText: "",
      respondingTo: false,
      showNewMessageBox: false,
      page: 1,
      page_length: 15,
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 2
      },
      pageRefresher: {
        componentsCompleted: 0,
        componentsRequired: 0
      },
      error: {
        active: false,
        message: ""
      },
      replies: [],
      loadingMoreRepliesList: false,
      arDropzoneOptions: {
        supportedMimeTypes: {},
        supportedMimeSizes: {},
        supportedFileSize: 1600,
        supportedFileCount: 0,
        supportWebcam: 0,
        supportUploadButton: 1
      }
    };
  },

  mounted() {
    this.cleanup();
    this.fetchActivityInfo();
    this.fetchActivityResponses();
  },

  watch: {
    activityId: function() {
      this.pageLoader.componentsCompleted = 0;
      this.pageLoader.componentsRequired = 1;
      this.fetchActivityInfo();
    },
    hasMorePage: function() {
      return this.activityPictureBookRepsonsesListMeta.last_page > this.activityPictureBookRepsonsesListMeta.current_page;
    },
  },
  methods: {
    fetchPictureBookInfo: function() {
      this.pageRefresher.componentsRequired = 2;
      this.pageRefresher.componentsCompleted = 0;
      this.fetchActivityInfo();
      this.fetchActivityResponses();
    },
    fetchActivityInfo() {
      let payload = {
        projectId: this.projectInfo.id,
        activityId: this.activityId,
        params: {
          paginate: false,
          activityType: "with",
          activityStage: "with",
          activitySettings: "with",
          socialBias: "with",
          activityResponses: "with",
          stimulusAssets: "with",
          appendSignedUrls: "1"
        }
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_INFO, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
          this.pageRefresher.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity information, please check back later or contact the helpdesk";
        });
    },
    fetchActivityResponses: function() {
      this.loadingMoreRepliesList = true;
      let payload = {
        activityId: this.activityId,
        params: {
          replies: 'with',
          sentiments: 'with',
          created_at: "ASC",
          paginate: true,
          page: this.page,
          pageLength: this.page_length,
          activityResponse: 'with',
          activityAttachment: 'with'
        }
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_PICTURE_BOOK_RESPONSES_LIST, payload)
        .then(() => {
          if (this.activityPictureBookRepsonsesListMeta.last_page > this.activityPictureBookRepsonsesListMeta.current_page){
            this.page++;
          }
          this.pageLoader.componentsCompleted++;
          this.pageRefresher.componentsCompleted++;
          this.loadingMoreRepliesList = false;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity responses, please check back later or contact the helpdesk";
        })
    },
    toggleNewMessage() {
      this.showNewMessageBox = !this.showNewMessageBox;
    },
    setError(bool) {
      return (this.error = bool);
    },
    cleanup: function() {
      this.setActivityInfo([]);
      this.resetActivityPictureBookRepsonsesList([]);
      this.resetActivityPictureBookRepsonsesMeta([]);
      this.resetActivityReplyEngagementsList([]);
    },
    ...mapMutations({
      setActivityInfo: "setActivityInfo",
      resetActivityPictureBookRepsonsesList: "resetActivityPictureBookRepsonsesList",
      resetActivityPictureBookRepsonsesMeta: "resetActivityPictureBookRepsonsesMeta",
      resetActivityReplyEngagementsList: "resetActivityReplyEngagementsList"
    })
  },
  computed: {
    ...mapGetters([
      "projectInfo",
      "currentUser",
      "activityInfo",
      "activityPictureBookRepsonsesList",
      "activityPictureBookRepsonsesListMeta"
    ]),
    assetReservationData: function() {
      return {
        scope: "activity",
        data: {
          directory: "attachments_images",
          projectId: this.projectInfo.id,
          activityId: this.activityInfo.id
        }
      };
    },
    activityId: function() {
      return this.$route.params.id;
    },
    activityType: function() {
      return this._.toLower(this.activityInfo.activity_type.name);
    },
    activityTypeId: function() {
      return this.activityInfo.activity_type.id;
    },
    hasMorePage: function() {
      return this.activityPictureBookRepsonsesListMeta.last_page > this.activityPictureBookRepsonsesListMeta.current_page;
    },
    activityUnbiased: function() {
      return this.activityInfo.social_bias.id == 1 ? true : false;
    },
    showResponse: function() {
      return this.userPosted || !this.activityUnbiased || this.currentUser.relatation_to_project != "participant";
    },
    userPosted: function() {
      let index = this.activityInfo.activity_responses.findIndex(
        replies => replies.user.id === this.currentUser.id
      );
      return index == -1 ? false : true;
    },
    prepopulatedResponse: function() {
      let setting = this._.find(this.activityInfo.activity_settings, { id: 1 });
      return setting ? String(setting.pivot.value) : "";
    },
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
