<template>
  <div>
    <div class="row d-flex justify-content-between mx-0" style="min-height: 30px">
      <div class="row d-flex justify-content-start mx-0">

        <component
          v-for="(type) in supportedInteractions.filter(function(interaction) { return allowInteractions.includes(interaction.component) })"
          :key="type.id"
          :is="type.component"
          :engagements="interact.interactions.filter(function(interaction) { return interaction.system_interaction_type_id === type.id } )"
          identifier="value"
          :morphType="morph"
          :morphId="response.id"
          :baseResponse="baseResponse"
          @on-engagement="onClickInteract($event, type.id)"
          @toggle_replies="showReplies = !showReplies"
          @toggle_reply_input="showReplyForm = !showReplyForm"
          @toggle-notepad="$emit('toggle-notepad',$event)"
        />

        <div v-if="allowInteractions.includes('Reply')" class="pt-1">
          <a
            v-if="!showReplyForm && currentUser.relation_to_project != 'observer'"
            href="javascript:void(0)"
            class="text-primary ml-2 font-size-sm text-muted"
            @click="showReplyForm = true"
          >
            {{ $t('GENERAL.BUTTONS.ADD_COMMENT') }}
          </a>
        </div>
        
      </div>
      <div v-if="allowDestroy">
        <Destroy
          v-if="currentUser.relation_to_project != 'observer'"
          v-show="hovering"
          :type="type"
          :baseResponse="baseResponse"
          :response="response"
          @on-response-deletion="$emit('on-response-deletion')"
        >
        </Destroy>
      </div>

    </div>

    <ReplyInput
      v-show="showReplyForm"
      :domain="domain"
      @reply-input-closed="showReplyForm = 0"
      @reply-input-added="onClickInteract($event, 3)"
    />

    <ReplyContainer
      ref="reply-container"
      v-if="showReplies"
      :baseResponse="baseResponse"
      :morphType="morph"
      :morphId="response.id"
      :domain="domain"
      @toggle-notepad="$emit('toggle-notepad',$event)"
      @on-response-deletion="stripInteraction($event)"
    />
  </div>

</template>

<script>
import {
  mapState,
  mapGetters,
  mapActions
} from "vuex";

import Interact from "@/core/services/InteractService";

import Sentiment from "./interaction/Sentiment.vue";
import Reply from "./interaction/Reply.vue";
import Favourite from "./interaction/Favourite.vue";
import Note from "./interaction/Note.vue";
import Tag from "./interaction/Tag.vue";
import Destroy from "./interaction/Destroy.vue";

import ReplyInput from "./interaction/ReplyInput.vue"
import ReplyContainer from "./interaction/ReplyContainer.vue";

export default {
  name: "InlineEngagementNewSchema",

  props: {
    type:               { type: String, required: true },

    allowInteractions:  { type: Array, required: true },
    allowDestroy:       { type: Boolean, required: true },

    baseResponse:       { type: Object, required: true },
    response:           { type: Object, required: true },
    morph:              { type: String, required: true },

    hovering:           { type: Boolean, required: true },

    probing:            { type: Boolean, required: false, default: false },
    domain:             { type: String, required: true, default: "admin" }
  },

  data() {
    return {
      interact: new Interact(
        this.response.direct_interactions,
        this.createEngagementInteraction,
        this.deleteEngagementInteraction
      ),
      showReplies: false,
      showReplyForm: false
    }
  },

  components: {
    Sentiment,
    Reply,
    Favourite,
    Note,
    Tag,
    Destroy,
    ReplyInput,
    ReplyContainer
  },

  methods: {
    onClickInteract: function(event, systemInteractTypeId) {
      if (event.type != undefined && event.type === 'add') {
        let interactionPayload = {
          options: this.getInteractionOptions(event, systemInteractTypeId),
          value: this.getInteractionValue(event, systemInteractTypeId)
        }
        this.interact.interact(systemInteractTypeId, this.morph, this.response.id, interactionPayload, this.baseResponse)
          .then(() => {
            if (systemInteractTypeId === 3 && this.showReplies === false) {
              this.showReplies = true;
            } else if (systemInteractTypeId === 3) {
              this.$refs['reply-container'].fetchReplies();
            }
          })
      } else if (event.type != undefined && event.type === 'remove') {
        this.interact.remove(event.engagementId, this.baseResponse);
      }
    },
    getInteractionOptions: function(event, system_interaction_type_id) {
      switch (system_interaction_type_id) {
        case 3:
          return {
            probe: event.probe
          }
        default:
          return null;
      }
    },
    getInteractionValue: function(event, systemInteractTypeId) {
      switch (systemInteractTypeId) {
        case 2:
          return event.engagement.note;
        case 3:
          return event.reply;
        case 5:
          return event.engagement.tag;
        default:
          return null;
      }
    },
    onDestroy: function() {
      window.alert("Destroy Response");
    },
    stripInteraction: function(removedInteractionId) {
      this.interact.interactions = this.interact.interactions.filter((interaction) => {
        return interaction.id != removedInteractionId;
      })
    },
    ...mapActions({
      createEngagementInteraction: "createEngagementInteraction",
      deleteEngagementInteraction: "deleteEngagementInteraction"
    })
  },

  computed: {
    ...mapState([
      'projectStream'
    ]),
    ...mapGetters([
      'currentUser'
    ]),
    supportedInteractions: function() {
      return [
        {id: 4, component: "Sentiment"},
        {id: 3, component: "Reply"},
        {id: 1, component: "Favourite"},
        {id: 2, component: "Note" },
        {id: 5, component: "Tag" }
      ]
    },
  }
};
</script>
