<template>
  <GeneralDisplay v-if="loadingComplete" :activityInfo="activityInfo">
    <div slot="activity-panel">
      <div class="mt-10 d-flex flex-row-reverse">
        <button
          class="btn btn-primary"
          @click="toggleNewMessage()"
          v-show="!showNewMessageBox"
        >
          {{ $t("RESPONSE_FORMS.INPUTS.NEW_POST") }}
        </button>
      </div>

      <!--begin::Diary Form Questions List Panel-->
      <PageOfQuestions
        :questionForm="prepareForm()"
        :questions="computedDiaryQuestions"
        :activityType="activityType"
        v-show="showNewMessageBox"
        @responses_created="responses_created()"
      ></PageOfQuestions>
      <!--end::Diary Form Questions List Panel-->

      <!--begin::Diary Response Lists-->
      <div class="mt-10">
        <div class="d-flex justify-content-center mb-3" v-if="responseLoading">
          <b-spinner class="main-card-loader" label="Loading..."></b-spinner>
        </div>

        <DiaryResponse
          :activityId="_.toNumber(activityId)"
          v-else
        ></DiaryResponse>
      </div>
      <!--end::Diary Response Lists-->
    </div>
  </GeneralDisplay>

  <!--begin::Loading Spinner-->
  <div class="row" v-else>
    <div class="col-xxl-12 min-vh-100">
      <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
        <div class="card-body my-4 pd-12">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner class="main-card-loader" label="Loading..."></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Loading Spinner-->
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { POPULATE_ACTIVITY_INFO } from "@/core/services/store/activity/activity.module";
import { POPULATE_ACTIVITY_DIARY_RESPONSES_LIST } from "@/core/services/store/activity/activity_diary_responses.module.js";
import DiaryResponse from "@/view/components/activity/DiaryResponse.vue";
import PageOfQuestions from "@/view/pages/activity/PageOfQuestions.vue";
import { POPULATE_DIARY_QUESTION_LIST } from "@/core/services/store/activity/diary_question.module";
import GeneralDisplay from "@/view/components/activity/GeneralDisplay.vue";
import ActivityFunctionsHelper from "@/helpers/ActivityFunctions";
export default {
  name: "DiaryActivity",
  components: {
    DiaryResponse,
    GeneralDisplay,
    PageOfQuestions
  },

  data() {
    return {
      showNewMessageBox: false,
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 2
      },
      error: {
        active: false,
        message: ""
      },
      responseLoading: true,
      replies: []
    };
  },

  mounted() {
    this.cleanup();
    this.fetchActivityInfo();
  },

  watch: {
    activityId: function() {
      this.pageLoader.componentsCompleted = 0;
      this.pageLoader.componentsRequired = 2;
      this.fetchActivityInfo();
    },
    responseId: function() {
      this.responseLoading = true;
      this.fetchDiaryResponse();
    }
  },
  methods: {
    fetchActivityInfo() {
      let payload = {
        projectId: this.projectInfo.id,
        activityId: this.activityId,
        params: {
          paginate: false,
          activityType: "with",
          activityStage: "with",
          activitySettings: "with",
          socialBias: "with",
          stimulusAssets: "with",
          appendSignedUrls: "1"
        }
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_INFO, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
          this.fetchDiaryQuestions();
          this.fetchDiaryResponse();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity information, please check back later or contact the helpdesk";
        });
    },
    fetchDiaryQuestions() {
      let payload = {
        activityId: this.activityId,
        params: {
          answerType: "with",
          diaryQuestionChoices: "with",
          appendSignedUrls: 1,
          stimulusAssets: "with",
          paginate: false
        }
      };
      this.$store
        .dispatch(POPULATE_DIARY_QUESTION_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the diary question list, please check back later or contact the helpdesk";
        });
    },
    fetchDiaryResponse() {
      let payload = {
        activityId: this.activityId,
        params: {
          diaryQuestionResponses: "with",
          sentiments: "with",
          responseId: this.responseId,
          scope: "input",
          paginate: false,
          created_at: "DESC"
        }
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_DIARY_RESPONSES_LIST, payload)
        .then(() => {
          this.responseLoading = false;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the diary response list, please check back later or contact the helpdesk";
        });
    },
    toggleNewMessage() {
      this.showNewMessageBox = !this.showNewMessageBox;
    },
    prepareForm() {
      let form = [];
      this.diaryQuestionList.forEach(q => {
        if (q.system_answer_type_id == "2") {
          form.push({ answer: [] });
        } else if (q.system_answer_type_id == "8") {
          let slots = {};
          let slotNumber = 1;
          q.arrayQuestion.forEach(slot => {
            if (slot.type == "OpenEnded" || slot.type == "SingleChoice") {
              slots[slotNumber] = null;
              slotNumber++;
            }
          })
          form.push({ answer: slots});
        } else {
          form.push({ answer: "" });
        }
      });
      let arr = this.groupByPage(form);
      return arr;
    },
    groupByPage(list) {
      let p = [{ page: list }];
      return p;
    },
    prepareDiaryQuestionsChoices(list) {
      return ActivityFunctionsHelper.prepareQuestionsChoices(
        list,
        "diary_question_choices"
      );
    },
    responses_created() {
      this.$router.go();
    },
    cleanup: function() {
      this.setActivityInfo([]);
      this.resetActivityDiaryRepsonsesList([]);
      this.resetActivityReplyEngagementsList([]);
    },
    ...mapMutations({
      setActivityInfo: "setActivityInfo",
      resetActivityDiaryRepsonsesList: "resetActivityDiaryRepsonsesList",
      resetActivityReplyEngagementsList: "resetActivityReplyEngagementsList"
    })
  },
  computed: {
    ...mapGetters([
      "projectInfo",
      "currentUser",
      "activityInfo",
      "diaryQuestionList",
      "activityDiaryRepsonsesList"
    ]),
    activityId: function() {
      return this.$route.params.id;
    },    
    responseId: function() {
      return this.$route.params.responseId;
    },
    activityType: function() {
      return this._.toLower(this.activityInfo.activity_type.name);
    },
    activityTypeId: function() {
      return this.activityInfo.activity_type.id;
    },
    activityUnbiased: function() {
      return this.activityInfo.social_bias.id == 1 ? true : false;
    },
    showResponse: function() {
      return this.userPosted || !this.activityUnbiased;
    },
    userPosted: function() {
      let index = this.activityDiaryRepsonsesList.findIndex(
        replies => replies.user.id === this.currentUser.id
      );
      return index == -1 ? false : true;
    },
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    },
    computedDiaryQuestions: function() {
      let list = this.diaryQuestionList;
      list = this.prepareDiaryQuestionsChoices(list);
      return this.groupByPage(list);
    }
  }
};
</script>
