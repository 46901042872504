<template>
  <GeneralDisplay
    v-if="loadingComplete"
    :activityInfo="activityInfo"
    :showIntroduction="false"
    :showSummary="false"
  >
    <template slot="activity-text">
      <span v-html="activityInfo.engagement_questions[0].question" />
    </template>

    <template slot="activity-panel">

      <div ref="error-box">
        <ErrorBox
          v-if="activityResponseFormErrors != null"
          :errors="activityResponseFormErrors"
          @clear_errors="setActivityResponseFormError()"
        />
      </div>


      <PinnedImage
        v-if="responses.length === 0"
        id="pins"
        :imageUrl="activityInfo.engagement_stimuli_assets[0].signedUrl"
        type="input"
        :engage="engage"
        :activity="activityInfo"
      />

      <div
        v-if="responses.length === 0"
        class="d-flex justify-content-end"
      >
        <a
          href="javascript:void(0)"
          class="btn btn-sm btn-primary"
          :class="{disabled: submitting}"
          @click="onSubmit"
        >
          {{ $t('GENERAL.BUTTONS.SUBMIT') }}
        </a>
      </div>

      <div v-if="responses.length > 0">
        <template v-for="(response) in responses">
          <NewMarkupDisplay
            :key="response.id"
            :response="response"
          />
        </template>
      </div>
    </template>
  </GeneralDisplay>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapActions
} from "vuex";

import Swal from "sweetalert2";
import Engage from "@/core/services/EngageService";

import GeneralDisplay from "@/view/components/activity/GeneralDisplay.vue";
import PinnedImage from "@/modules/together-helpers/activities/response/answer-types/PinnedImage.vue";
import ErrorBox from "@/view/components/ErrorBox";
import NewMarkupDisplay from "./NewMarkupDisplay.vue";

import { POPULATE_ACTIVITY_INFO } from "@/core/services/store/activity/activity.module";
import { POPULATE_MARKUP_PIN_TYPES } from "@/core/services/store/system/system.module";

export default {
  name: "NewMarkupActivityInput",

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 3
      },
      error: {
        active: false,
        message: ""
      },
      responses: null,
      showResponse: true,
      submitting: false,
      engage: new Engage(
        this,
        this.setActivityResponseDisplayError,
        this.setActivityResponseFormError,
        this.createActivityResponse
      )
    }
  },

  components: {
    GeneralDisplay,
    PinnedImage,
    ErrorBox,
    NewMarkupDisplay
  },

  mounted() {
    this.cleanup();
    this.fetchMarkupPinTypes();
    this.fetchActivityInfo();

    this.engage.setResponseValue("pins", []);
  },

  methods: {
    onSubmit: function() {
      if (this.submitting == false) {
        this.submitting = true;
        this.engage.submit().then((res) => {
          let sweetAlertMsg = (res === null) ? this.$t("RESPONSE_FORMS.ADD_SUCCESS.TEST_ACTIVITY") : this.$t("RESPONSE_FORMS.ADD_SUCCESS.8");
          Swal.fire({
            title: "",
            text: sweetAlertMsg,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            allowOutsideClick: false
          }).then(() => {
            this.fetchResponse();
            this.submitting = false;
          });
        }).catch((error) => {
          this.setActivityResponseFormError(error.response.data);
          this.submitting = false;
          return this.$refs['error-box'].scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        })
      }
    },
    fetchMarkupPinTypes() {
      this.$store.dispatch(POPULATE_MARKUP_PIN_TYPES).then(() => { this.pageLoader.componentsCompleted++; } );
    },
    fetchResponse: function() {
      let payload = {
        route: `api/v1/activities/${this.activityInfo.id}/engagement_responses`,
        params: {
          paginate: false,
        },
        relations: {
          activity: 'with',
          projectAssets: 'with',
          activityType: 'with',
          user: 'with',
          engagementQuestionResponses: 'with',
          engagementQuestionPinResponses: 'with',
          directInteractions: 'with'
        }
      };

      this.genericResource(payload).then((res) => {
        this.responses = res._embedded.engagement_responses;
        this.pageLoader.componentsCompleted++;
      })
    },
    fetchActivityInfo() {
      let payload = {
        projectId: this.projectInfo.id,
        activityId: this.activityId,
        params: {
          paginate: false,
          activityType: "with",
          activityStage: "with",
          activitySettings: "with",
          socialBias: "with",
          activityResponses: "with",
          engagementStimuliAssets: 'with',
          engagementQuestions: 'with',
          engagementQuestionPins: 'with',
          appendSignedUrls: "1"
        }
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_INFO, payload)
        .then((res) => {
          this.pageLoader.componentsCompleted++;
          this.engage.setActivity(res);
          this.fetchResponse();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity information, please check back later or contact the helpdesk";
        });
    },
    cleanup: function() {
      this.setActivityInfo(null);
      this.resetActivityBlogRepsonsesList([]);
      this.resetActivityDiscussionRepsonsesList([]);
      this.resetActivityReplyEngagementsList([]);
    },
    ...mapActions({
      createActivityResponse: "createActivityResponse",
      genericResource: "genericResource"
    }),
    ...mapMutations({
      setActivityInfo: "setActivityInfo",
      resetActivityBlogRepsonsesList: "resetActivityBlogRepsonsesList",
      resetActivityDiscussionRepsonsesList: "resetActivityDiscussionRepsonsesList",
      resetActivityReplyEngagementsList: "resetActivityReplyEngagementsList",
      setActivityResponseDisplayError: "setActivityResponseDisplayError",
      setActivityResponseFormError: "setActivityResponseFormError"
    })
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      'activityInfo',
      'activityResponseFormErrors'
    ]),

    activityId: function() {
      return this.$route.params.id;
    },

    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>