<template>
  <b-overlay :show="!loadingComplete" rounded="sm">
    <a
      @click="toggleLike()"
      href="javascript:void(0)"
      class="btn btn-icon-danger btn-text-dark-50 bg-hover-light-danger btn-hover-text-danger rounded font-weight-bolder font-size-xs p-2"
      :class="calculatedBtnSize"
    >
      <span class="svg-icon svg-icon-md svg-icon-danger pr-1">
        <i
          class="text-danger"
          :class="myLoveId != null ? 'fa fa-heart' : 'far fa-heart'"
        ></i> </span
      >{{ localSentimentArray.length }}
    </a>
  </b-overlay>
</template>

<script>
import { mapGetters } from "vuex";
import {
  CREATE_SENTIMENT_ENGAGEMENTS,
  DELETE_SENTIMENT_ENGAGEMENTS
} from "../../vuex/activity/engagement/activity_sentiment_engagements.module";

export default {
  name: "Sentiment",

  props: {
    activityId: { type: Number, required: true },
    post: { type: Object, required: true },
    size: { type: String, required: false }
  },

  data() {
    return {
      localSentimentArray: [],
      loadingComplete: true
    };
  },

  mounted() {
    this.localSentimentArray = this.post.sentiments;
  },

  methods: {
    toggleLike: function() {
      this.loadingComplete = false;
      if (this.myLoveId != null) {
        this.onUnlovePost();
      } else {
        this.onLovePost();
      }
    },
    onLovePost: function() {
      let payload = {
        activityId: (this.activityId != undefined) ? this.activityId : this.activityInfo.id,
        data: {
          sentiment: 1,
          sentimentable_type: "ActivityPictureBookResponse",
          sentimentable_id: this.post.id
        }
      };
      this.$store.dispatch(CREATE_SENTIMENT_ENGAGEMENTS, payload).then(res => {
        this.loadingComplete = true;
        this.localSentimentArray.push(res);
      });
    },
    onUnlovePost: function() {
      let payload = {
        activityId: (this.activityId != undefined) ? this.activityId : this.activityInfo.id,
        engagementId: this.myLoveId
      };
      this.$store.dispatch(DELETE_SENTIMENT_ENGAGEMENTS, payload).then(() => {
        this.loadingComplete = true;
        const self = this;
        this.localSentimentArray = this.localSentimentArray.filter(function(
          localSentiment
        ) {
          return localSentiment.id != self.myLoveId;
        });
      });
    }
  },

  computed: {
    ...mapGetters(["activityInfo", "currentUser"]),

    myLoveId: function() {
      const self = this;
      let myLove = this.localSentimentArray.filter(function(sentiment) {
        return sentiment.user_id == self.currentUser.id;
      });

      if (myLove.length == 1) {
        return myLove[0].id;
      } else {
        return null;
      }
    },

    calculatedBtnSize: function() {
      if (this.size == undefined) {
        return "btn-sm font-size-sm";
      } else {
        return "btn-" + this.size + " font-size-" + this.size;
      }
    }
  }
};
</script>
