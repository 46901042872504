<template>
  <GeneralDisplay v-if="loadingComplete" :activityInfo="activityInfo">
    <div slot="activity-panel">
      <div class="mt-10 d-flex justify-content-center">
        <b-button
          variant="primary"
          @click="toggleModal()"
          v-if="!completedMarkUp"
        >
          {{ $t("ACTIVITY.MARKUP.START") }}
        </b-button>

        <b-button disabled size="lg" variant="primary" v-else>
          {{ $t("ACTIVITY.SURVEY.COMPLETED_TITLE") }}</b-button
        >

        <!--begin:: Mark Up Modal Boz -->
        <b-modal
          no-close-on-backdrop
          header-bg-variant="primary"
          header-text-variant="light"
          footer-bg-variant="light"
          footer-text-variant="dark"
          centered
          size="xl"
          v-model="modalShow"
        >
          <template #modal-header>
            <h5 class="mb-0" v-html="activityHeader(activityInfo)"></h5>

            <b-button size="sm" variant="outline-danger" @click="closeModal()">
              {{ $t("ACTIVITY.MARKUP.CLOSE") }}
            </b-button>
          </template>

          <template #modal-footer>
            <div class="w-100 d-flex justify-content-center">
              <div v-if="page === totalQuestion + 1">
                <b-overlay :show="submitting" rounded="sm">
                  <b-button
                    size="md"
                    variant="primary"
                    @click.prevent.self="submit(page)"
                  >
                    {{ $t("ACTIVITY.MARKUP.FINISH") }}
                  </b-button>
                </b-overlay>
              </div>

              <b-button
                size="md"
                variant="primary"
                v-else
                @click.prevent="next(page)"
              >
                {{
                  page == 1
                    ? $t("ACTIVITY.MARKUP.READY")
                    : page !== 1
                    ? $t("ACTIVITY.MARKUP.PROCEED")
                    : false
                }}
              </b-button>
            </div>
          </template>

          <MarkUpModal
            :page="page"
            ref="modal"
            class="mark-up-modal"
            :questions="questionsList"
            @loadedImageXY="loadedImageXY($event)"
          ></MarkUpModal>
        </b-modal>
        <!--end:: Mark Up Modal Boz -->
      </div>
    </div>
  </GeneralDisplay>

  <!--begin::Loading Spinner-->
  <div class="row" v-else>
    <div class="col-xxl-12 min-vh-100">
      <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
        <div class="card-body my-4 pd-12">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner class="main-card-loader" label="Loading..."></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--end::Loading Spinner-->
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import Swal from "sweetalert2";
import { POPULATE_ACTIVITY_INFO } from "@/core/services/store/activity/activity.module";
import { CREATE_MARKUP_RESPONSES, POPULATE_MARKUP_QUESTION_LIST_LEGACY } from "@/core/services/store/activity/activity_mark_up.module";

import SystemIconHelper from "@/modules/together-helpers/helpers/SystemIconHelper";
import SystemLabelHelper from "@/modules/together-helpers/helpers/SystemLabelHelper";

import GeneralDisplay from "@/view/components/activity/GeneralDisplay.vue";
import MarkUpModal from "@/view/components/activity/mark_up/MarkUpModal.vue";
import GeneralFunctionsHelper from "@/helpers/GeneralFunctions";
export default {
  name: "MarkUpActivity",

  components: {
    GeneralDisplay,
    MarkUpModal,
  },

  data() {
    return {
      modalShow: false,
      completedMarkUp: false,
      page: 1,
      maxX: 0,
      maxY: 0,
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 1,
      },
      no_response_role: false,
      submitting: false,
      error: {
        active: false,
        message: "",
      },
    };
  },

  mounted() {
    this.cleanup();
    this.fetchActivityInfo();
  },

  watch: {
    activityId: function () {
      this.pageLoader.componentsCompleted = 0;
      this.pageLoader.componentsRequired = 1;
      this.fetchActivityInfo();
    },
    no_response_role: function (val) {
      if (val == true) {
        this.$root.$bvToast.toast(
          `${this.$t("TOASTS.ACTIVITY.ROLE_NOT_ELIGIBLE_TO_RESPONSE_MSG")}`,
          {
            title: `${this.$t(
              "TOASTS.ACTIVITY.ROLE_NOT_ELIGIBLE_TO_RESPONSE_TITLE"
            )}`,
            variant: "warning",
            noAutoHide: true,
          }
        );
      }
    },
  },
  methods: {
    loadedImageXY($event) {
      this.maxX = $event.X;
      this.maxY = $event.Y;
      this.$log.info($event);
    },
    preparePayload() {
      let form = [];
      let answer = this.$refs.modal.questionForm;
      answer.forEach((Question, QusestionIndex) => {
        Question[0].pins.forEach((pin) => {
          pin.pin_x = Math.abs(pin.pin_x / this.maxX);
          pin.pin_y = Math.abs(pin.pin_y / this.maxY);
        });
        form[QusestionIndex] = Question[0].pins;
      });
      let data = {
        activity_id: this.activityInfo.id,
        project_profile_id: this.currentUser.project_profiles.id,
        user_id: this.currentUser.id,
        system_activity_type_id: this.activityInfo.system_activity_type_id,
        response: form,
      };
      return {
        activityId: this.activityInfo.id, // required
        data: data,
      };
    },
    submit(pageNumber) {
      this.submitting = true;
      if (this.validatePage(pageNumber)) {
        let answers = this.preparePayload();
        this.$store
          .dispatch(CREATE_MARKUP_RESPONSES, answers)
          .then(() => {
            let sweetAlertMsg = "";
            if (this.isParticipant) {
              sweetAlertMsg = this.$t("ACTIVITY.MARKUP.SUBMITTED_TEXT");
            } else {
              sweetAlertMsg = this.$t(
                "RESPONSE_FORMS.ADD_SUCCESS.TEST_ACTIVITY"
              );
            }
            Swal.fire({
              title: "",
              text: sweetAlertMsg,
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              allowOutsideClick: false,
            }).then(() => {
              this.closeModal();
              this.fetchActivityInfo();
            });
          })
          .catch(() => {
            this.error.active = true;
            this.$toasted.global.error_toast({
              message: `We're having some issues posting a ${this.activityType} response, please check back later or contact the helpdesk`,
            });
          })
          .finally(() => {
            this.submitting = false;
          });
      } else {
        this.submitting = false;
      }
    },
    validatePage(pageNumber) {
      let page = `page-${pageNumber}-questions`;
      let question = this.$refs.modal.$refs[page];

      if (question.pinsPut < 1) {
        this.setError(this.$t("ACTIVITY.MARKUP.PIN_ERRORS"));
        return false;
      } else if (
        question.pinCountType !== 0 &&
        question.pinsPut < question.pinCount
      ) {
        this.setError(this.$t("ACTIVITY.MARKUP.PIN_ERRORS"));
        return false;
      }

      question.$v.form.$touch();

      if (question.$v.form.$anyError) {
        this.setError(this.$t("ACTIVITY.MARKUP.FORM_ERRORS"));
        return false;
      }
      return true;
    },
    next(pageNumber) {
      // validate
      if (pageNumber !== 1) {
        if (this.validatePage(pageNumber)) {
          this.page++;
        }
        return;
      }
      this.page++;
    },
    closeModal() {
      // validate
      this.toggleModal();
      this.page = 1;
    },
    fetchActivityInfo() {
      let payload = {
        projectId: this.projectInfo.id,
        activityId: this.activityId,
        params: {
          paginate: false,
          activityType: "with",
          engagementQuestions: "with",
          activityStage: "with",
          activitySettings: "with",
          socialBias: "with",
          stimulusAssets: "with",
          appendSignedUrls: "1",
        },
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_INFO, payload)
        .then((res) => {
          this.pageLoader.componentsCompleted++;
          if (res.responseStatus == "completed") {
            this.completedMarkUp = true;
          } else {
            if (this.isMobile && !this.isMarkUpImage) {
              Swal.fire({
                title: this.$t("ACTIVITY.MARKUP.MOBILE_NOT_ALLOWED_TITLE"),
                text: this.$t("ACTIVITY.MARKUP.MOBILE_NOT_ALLOWED_TEXT"),
                icon: "danger",
                confirmButtonClass: "btn btn-danger",
                allowOutsideClick: false,
              }).then(() => {
                this.$router.push({ path: "/" });
              });
            }
            if (res.responseStatus !== "eligible") {
              this.no_response_role = true;
            }
            this.pageLoader.componentsRequired++;
            this.fetchMarkUpQuestions();
          }
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity information, please check back later or contact the helpdesk";
        });
    },
    fetchMarkUpQuestions() {
      let payload = {
        activityId: this.activityInfo.id
      };

      this.$store
        .dispatch(POPULATE_MARKUP_QUESTION_LIST_LEGACY, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the mark up question list, please check back later or contact the helpdesk";
        });
    },
    toggleModal() {
      this.modalShow = !this.modalShow;
    },
    activityIcon(activity) {
      return SystemIconHelper.activityType(
        activity.activity_type,
        activity.recording_override
      );
    },
    iconLabel(activity) {
      return SystemLabelHelper.activityType(
        activity.activity_type,
        activity.recording_override
      );
    },
    activityHeader(activityInfo) {
      return GeneralFunctionsHelper.activityHeader(
        this.activityIcon(activityInfo),
        activityInfo,
        this.$t(this.iconLabel(activityInfo))
      );
    },
    setError(msg) {
      this.$toasted.global.error_toast({
        message: msg,
      });
      return;
    },
    cleanup: function () {
      this.setActivityInfo([]);
      this.resetActivityReplyEngagementsList([]);
    },
    ...mapMutations({
      setActivityInfo: "setActivityInfo",
      resetActivityReplyEngagementsList: "resetActivityReplyEngagementsList",
    }),
  },
  computed: {
    ...mapGetters([
      "projectInfo",
      "currentUser",
      "activityInfo",
      "markupQuestionList2",
    ]),

    isParticipant: function () {
      if (this.currentUser.relation_to_project == "participant") {
        return true;
      } else {
        return false;
      }
    },
    activityId: function () {
      return this.$route.params.id;
    },
    activityType: function () {
      return this._.toLower(this.activityInfo.activity_type.name);
    },
    activityTypeId: function () {
      return this.activityInfo.activity_type.id;
    },
    activityUnbiased: function () {
      return this.activityInfo.social_bias.id == 1 ? true : false;
    },
    totalQuestion: function () {
      return this.markupQuestionList2.length;
    },
    randomiseQuestions: function () {
      let setting = this._.find(this.activityInfo.activity_settings, { id: 3 });
      return setting ? Boolean(parseInt(setting.pivot.value)) : false;
    },
    questionsList: function () {
      let list = this.markupQuestionList2;
      if (this.randomiseQuestions) {
        list = this._.shuffle(list);
      }
      // prepare pin count for each questions
      list.forEach((q) => {
        let flag = parseInt(q.pin_answer_type);
        q.type_label =
          flag === 0
            ? this.$t("ACTIVITY.MARKUP.PIN_ANSWER_TYPE_UP_TO")
            : flag === 1
            ? this.$t("ACTIVITY.MARKUP.PIN_ANSWER_TYPE_TOTAL_OF")
            : false;
      });
      return {
        totalQuestion: this.totalQuestion,
        questionsList: list,
      };
    },
    loadingComplete: function () {
      if (
        this.pageLoader.componentsCompleted >=
        this.pageLoader.componentsRequired
      ) {
        return true;
      } else {
        return false;
      }
    },
    isMobile: function () {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isMarkUpImage: function () {
      // let asset = this.activityInfo.stimulus_assets[0];
      // if (asset.type == 1) {
      //   return false;
      // } else if (asset.type == 2) {
      //   return true;
      // }
      // return false;
      return true;
    },
  },
};
</script>
