<template>
  <div v-if="page == 1">
    <!--begin::Mark Up Questions Introduction -->
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">
            {{ $t("ACTIVITY.MARKUP.Questions") }}
          </th>
          <th scope="col">
            {{ $t("ACTIVITY.MARKUP.ACTIVITY_MARKUP_PIN_NUMBER") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(q, index) in questions.questionsList" v-bind:key="index">
          <th scope="row">{{ index + 1 }}</th>
          <td v-html="q.question"></td>
          <td>{{ q.type_label }} {{ q.pin_count }}</td>
        </tr>
        <tr></tr>
      </tbody>
    </table>
    <!--end::Mark Up Questions Introduction -->
  </div>

  <!--begin::Mark Up Questions Pins Panel -->
  <div v-else>
    <MarkUpImageQuestions
      v-if="isMarkUpImage"
      :ref="`page-${page}-questions`"
      :question="question"
      :pinCountType="parseInt(question.pin_answer_type)"
      :pinCount="parseInt(question.pin_count)"
      :form="questionForm[question.id]"
      @addAnotherPins="addAnotherPins($event)"
      @loadedImageXY="loadedImageXY($event)"
    ></MarkUpImageQuestions>
    <MarkUpVideoQuestions
      v-else
      :ref="`page-${page}-questions`"
      :question="question"
      :pinCountType="parseInt(question.pin_answer_type)"
      :pinCount="parseInt(question.pin_count)"
      :form="questionForm[question.id]"
      @addAnotherPins="addAnotherPins($event)"
      @loadedImageXY="loadedImageXY($event)"
    ></MarkUpVideoQuestions>
  </div>
  <!--end::Mark Up Questions Pins Panel -->
</template>

<script>
import { mapGetters } from "vuex";
import MarkUpImageQuestions from "@/view/components/activity/mark_up/MarkUpImageQuestions.vue";
import MarkUpVideoQuestions from "@/view/components/activity/mark_up/MarkUpVideoQuestions.vue";

export default {
  name: "MarkUpModal",

  props: {
    page: { type: Number, required: true },
    questions: { type: Object, required: true }
  },

  components: {
    MarkUpImageQuestions,
    MarkUpVideoQuestions
  },
  methods: {
    requiredTextResponse(question) {
      let prompt = this._.isString(question.text_response_prompt);
      let required = question.text_response_required;
      return prompt && required;
    },
    requiredSentiment(question) {
      let prompt = this._.isString(question.sentiment_response_prompt);
      let required = question.sentiment_response_required;
      return prompt && required;
    },
    requiredSentimentTextResponse(question) {
      let prompt = this._.isString(question.sentiment_text_response_prompt);
      let required = question.sentiment_text_response_required;
      return prompt && required;
    },
    preparePinAnswer(q) {
      let answer = {};
      if (this.isMarkUpImage) {
        answer = { pin_x: null, pin_y: null };
      } else {
        answer = { timestamp: null };
      }
      if (this.requiredTextResponse(q)) {
        answer.text_response = null;
      }
      if (this.requiredSentiment(q)) {
        answer.sentiment = null;
      }
      if (this.requiredSentimentTextResponse(q)) {
        answer.sentiment_text_response = null;
      }
      return answer;
    },
    addAnotherPins(index) {
      let q = this.questions.questionsList.find(q => q.id === index);
      let answer = this.preparePinAnswer(q);
      this.questionForm[index][0].pins.push(answer);
    },
    loadedImageXY(param) {
      this.$emit("loadedImageXY", param);
    }
  },

  computed: {
    ...mapGetters(["activityInfo"]),
    isMarkUpImage: function() {
      return true;
      // let asset = this.activityInfo.stimulus_assets[0];
      // if (asset.type == 1) {
      //   return false;
      // } else if (asset.type == 2) {
      //   return true;
      // }
      // return false;
    },
    questionIndex: function() {
      return this.page - 2;
    },
    question: function() {
      return this.questions.questionsList[this.questionIndex];
    },
    questionForm: function() {
      let questionForm = [];
      this.questions.questionsList.forEach(q => {
        let question = [];
        let answer = this.preparePinAnswer(q);
        question.push({ pins: [answer] });
        questionForm[q.id] = question;
      });
      return questionForm;
    }
  }
};
</script>
