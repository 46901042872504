<template>
  <div>
    <b-form-group
      :id="id + '-group'"
      :label="computedLabel"
      :label-sr-only="!displayLabel"
      :label-for="id"
      class="w-100 pt-5"
      :class="{ 'mb-0 ': model.$error }"
    >
      <template v-for="(icon, index) in sentimentsIcon">
        <button
          type="button"
          v-bind:key="index"
          :class="
            `btn btn-icon btn-outline-${icon.labelColor} btn-circle btn-lg mr-2` +
              (icon.active ? ' active' : '')
          "
          @click="changeSentiment(icon)"
        >
          <i :class="`fa ${icon.icon}`"></i>
        </button>
      </template>
    </b-form-group>

    <div class="custom-validation-failure mb-7" v-if="model.$error">
      {{ error }}
    </div>
  </div>
</template>
<script>
export default {
  name: "MarkUpSentimentCheckBox",

  props: ["model", "submissionStates", "id", "label", "error"],

  data() {
    return {
      sentimentsIcon: [
        {
          value: "1",
          active: false,
          icon: "fa fa-smile",
          labelColor: "success"
        },
        {
          value: "2",
          active: false,
          icon: "fa fa-meh",
          labelColor: "warning"
        },
        {
          value: "3",
          active: false,
          icon: "fa fa-frown",
          labelColor: "danger"
        }
      ]
    };
  },
  mounted() {
    if (this.model.$model) {
      let value = this.model.$model;
      this.sentimentsIcon.forEach(icon => {
        if (icon.value == value) {
          icon.active = true;
        }
      });
    }
  },
  methods: {
    validateState() {
      const { $dirty, $error } = this.model;
      return $dirty ? !$error : null;
    },
    changeSentiment(icon) {
      this.sentimentsIcon.forEach(otherIcons => {
        if (otherIcons.value !== icon.active) {
          otherIcons.active = false;
        }
      });
      icon.active = true;
      this.model.$model = icon.value;
    }
  },
  computed: {
    computedLabel: function() {
      if (this.label !== undefined) {
        if (this.model.$params.hasOwnProperty("required")) {
          return this.label + "*";
        } else {
          return this.label;
        }
      } else {
        return "";
      }
    },
    displayLabel: function() {
      if (this.label != undefined) {
        if (this.label.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }
};
</script>
