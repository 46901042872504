<template>
  <div>
    <b-form-group
      :id="id+'-group'"
      class="w-100 px-0"
      :class="{ 'mb-0 ': model.$anyError }"
    >
      <template v-for="(option, i) in options">
        <div
          class="row d-flex justify-content-between w-100"
          v-bind:key="option.id"
        >
          <div class="choice-display justify-content-start">
            <b-form-radio
              :key="i"
              v-model="model.$model"
              :name="id + '-option-' + i"
              :value="option.value"
              :disabled="disabled || submissionStates.submitting === true"
              size="lg"
            >
              <p class="pt-1">
                {{ option.text }}
              </p>
            </b-form-radio>
          </div>
          <div class="justify-content-end">
            <b-button
              v-if="option.choiceStimuli"
              :id="`image-${option.value}`"
              class="no-decoration"
            >
              <InputGenericThumbGallery
                :projectAssets="option.choiceStimuli"
                classStyle="sm"
                class="mb-0"
              >
              </InputGenericThumbGallery>
            </b-button>
          </div>
        </div>
        <div v-if="anyChoiceStimuli && i != (options.length - 1)" v-bind:key="option.value" class="separator separator-dashed my-6"></div>
      </template>
    </b-form-group>
    <div class="custom-validation-failure mb-7" v-if="model.$anyError">
      {{ error }}
    </div>
  </div>
</template>

<script>
import InputGenericThumbGallery from "@/view/components/form-inputs/GenericThumbGallery.vue";

export default {
  name: "SingleChoiceQuestion",

  props: {
    model: { type: Object, required: true },
    disabled: { type: Boolean, required: false },
    submissionStates: { type: Object, required: true },
    id: { type: String, required: true },
    error: { type: String, required: true },
    options: { type: Array, required: true }
  },
  
  methods: {
    validateState() {
      const { $dirty, $error } = this.model;
      return $dirty ? !$error : null;
    }
  },

  components: {
    InputGenericThumbGallery
  },

  computed: {
    anyChoiceStimuli: function() {
      let count = 0;

      this.options.forEach(choice => {
        if (choice.choiceStimuli != undefined) {
          count = count + choice.choiceStimuli.length;
        }
      });

      if (count == 0) {
        return false;
      } else {
        return true;
      };
    }
  }
}
</script>

<style>
@media screen and (min-width: 768px) {
  .choice-display {
    width: 60% !important
  }
}
</style>